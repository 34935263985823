import React, { useState, useEffect, memo } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import toast from 'react-hot-toast';

import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import FilterProjectComponent from 'src/components/FilterProjectComponent';
import {
  getProjectsClassificationList,
  projectReducer,
} from 'src/redux/projects';

import defaultProjectImage from '../../assests/Projects Icon.png';
import GHBuilderImage from '../../assests/G.H Builders IMG.png';
import FilterByLabourContractorComponent from 'src/components/FilterByLaourContractorComponent';
import { getContractors, usersReducer } from 'src/redux/users';
import {
  getListOfBOQ,
  productivityReducer,
  selectProductivityParentProject,
  getBoqListV1,
  shortCloseBoq,
  rejectChangeRequest,
  approveChangeRequest,
} from 'src/redux/productivity';

import SearchInput from 'src/components/SearchInput';
import Page from 'src/components/Page';
import { getNewProductivity } from '.';
import SOWAccordian from './SOWAccordian';
import useAccessPerView from 'src/common/Access';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
  },
  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    textTransform: 'capitalize',
    marginBottom: '5px',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonGreenOpacity: {
    color: '#81B734',
    backgroundColor: 'rgba(129, 183, 52, 0.1)',
    textTransform: 'capitalize',
    marginBottom: '5px',
    '&:hover': {
      backgroundColor: 'rgba(129, 183, 52, 0.1)',
    },
  },
  buttonRedOpacity: {
    margin: '5px 0px',
    textAlign: 'center',
    backgroundColor: 'rgba(247, 186, 175, 0.8)',
    color: 'rgba(196, 30, 0, 0.8)',
    textTransform: 'capitalize',
    borderRadius: 5,
    '&:hover': {
      backgroundColor: 'rgba(255, 99, 71, 0.8)',
    },
    justifyContent: 'center',
  },
  clippingLabel: {
    width: '100px',
    height: '30px',
    padding: '2px 20px 2px 10px',
    backgroundColor: 'blue',
    borderTopLeftRadius: '7px',
    borderBottomLeftRadius: '9px',
    color: 'white',
    fontFamily: 'calibri',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    clipPath:
      'polygon(0% 0%, calc(100% - 1rem) 0, 100% 50%, calc(100% - 1rem) 100%, 0% 100%)',
    '& > span': {
      backgroundColor: 'white',
      position: 'absolute',
      top: '12px',
      right: '15px',
      height: '5px',
      width: '5px',
      borderRadius: '100%',
    },
  },
  buttonDisabled: {
    backgroundColor: 'grey',
    textTransform: 'capitalize',
    marginBottom: '5px',
    '&.Mui-disabled': {
      background: '#c0c0c0',
      color: '#fff',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonRed: {
    color: theme.palette.common.white,
    backgroundColor: 'tomato',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'red',
    },
  },
  buttonPurpleOpacity: {
    color: '#986FEF',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    borderRadius: '10px',
    textTransform: 'capitalize',
    padding: '5px 15px',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  marginLeft: { marginLeft: '20px' },
  paddingLeft: {
    paddingLeft: '20px',
  },
  columnStyle: {
    fontSize: '11px',
    color: '#ADBAC3',
  },
  paddingRight: {
    padding: '10px',
    fontSize: '11px',
    color: '#ADBAC3',
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: { marginLeft: '0px' },
    paddingLeft: {
      paddingLeft: '0px',
    },
  },
}));

const ShortCloseBoqList = memo((props) => {
  const { currentProject, LabourContractor } = props;
  const classes = useStyles();
  let dispatch = useDispatch();
  const Accesses = {};
  Accesses['CHANGE_MANAGEMENT'] = useAccessPerView('CHANGE_MANAGEMENT');

  //! SELECTORS
  const { boqListV1, boqListV1Loading } = useSelector(productivityReducer);

  useEffect(() => {
    const fetchV1Boq = () => {
      dispatch(getBoqListV1(currentProject.projectId));
    };
    if (currentProject) {
      fetchV1Boq();
    }
  }, [currentProject]);

  const handleShortCloseBoq = async (selectedBoq) => {
    const boq = {
      contractorId: LabourContractor?.userId,
      boqId: selectedBoq?.boqId,
    };
    if (!LabourContractor || !selectedBoq) {
      if (!LabourContractor) {
        toast.error('Please Select Labour Contractor');
      } else {
        toast.error('Invalid BOQ ID');
      }
      return;
    }
    let resp = await dispatch(shortCloseBoq(boq));
    if (!resp.data.success) {
      toast.error(resp.data.errors[0]);
    } else {
      toast.success('Cost Code closed successfully');
      dispatch(getBoqListV1(currentProject.projectId));
    }
  };

  const handleReject = async (selectedBoq) => {
    const boq = {
      contractorId: LabourContractor?.userId,
      boqId: selectedBoq?.boqId,
    };
    if (!LabourContractor || !selectedBoq) {
      if (!LabourContractor) {
        toast.error('Please Select Labour Contractor');
      } else {
        toast.error('Invalid BOQ ID');
      }
      return;
    }
    const response = await dispatch(rejectChangeRequest(boq));
    if (!response.data.success) {
      toast.error(response.data.errors[0]);
    } else {
      toast.success('Change request has been successfully rejected');
      dispatch(getBoqListV1(currentProject.projectId));
    }
  };

  const handleApprove = async (selectedBoq) => {
    const boq = {
      contractorId: LabourContractor?.userId,
      boqId: selectedBoq?.boqId,
    };
    if (!LabourContractor || !selectedBoq) {
      if (!LabourContractor) {
        toast.error('Please Select Labour Contractor');
      } else {
        toast.error('Invalid BOQ ID');
      }
      return;
    }
    const response = await dispatch(approveChangeRequest(boq));
    if (!response.data.success) {
      toast.error(response.data.errors[0]);
    } else {
      toast.success('Change request has been successfully approved');
      dispatch(getBoqListV1(currentProject.projectId));
    }
  };

  //TODO: Reduce the cognitive complexity, (simplify conditionals)
  const renderAction = (boq) => {
    const approveButton = (
      <Button
        onClick={async () => handleApprove(boq)}
        className={classes.buttonGreenOpacity}
        style={{
          marginRight: '5px',
          padding: '5px 20px',
          marginBottom: 0,
          backgroundColor: '#81B73433',
        }}
      >
        Approve
      </Button>
    );

    const rejectButton = (
      <Button
        style={{
          padding: '5px 25px',
        }}
        onClick={async () => handleReject(boq)}
        className={classes.buttonRedOpacity}
      >
        Reject
      </Button>
    );

    const shortCloseButton = (
      <Button
        className={classes.buttonGreenOpacity}
        style={{ borderRadius: '5px', backgroundColor: '#81B73433' }}
        onClick={async () => {
          handleShortCloseBoq(boq);
        }}
      >
        Short Close
      </Button>
    );

    // BOQ IS LOCKED (DISABLED)
    if (boq.isLocked && boq.shortCloseDate !== null) {
      return (
        <Button
          className={classes.buttonGreenOpacity}
          style={{ borderRadius: '5px', backgroundColor: '#81B73433' }}
          disabled
        >
          Short Closed
        </Button>
      );
    }

    // BOQ IS APPROVED (DISABLED)
    if (
      boq.isApproved &&
      (Accesses['CHANGE_MANAGEMENT'].isEditMode ||
        Accesses['CHANGE_MANAGEMENT'].hasAccess.LEVEL1)
    ) {
      return shortCloseButton;
    }

    // IF BOQ IS NOT APPROVED (SHOW PENDING STATUS) -- has no access
    if (!boq.isApproved && Accesses['CHANGE_MANAGEMENT'].isEditMode) {
      return <Button disabled>Pending Request</Button>;
    }

    // IF BOQ IS NOT APPROVED (SHOW APPROVE OR REJECT ACTIONS) -- has access
    if (!boq.isApproved && Accesses['CHANGE_MANAGEMENT'].hasAccess.LEVEL1) {
      return (
        <Grid>
          {approveButton}
          {rejectButton}
        </Grid>
      );
    }
  };

  const renderBoqRow = (boq) => {
    return (
      <Accordion
        style={{
          minWidth: '100%',
          overflowY: 'auto',
          borderBottom: 0,
          width: '100%',
          margin: 0,
        }}
      >
        <AccordionSummary>
          <Typography style={{ alignContent: 'center' }}>
            {`WORKORDER # ${boq.workOrderNumber}`}
          </Typography>
          <Grid style={{ marginLeft: 'auto' }}>{renderAction(boq)}</Grid>
        </AccordionSummary>
      </Accordion>
    );
  };

  const renderLoader = () => {
    return <Typography style={{ padding: 10 }}>Loading ....</Typography>;
  };
  return (
    <Page
      className={classes.root}
      id="zoom-level"
      title="Productivity"
      style={{ zoom: '90%' }}
    >
      <Container maxWidth={true} style={{ margin: 0, padding: 0 }}>
        <Grid container xs={12}>
          {boqListV1Loading && renderLoader()}
          {!boqListV1Loading && boqListV1.length === 0 && (
            <Grid
              container
              style={{
                background: 'white',
                borderRadius: '10px',
              }}
            >
              <div style={{ padding: '1rem 0 1rem 10px' }}>No Data Found!</div>
            </Grid>
          )}
          {boqListV1.map((boq) => {
            return renderBoqRow(boq);
          })}
        </Grid>
      </Container>
    </Page>
  );
});

export default ShortCloseBoqList;
