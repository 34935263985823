import React, { useState } from 'react';
import {
  Button,
  Grid,
  makeStyles,
  TextField,
  Typography,
  IconButton,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Dialog,
  AppBar,
  Toolbar,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  DialogContent,
  DialogTitle,
  InputAdornment,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import moment from 'moment';
import EditIcon from '@material-ui/icons/Edit';

import VideocamIcon from '@material-ui/icons/Videocam';
import AttachmentIcon from '@material-ui/icons/Attachment';

import MicNoneIcon from '@material-ui/icons/MicNone';
import { useDispatch, useSelector } from 'react-redux';
import {
  getJobWorkers,
  jobReducer,
  selectJob,
  getJobSwitchWorkers,
} from 'src/redux/jobs';
import { useNavigate } from 'react-router';
import CloseIcon from '@material-ui/icons/Close';
import {
  markWorkerJob,
  switchWorkerJob,
  workersReducer,
} from 'src/redux/workers';
import toast from 'react-hot-toast';

const useStyles = makeStyles((theme) => ({
  listSection: {
    backgroundColor: 'inherit',
    margin: '5px',
    cursor: 'pointer',
  },

  ul: {
    backgroundColor: 'inherit',
    // padding: 0,
  },

  buttonGreen: {
    color: theme.palette.common.white,
    fontFamily: 'Roboto',
    backgroundColor: '#81B734',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    fontFamily: 'Roboto',
    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    color: '#986FEF',
    borderRadius: '10px',
    textTransform: 'capitalize',
    fontFamily: 'Roboto',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  headerCellText: {
    fontSize: '11px',
    fontWeight: 500,
    fontFamily: 'Roboto',
    lineHeight: '13.75px',
  },
  headerCellText1: {
    fontSize: '10px',
    fontWeight: 600,
    fontFamily: 'Roboto',
    color: '#ADBAC3',
  },
  textField: {
    // marginLeft: theme.spacing(1),
    paddingRight: '1.5rem',
    borderRadius: '10px',
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontWeight: 400,
  },
  input: {
    display: 'none',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  hover: {
    ':hover': {
      backgroundColor: '#A179F2',
    },
  },
  textFieldStyle: {
    fontWeight: 400,
    fontFamily: 'Roboto',
    fontSize: '15px',

    '&::placeholder': {
      fontWeight: 400,
      fontFamily: 'Roboto',
      fontSize: '15px',
      color: 'rgba(126, 131, 134, 0.7)',
    },
  },
  input: {
    borderRadius: '10px',
    backgroundColor: 'rgba(249, 249, 249, 1)',
  },
  audioVideoTitle: {
    color: 'rgba(173, 186, 195, 1)',
    fontSize: '14px',
    fontFamily: 'Roboto',
    fontWeight: 600,
  },
  audioVideoBoxes: {
    height: '45px',
    border: '1px dashed green',
    display: 'flex',
    justify: 'space-between',
    alignItems: 'center',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  audioVideoBoxesIcon: {
    color: 'rgba(129, 183, 52, 1)',
    backgroundColor: 'rgba(129, 183, 52, 0.2)',
    padding: '5px',
    borderRadius: ' 4px',
    marginLeft: '5px',
  },
  smallContainerMargin: {
    marginBottom: '1em',
  },
  // [theme.breakpoints.down('sm')]: {
  // 	smallContainerMargin: {
  // 		marginBottom: '1em',
  // 	},
  // },
}));

const JobDetails = (props) => {
  const [jobWorkersDialog, setJobWorkersDialog] = useState(false);
  const [reasonValue, setReasonValue] = useState('');
  const [isWorkerModal, setIsWorkerModal] = useState(false);
  const [checkedWorkers, setCheckedWorkers] = useState({});
  const [workerSearchValue, setWorkerSearchValue] = useState('');

  //! INSTACNES
  let classes = useStyles();
  let dispatch = useDispatch();
  let navigate = useNavigate();

  //! SELECTORS
  const {
    selectedJob: job,
    jobWorkers,
    jobWorkersLoading,
  } = useSelector(jobReducer);

  //! SELECTORS
  const { jobSwitchingLoading } = useSelector(workersReducer);

  //! METHODS
  const handleBack = () => {
    dispatch(selectJob(null));
    navigate('..');
  };

  const handleEditJob = () => {
    navigate('../edit');
  };
  const openJobWorkerList = () => {
    if (job?.jobId) {
      setJobWorkersDialog(true);
      dispatch(getJobWorkers(job?.jobId));
    }
  };
  const closeJobWorkersList = () => {
    setJobWorkersDialog(false);
  };

  const markReason = async (value, row) => {
    setReasonValue(value);
    let resp = await dispatch(
      markWorkerJob({
        workerId: row.workerId,
        jobId: job.jobId,
        reason: value,
      })
    );

    if (resp.status === 200) {
      toast.success('Worker Removed Successfully From This Job!');
      dispatch(getJobWorkers(job?.jobId));
    } else {
      toast.error('Something went wrong!');
    }
  };
  const openAddWorkerModal = () => {
    if (job?.jobId) {
      setIsWorkerModal(true);
      dispatch(getJobSwitchWorkers(job?.jobId));
    }
  };
  const closeAddWorkerModal = () => {
    setIsWorkerModal(false);
    setCheckedWorkers({});
  };

  const getSwitchingWorkers = () => {
    const workersList = [];
    Object.keys(checkedWorkers).forEach((workerId) => {
      if (checkedWorkers[workerId]) {
        workersList.push(Number(workerId));
      }
    });
    return workersList;
  };

  const handleSuccess = (resp) => {
    toast.success('Worker Switched Successfully');
    closeAddWorkerModal();
  };

  const handleFailure = (resp) => {
    toast.error(resp.data.error);
  };

  const handleSubmit = async () => {
    const workersList = getSwitchingWorkers();

    const apiData = {
      jobId: job.jobId,
      workerList: workersList,
    };

    let resp = await dispatch(switchWorkerJob(apiData));
    if (resp.status === 200) {
      handleSuccess(resp);
    } else {
      handleFailure(resp);
    }
  };

  const handleCheckbox = (workerId, isChecked) => {
    setCheckedWorkers((prev) => ({ ...prev, [workerId]: isChecked }));
  };

  const handleWorkerSearchValue = (e) => {
    let { value } = e.target;
    setWorkerSearchValue(value);
  };
  const handleSerachForWorker = () => {
    dispatch(getJobSwitchWorkers(job?.jobId, workerSearchValue));
  };

  return (
    <Grid container style={{ padding: '0 1rem ' }}>
      <Dialog
        open={jobWorkersDialog}
        onClose={closeJobWorkersList}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6">Remove Workers</Typography>
            </Grid>
            <Grid item>
              <CloseIcon
                onClick={closeJobWorkersList}
                style={{ cursor: 'pointer' }}
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>WORKER ID</TableCell>
                  <TableCell align="left">FULL NAME</TableCell>
                  <TableCell align="left">PHONE</TableCell>
                  <TableCell align="left">ADDRESS</TableCell>
                  <TableCell align="left" width="20%">
                    REMOVE
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {jobWorkersLoading
                  ? 'Loading...'
                  : jobWorkers?.workers?.length
                  ? jobWorkers?.workers?.map((row) => (
                      <TableRow key={row.workerId}>
                        <TableCell component="th" scope="row">
                          {row.workerId}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.fullName}
                        </TableCell>
                        <TableCell align="left">{row.phoneNumber}</TableCell>
                        <TableCell align="left">{row.address}</TableCell>
                        <TableCell align="left">
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Select Reason{' '}
                            </InputLabel>
                            <Select
                              onChange={(e) => markReason(e.target.value, row)}
                            >
                              <MenuItem value={reasonValue}>None</MenuItem>
                              <MenuItem value="NoShow">No Show</MenuItem>
                              <MenuItem value={'Tardiness'}>Tardiness</MenuItem>
                              <MenuItem value={'Misconduct'}>
                                Misconduct
                              </MenuItem>
                              <MenuItem value={'JobEnded'}>Job Ended</MenuItem>
                            </Select>
                          </FormControl>
                        </TableCell>
                      </TableRow>
                    ))
                  : 'No Record Found!'}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isWorkerModal}
        onClose={closeAddWorkerModal}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6">Add Workers</Typography>
            </Grid>
            <Grid item>
              <CloseIcon
                onClick={closeAddWorkerModal}
                style={{ cursor: 'pointer' }}
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            style={{ margin: '10px 0' }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={5}>
              {' '}
              <TextField
                type="search"
                size="small"
                label="Search"
                fullWidth
                variant="outlined"
                style={{ paddingRight: '10px' }}
                value={workerSearchValue}
                onChange={handleWorkerSearchValue}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                size="small"
                className={classes.buttonGreen}
                fullWidth
                style={{
                  padding: '.6rem 2rem',
                }}
                onClick={handleSerachForWorker}
              >
                {' '}
                Search
              </Button>
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">Select </TableCell>

                  <TableCell>WORKER ID</TableCell>
                  <TableCell align="left">FULL NAME</TableCell>
                  <TableCell align="left">AADHAR CARD NO</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {jobWorkersLoading
                  ? 'Loading...'
                  : jobWorkers?.workersList?.length
                  ? jobWorkers?.workersList?.map((row) => (
                      <TableRow key={row.workerId}>
                        <TableCell component="th" scope="row">
                          <Checkbox
                            checked={!!checkedWorkers[row.workerId]}
                            onChange={(e) =>
                              handleCheckbox(row.workerId, e.target.checked)
                            }
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.workerId}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.fullName}
                        </TableCell>
                        <TableCell align="left">{row.identityNumber}</TableCell>
                        {/* <TableCell align="left">{row.address}</TableCell> */}
                      </TableRow>
                    ))
                  : 'No Record Found!'}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid
            item
            xs={12}
            container
            justify="flex-end"
            style={{ margin: '10px 0' }}
          >
            <Grid item>
              <Button
                className={classes.buttonPurpleOpacity}
                style={{
                  padding: '.6rem 2rem',
                }}
                onClick={handleSubmit}
                // disabled={jobSwitchingLoading}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Grid
        container
        item
        xs={12}
        justify="space-between"
        alignItems="center"
        style={{ marginTop: '1em', marginBottom: '1em' }}
      >
        <Grid item xs={4} container alignItems="center">
          <IconButton onClick={handleBack}>
            <ArrowBackIcon color="black" fontSize="default" />
          </IconButton>
          <Typography variant="h5" style={{ fontFamily: 'Roboto' }}>
            {job?.projectName ?? 'N/A'}
          </Typography>
        </Grid>
        <Grid item xs={1} container justify="flex-end">
          <Grid item>
            <Button
              startIcon={<EditIcon />}
              className={classes.buttonPurpleOpacity}
              onClick={handleEditJob}
              style={{
                padding: '.6rem 1rem',
              }}
              disabled={!job?.jobId}
            >
              Edit
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justify="flex-end" alignItems="center">
        <Grid item style={{ marginRight: '10px' }}>
          <Button
            style={{
              textTransform: 'capitalize',
              fontSize: '12px',
              borderRadius: '5px',
            }}
            size="small"
            className={classes.buttonGreen}
            onClick={openAddWorkerModal}
          >
            Add Workers
          </Button>
        </Grid>
        <Grid item>
          <Button
            style={{
              textTransform: 'capitalize',
              fontSize: '12px',
              borderRadius: '5px',
            }}
            size="small"
            className={classes.buttonPurpleOpacity}
            onClick={openJobWorkerList}
          >
            Remove Workers
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        justify="space-between"
        alignItems="flex-start"
      >
        {/* <Grid item xs={4}></Grid> */}
        <Grid container item xs={12} justify="center">
          <Grid
            container
            item
            xs={6}
            style={{ marginBottom: 10, paddingRight: '1.5rem' }}
            justify="center"
          >
            <TextField
              InputLabelProps={{ style: { fontFamily: 'Roboto' } }}
              label="JOB TITLE / SKILL SET"
              fullWidth
              variant="filled"
              value={job?.jobName ?? 'N/A'}
              InputProps={{
                disableUnderline: true,

                classes: {
                  input: `${classes.textFieldStyle}`,
                  root: classes.input,
                },
              }}

              // className={` ${classes.textField} `}
              // InputProps={{ disableUnderline: true }}
            />
          </Grid>
          <Grid
            container
            item
            xs={6}
            style={{ marginBottom: 10 }}
            // justify='center'
          >
            {/* <TextField
							InputLabelProps={{ style: { fontFamily: 'Roboto' } }}
							label='SKILL LEVEL'
							fullWidth
							variant='filled'
							value={job?.skillTypeId ?? 'Not Specified'}
							InputProps={{
								disableUnderline: true,

								classes: {
									input: `${classes.textFieldStyle}`,
									root: classes.input,
								},
							}}

							// className={` ${classes.textField} `}
							// InputProps={{ disableUnderline: true }}
						/> */}
            <FormControl>
              <FormLabel component="legend" style={{ fontSize: '12px' }}>
                SKILL LEVEL
              </FormLabel>

              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                value={job?.skillTypeId}
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="Supervisor"
                  control={
                    <Radio
                    // onChange={ ( e ) => handleSkillCheckbox( 'Supervisor' ) }
                    />
                  }
                  label="Supervisor"
                />
                <FormControlLabel
                  value="Skilled"
                  control={
                    <Radio
                    // onChange={ ( e ) => handleSkillCheckbox( 'Skilled' ) }
                    />
                  }
                  label="Skilled"
                />
                <FormControlLabel
                  value="Helper"
                  control={
                    <Radio
                    // onChange = {( e ) => handleSkillCheckbox('Helper')}
                    />
                  }
                  label="Helper"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justify="space-between"
            style={{ marginTop: '1em', marginBottom: '1em' }}
          >
            <Grid item xs={12} md={6} style={{ marginBottom: '1em' }}>
              <TextField
                InputLabelProps={{ style: { fontFamily: 'Roboto' } }}
                label="WORKERS REQUIRED"
                fullWidth
                variant="filled"
                value={`${job?.activeWorkers ?? 'N/A'}/${
                  job?.requiredWorkers ?? 'N/A'
                }`}
                className={` ${classes.textField} `}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    input: `${classes.textFieldStyle}  `,
                    root: classes.input,
                  },
                }}

                // InputProps={{ disableUnderline: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                InputLabelProps={{ style: { fontFamily: 'Roboto' } }}
                variant="filled"
                label="PROJECT NAME"
                value={job?.projectName ?? 'N/A'}
                fullWidth
                // InputProps={{ disableUnderline: true }}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    input: `${classes.textFieldStyle}  `,
                    root: classes.input,
                  },
                }}

                // className={`${classes.textFieldStyle} `}
              />
            </Grid>
          </Grid>
          <Grid container item style={{ marginBottom: 10 }}>
            <Grid
              container
              item
              xs={6}
              style={{ paddingRight: '1.5rem' }}
              justify="center"
            >
              <TextField
                label="SUPERVISOR"
                InputLabelProps={{ style: { fontFamily: 'Roboto' } }}
                fullWidth
                variant="filled"
                value={job?.supervisorName ?? 'N/A'}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    input: `${classes.textFieldStyle}  `,
                    root: classes.input,
                  },
                }}

                // className={`${classes.textFieldStyle} `}
                // InputProps={{ disableUnderline: true }}
              />
            </Grid>
            <Grid item container xs={6}>
              <Grid
                container
                item
                xs={6}
                // justify='center'
              >
                <FormControl>
                  <FormLabel component="legend" style={{ fontSize: '12px' }}>
                    JOB STATUS
                  </FormLabel>

                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={job?.isPrivate}
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value={false}
                      control={
                        <Radio
                        // onChange={ ( e ) => handleSkillCheckbox( 'Supervisor' ) }
                        />
                      }
                      label="Public"
                    />
                    <FormControlLabel
                      value={true}
                      control={
                        <Radio
                        // onChange={ ( e ) => handleSkillCheckbox( 'Skilled' ) }
                        />
                      }
                      label="Private"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography
                  style={{
                    fontSize: 12,
                    color: '#ADBAC3',
                    marginBottom: 5,
                    fontFamily: 'Roboto',
                  }}
                >
                  JOB TYPE
                </Typography>{' '}
                <FormControl component="fieldset">
                  <RadioGroup row defaultValue={job?.isNMR} value={job?.isNMR}>
                    <FormControlLabel
                      value={true}
                      control={
                        <Radio
                        // onChange={(e) =>
                        // formik.setFieldValue("isPrivate", false)
                        // }
                        />
                      }
                      label="NMR"
                    />
                    <FormControlLabel
                      // value={false}
                      value={false}
                      control={
                        <Radio
                        // onChange={(e) =>
                        // formik.setFieldValue("isPrivate", true)
                        // }
                        />
                      }
                      label="PRW"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justify="space-between"
            style={{ marginTop: '1em', marginBottom: '1em' }}
          >
            <Grid item xs={6}>
              <TextField
                InputLabelProps={{ style: { fontFamily: 'Roboto' } }}
                label="START DATE"
                variant="filled"
                fullWidth
                value={` ${moment(job?.startDate).format('DD MMM, YYYY')} `}
                className={` ${classes.textField} `}
                // InputProps={{ disableUnderline: true }}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    input: `${classes.textFieldStyle}  `,
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                InputLabelProps={{ style: { fontFamily: 'Roboto' } }}
                label="MAN DAYS"
                variant="filled"
                fullWidth
                value={job?.manDays}
                // className={` ${classes.textField} `}
                // InputProps={{ disableUnderline: true }}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    input: `${classes.textFieldStyle}  `,
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            {/* <Grid item xs={6}>
							<TextField
								InputLabelProps={{ style: { fontFamily: 'Roboto' } }}
								variant='filled'
								label='SKILL LEVEL'
								fullWidth
								value={job?.skillLevel + '%'}
								className={`${classes.textFieldStyle} `}
								InputProps={{
									disableUnderline: true,
									classes: {
										input: `${classes.textFieldStyle}  `,
										root: classes.input,
									},
								}}

								// InputProps={{
								// 	disableUnderline: true,
								// 	style: { fontFamily: 'Roboto', fontWeight: 400 },
								// }}
							/>
						</Grid> */}
          </Grid>
          <Grid item xs={12} style={{ marginBottom: '1em' }}>
            <TextField
              InputLabelProps={{ style: { fontFamily: 'Roboto' } }}
              // placeholder='Enter your project name'
              label="LOCATION"
              fullWidth
              variant="filled"
              value={job?.cityName ?? 'N/A'}
              // className={`${classes.textFieldStyle} `}
              InputProps={{
                disableUnderline: true,
                classes: {
                  input: `${classes.textFieldStyle}  `,
                  root: classes.input,
                },
              }}

              // InputProps={{ disableUnderline: true }}
            />
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 10 }}>
            <TextField
              InputLabelProps={{ style: { fontFamily: 'Roboto' } }}
              // placeholder='Enter your project name'
              label="SCOPE OF WORK"
              fullWidth
              variant="filled"
              value={job?.description ?? 'N/A'}
              // className={`${classes.textFieldStyle} `}
              InputProps={{
                disableUnderline: true,
                classes: {
                  input: `${classes.textFieldStyle}  `,
                  root: classes.input,
                },
              }}

              // InputProps={{ disableUnderline: true }}
            />
          </Grid>
          <Grid
            container
            item
            xs={12}
            justify="space-between"
            style={{ marginTop: '1em', marginBottom: '1em' }}
          >
            <Grid item xs={12} md={6} className={classes.smallContainerMargin}>
              <TextField
                InputLabelProps={{ style: { fontFamily: 'Roboto' } }}
                placeholder="Select"
                label="TOTAL WAGES"
                variant="filled"
                fullWidth
                // value='Total  ₹27,945 '
                value={job?.totalWage ?? 'N/A'}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    input: `${classes.textFieldStyle}  `,
                    root: classes.input,
                  },
                }}
                className={` ${classes.textField} `}
                // InputProps={{ disableUnderline: true }}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.smallContainerMargin}>
              <TextField
                InputLabelProps={{ style: { fontFamily: 'Roboto' } }}
                variant="filled"
                label="DAILY WAGES"
                fullWidth
                value={`Daily  ₹${job?.dailyWage ?? 'N/A'}`}
                // InputProps={{ disableUnderline: true }}
                // className={`${classes.textFieldStyle} `}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    input: `${classes.textFieldStyle}  `,
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6} style={{ paddingRight: '1.5rem' }}>
              <TextField
                InputLabelProps={{ style: { fontFamily: 'Roboto' } }}
                variant="filled"
                label="CONTACT NUMBER"
                fullWidth
                value={job?.contactNumber ?? 'N/A'}
                // InputProps={{ disableUnderline: true }}
                // className={`${classes.textFieldStyle} `}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    input: `${classes.textFieldStyle}  `,
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              Food: {job?.isFood ? 'YES' : 'NO'}
              <br />
              Accomodation: {job?.isAccomodation ? 'YES' : 'NO'}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid container item xs={12} style={{ marginTop: 15, marginBottom: 15 }}>
				<Grid item xs={12}>
					<Typography variant='h5' style={{ fontFamily: 'Roboto' }}>
						Job Instructions
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<img
						src='/static/images/bettamintImages/jobinstructions.png'
						alt='job instructions'
						style={{ width: '500px', marginTop: '1em' }}
					/>
				</Grid>
			</Grid> */}

      <Grid container>
        {job?.jobMedia.map((media, index) => (
          <Grid
            item
            xs={6}
            key={index}
            style={{ paddingRight: index === 0 && '1rem' }}
          >
            {media.mediaTypeId === 'Audio' ? (
              <div>
                <label htmlFor="audio">
                  {/* <Button
										fullWidth
										variant='contained'
										color='primary'
										component='span'
										startIcon={<VideoCallIcon />}
									>
										Upload Video
									</Button> */}
                  <div className={classes.audioVideoBoxes}>
                    <AttachmentIcon
                      fontSize="large"
                      className={classes.audioVideoBoxesIcon}
                    />
                    <p
                      style={{
                        color: 'rgba(173, 186, 195, 1)',
                        fontFamily: 'Roboto',
                      }}
                      className="dots"
                    >
                      <a
                        href={`${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${media.url}`}
                        target="_blank"
                        style={{ fontFamily: 'Roboto' }}
                      >
                        {media.fileName}
                      </a>
                    </p>
                    <MicNoneIcon
                      fontSize="large"
                      style={{
                        color: 'rgba(129, 183, 52, 1)',
                        marginRight: '5px',
                      }}
                    />
                  </div>
                </label>
              </div>
            ) : (
              <div>
                <label htmlFor="video">
                  {/* <Button
										fullWidth
										variant='contained'
										color='primary'
										component='span'
										startIcon={<AudiotrackIcon />}
									>
										Upload Audio
									</Button> */}
                  <div className={classes.audioVideoBoxes}>
                    <AttachmentIcon
                      fontSize="large"
                      className={classes.audioVideoBoxesIcon}
                    />
                    <p
                      style={{
                        color: 'rgba(173, 186, 195, 1)',
                        fontFamily: 'Roboto',
                      }}
                      className={'dots'}
                    >
                      <a
                        href={`${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${media.url}`}
                        target="_blank"
                      >
                        {media.fileName}
                      </a>
                    </p>

                    <VideocamIcon
                      fontSize="large"
                      style={{
                        color: 'rgba(129, 183, 52, 1)',
                        marginRight: '5px',
                      }}
                    />
                  </div>
                </label>
              </div>
            )}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
export default JobDetails;
