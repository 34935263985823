import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import DatePicker from 'react-datepicker';

import { Line } from 'react-chartjs-2';

import defaultProjectImage from '../../../assests/Projects Icon.png';

import { useDispatch, useSelector } from 'react-redux';
import {
  getProjectsClassificationList,
  projectReducer,
} from 'src/redux/projects';
import { Autocomplete } from '@material-ui/lab';

export default function ContractorProductionRate(props) {
  const { classes } = props;

  const [currentProject, setCurrentProject] = useState(null);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [classifiedProject, setClassifiedProject] = useState('');
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const state = {
    dataSet: [
      [60, 90, 55, 62, 95, 65, 72, 60],
      [70, 60, 80, 50, 85, 52, 85, 70],
    ],
    labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG'],
    options: {
      tooltips: {
        enabled: true,
        callbacks: {
          title: function (tooltipItem, data) {
            return `1-30 ${data['labels'][tooltipItem[0]['index']]}`;
          },
          label: function (tooltipItem, data) {
            return `${data['datasets'][0]['data'][tooltipItem['index']]}% Rate`;
          },
        },
        backgroundColor: 'black',
        titleFontSize: 16,
        titleFontColor: 'white',
        bodyFontColor: 'white',
        bodyFontSize: 14,
        displayColors: false,
        textAlign: 'center',
      },

      scales: {
        xAxes: [
          {
            gridLines: { display: false, color: 'grey' },
            ticks: { fontColor: '#3C3C3C', fontSize: 10 },
          },
        ],
        yAxes: [
          {
            scaleLabel: {
              display: false,
              labelString: 'Color Strength',
              fontSize: 14,
            },
            ticks: {
              display: true,
              min: 0,
              max: 100,
              scaleSteps: 10,
              scaleStartValue: 0,
              maxTicksLimit: 5,
              fontColor: '#9B9B9B',
              callback: (point) => (point < 0 ? '' : point),
            },
            gridLines: {
              display: false,
              offsetGridLines: true,
              color: '3C3C3C',
              tickMarkLength: 4,
            },
          },
        ],
      },
      legend: {
        display: false,
      },
    },
  };

  const data = {
    labels: state.labels,
    datasets: [
      {
        label: 'Number Of Workers',
        data: state.dataSet[0],
        lineTension: 0,
        backgroundColor: '#81B73433',
        borderColor: '#81B734',
        borderWidth: 1,
        pointHoverRadius: 10,
        pointBorderWidth: 0,
        spanGaps: false,
        dragData: false,
      },
      {
        label: 'Contractor Production Rate',
        data: state.dataSet[1],
        lineTension: 0,
        borderColor: '#A179F2',
        borderWidth: 0,
        pointBorderWidth: 0,
        spanGaps: false,
      },
    ],
  };

  const [startDate, endDate] = dateRange;
  let dispatch = useDispatch();

  const { projectClassificationList } = useSelector(projectReducer);

  useEffect(() => {
    if (projectClassificationList.length === 0) {
      dispatch(getProjectsClassificationList());
    }
  }, [projectClassificationList]); // eslint-disable-line

  const handleProjectChange = (project) => {
    setClassifiedProject([project]);
    setCurrentProject(project);
  };

  return (
    <Grid item container className={classes.graphContainer}>
      <Grid item container className={classes.graph}>
        <Grid item container style={{ height: '100%' }}>
          <Grid item container className={classes.graphTitle}>
            <Typography variant={isSmallScreen ? 'h6' : 'h5'}>
              Contractor Production Rate
            </Typography>
            <Button className={classes.purpleOpacity}>View Details</Button>
          </Grid>
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.fontSmall}
            style={{ padding: '0px 15px' }}
          >
            <Grid item xs={6}>
              <Grid container alignItems="center">
                <Grid item style={{ paddingRight: '10px' }}>
                  <Avatar
                    alt="Project Image"
                    style={{
                      width: '35px',
                      height: '35px',
                      marginLeft: '.5rem',
                    }}
                    src={
                      !classifiedProject[0]?.url
                        ? defaultProjectImage
                        : `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${classifiedProject[0]?.url}`
                    }
                  />
                </Grid>
                <Grid item>
                  <Autocomplete
                    autoSelect
                    size="small"
                    options={projectClassificationList}
                    getOptionLabel={(option) => option.name}
                    style={{ minWidth: '150px' }}
                    value={currentProject}
                    onChange={(event, newValue) => {
                      handleProjectChange(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Select Project"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              alignItems="center"
              justifyContent="space-between"
              xs={6}
            >
              <Typography style={{ fontSize: '0.9rem' }}>Filters:</Typography>
              <Grid style={{ width: '80%' }}>
                <DatePicker
                  dateFormat="dd MMM, yyyy"
                  showIcon
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            style={{ padding: '0xpx 20px' }}
          >
            <Line data={data} options={state.options} />
          </Grid>
          <Grid container justify="space-between">
            <Grid
              item
              xs={12}
              container
              justify="space-between"
              alignItems="center"
              style={{ padding: '0px 20px' }}
            >
              <Grid item container xs={5}>
                <Typography>Avg. Production Rate 73%</Typography>
              </Grid>
              <Grid
                item
                container
                xs={7}
                alignItems="center"
                justifyContent="space-around"
              >
                <Grid
                  item
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: 'rgba(178, 190, 199, 1)',
                    }}
                    className={classes.fontSmall}
                  >
                    Number Of Workers
                  </Typography>
                  <div className="skilled-box"></div>
                </Grid>
                <Grid
                  item
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: 'rgba(178, 190, 199, 1)',
                    }}
                    className={classes.fontSmall}
                  >
                    Contractor Production Rate
                  </Typography>
                  <div className="supervisor-box"></div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
