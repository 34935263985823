import { Button, Grid, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';

import arrow from '../../../assests/arrow.png';
import { DynamicCell } from '../SOWTable';
import pin from '../../../assests/Pin.png';
import toast, { Toaster } from 'react-hot-toast';

const DescriptionRow = (props) => {
  const { classes, description, onSave, grossProgress } = props;

  const initialQuantity = description?.RemainingQuantity || '';
  const rowBoqId = description?.BOQId || '';

  const successIndicatorColor = '#81B734';
  const defaultColor = 'rgba(1,1,1,1)';

  const [imgVisible, setImgVisible] = useState(null);
  const [progress, setProgress] = useState('');
  const [remarks, setRemarks] = useState('');
  const [newQuantity, setNewQuantity] = useState(initialQuantity);
  const [errors, setErrors] = useState({ progress: false, remarks: false });
  const [activeColor, setActiveColor] = useState(defaultColor);

  const checkTouchedStatus = () => {
    if (!grossProgress[description.BOQId]) {
      grossProgress[description.BOQId] = {
        sowId: description.ScopeOfWorkId,
        setErrorFallback: setErrors,
      };
    }
  };

  const handleProgressChange = (e) => {
    if (errors.progress) {
      setErrors((prev) => ({ ...prev, progress: false }));
    }
    const updatedProgress = e.target.value;
    if (!updatedProgress || updatedProgress.match(/^\d+$/)) {
      setProgress(updatedProgress);
      checkTouchedStatus();
      grossProgress[description.BOQId].quantity = updatedProgress;
    }
  };

  const handleRemarksChange = (e) => {
    if (errors.remarks) {
      setErrors((prev) => ({ ...prev, progress: false }));
    }
    const updatedRemarks = e.target.value;
    setRemarks(updatedRemarks);
    checkTouchedStatus();
    grossProgress[description.BOQId].quantity = updatedRemarks;
  };

  const handleImage = (e, id) => {
    const uploadedImage = e.target.files[0];
    e.stopPropagation();
    if (uploadedImage) {
      checkTouchedStatus();
      grossProgress[description.BOQId].image = e.target.files[0];
      setImgVisible(uploadedImage);
    }
  };

  const cleanUp = () => {
    setRemarks('');
    setProgress('');
    setImgVisible(null);
  };

  const errorFallback = () => {
    setActiveColor(defaultColor);
    setNewQuantity(initialQuantity);
    setErrors({ remarks: true, progress: true });
    toast.error('There was an error saving the progress.');
  };

  const preamptSuccess = () => {
    setActiveColor(successIndicatorColor);
    setTimeout(() => {
      setActiveColor(defaultColor);
    }, 5000);
  };

  const handleSave = (e) => {
    e.stopPropagation();
    if (!progress) {
      toast.error('Progress field is required.');
      return;
    }
    const { BOQId, ScopeOfWorkId } = description;
    onSave(BOQId, progress, remarks, imgVisible, ScopeOfWorkId, errorFallback);
    preamptSuccess();
    setNewQuantity((prevQuantity) => prevQuantity - progress);
    cleanUp();
  };

  return (
    <Grid
      item
      container
      style={{
        padding: '5px 10px',
      }}
    >
      <Grid item container xs={12}>
        <Grid item style={{ width: '10%' }}></Grid>
        <Grid item style={{ width: '14%' }}>
          <Typography style={{ fontSize: 10 }}>Description</Typography>
          <DynamicCell fontSize={12} value={description?.Description || ''} />
        </Grid>
        {console.log(description.IsLocked)}
        <Grid item style={{ width: '6%' }}>
          <Typography style={{ fontSize: 10 }}>Unit</Typography>
          <Typography> {description?.UnitName}</Typography>
        </Grid>
        <Grid item style={{ width: '10%' }}>
          <Typography
            className={classes.remainingWorkValue}
            style={{ color: activeColor, fontSize: 10 }}
          >
            Remaining Work
          </Typography>
          <Typography
            className={classes.remainingWorkValue}
            style={{ color: activeColor }}
          >
            {newQuantity}
          </Typography>
        </Grid>
        <Grid item style={{ width: '6%' }}>
          <Typography style={{ fontSize: 10 }}>Rate</Typography>
          <Typography>{description?.Rate}</Typography>
        </Grid>
        <Grid item style={{ width: '8%' }}>
          <Typography
            style={{ fontSize: 10, color: activeColor }}
            className={classes.remainingWorkValue}
          >
            Amount
          </Typography>
          <Typography
            style={{ color: activeColor }}
            className={classes.remainingWorkValue}
          >
            {newQuantity * description?.Rate}
          </Typography>
        </Grid>
        <Grid item style={{ width: '12%' }}>
          <TextField
            label={`Todays Progress`}
            size="small"
            variant="filled"
            InputProps={{
              disableUnderline: !errors.progress,
              style: {
                borderRadius: '7px',
              },
            }}
            type="text"
            InputLabelProps={{
              style: {
                color: '#8E99A8',
                fontSize: 'small',
              },
            }}
            style={{ margin: '5px' }}
            required
            value={progress || ''}
            onChange={handleProgressChange}
            error={errors.progress}
            disabled={description.IsLocked}
          />
        </Grid>
        <Grid item style={{ width: '12%' }}>
          <TextField
            label="Remarks"
            size="small"
            variant="filled"
            InputProps={{
              disableUnderline: !errors.progress,
              style: {
                borderRadius: '7px',
              },
            }}
            InputLabelProps={{
              style: {
                color: '#8E99A8',
                fontSize: 'small',
              },
            }}
            style={{
              margin: '5px',
            }}
            value={remarks || ''}
            onChange={handleRemarksChange}
            error={errors.remarks}
            disabled={description.IsLocked}
          />
        </Grid>
        <Grid item style={{ width: '12%', paddingTop: 4 }}>
          <input
            // accept="image/*"
            className={classes.input1}
            id={`icon-button-file-description-${rowBoqId}`}
            type="file"
            onChange={(e) => handleImage(e)}
          />

          <label
            htmlFor={`icon-button-file-description-${rowBoqId}`}
            className={classes.ImagePlaceHolder}
          >
            {imgVisible ? (
              <img
                src={`${URL?.createObjectURL(imgVisible)}`}
                alt="project url"
                style={{
                  width: '50px',
                  height: '50px',
                  borderRadius: '50%',
                }}
              />
            ) : (
              <Button
                onClick={() => {
                  document
                    .getElementById(`icon-button-file-description-${rowBoqId}`)
                    .click();
                }}
                disabled={description.IsLocked}
                style={{
                  border: '1px dotted rgba(1,1,1,0.2)',
                  backgroundColor: 'white',
                  padding: '5px 15px',
                  fontSize: 11,
                  fontWeight: 'normal',
                  textTransform: 'capitalize',
                  height: 45,
                  borderRadius: 8,
                }}
                startIcon={
                  <img
                    src={pin}
                    alt="upload "
                    style={{
                      backgroundColor: 'rgba(1,1,1,0.1)',
                      padding: '5px 10px',
                    }}
                  />
                }
              >
                Upload
              </Button>
            )}
          </label>
        </Grid>
        <Grid item style={{ width: '10%', paddingTop: 4 }}>
          <Button
            fullWidth
            className={classes.buttonPurple}
            style={{
              color: '#fff',
              padding: '10px 0',
              borderRadius: '7px',
            }}
            size="small"
            endIcon={
              <img src={arrow} alt="save" style={{ fontSize: '15px' }} />
            }
            disabled={description.IsLocked}
            onClick={handleSave}
          >
            Save
          </Button>
        </Grid>
      </Grid>
      <Toaster />
    </Grid>
  );
};

export default DescriptionRow;
