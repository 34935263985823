import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  makeStyles,
  Typography,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  InputAdornment,
  Avatar,
  Checkbox,
  TableContainer,
  AppBar,
  Toolbar,
} from '@material-ui/core';
import {
  approveAttendance,
  attendanceReducer,
  getWorkerAttendance,
  putNullWorkerAttendance,
  selectWorker,
  sendAdvancePayment,
  showPayment,
} from 'src/redux/attendance';
import { useDispatch, useSelector } from 'react-redux';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';

import User from '../../assests/worker2.png';
import User1 from '../../assests/Rectangle 4242.png';
import EventNoteIcon from '@material-ui/icons/EventNote';
import es from 'date-fns/locale/es';

import DatePicker, { registerLocale } from 'react-datepicker';

import { downloadExcel } from 'react-export-table-to-excel';

import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import {
  getWorkers,
  setWorkerForDetail,
  workersReducer,
} from 'src/redux/workers';

import attendanceListImage from '../../assests/attendanceStatusList.jpg';
import { Autocomplete } from '@material-ui/lab';
import useAccessPerView from 'src/common/Access';

registerLocale('es', es);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    fontFamily: 'Roboto',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    color: '#986FEF',
    fontFamily: 'Roboto',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
    overflow: {
      overflowX: 'hidden',
    },
  },
  square: {
    width: '120px',
    height: '120px',
    borderRadius: '20px',
  },
  squareProject: {
    width: '50px',
    height: '50px',
    borderRadius: '10px',
  },
  projectInput: {
    fontSize: '12px !important',
  },
  marginTop: {
    marginTop: '0',
  },
  appBar: {
    position: 'relative',
  },
  [theme.breakpoints.down('sm')]: {
    overflow: {
      overflowX: 'scroll',
    },
    marginTop: {
      marginTop: '15px',
    },
  },
}));

const AttendanceDetail = () => {
  const [advancePaymentModal, setAdvancePaymentModal] = useState(false);

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const classes = useStyles();
  let dispatch = useDispatch();
  let { addToast } = useToasts();
  const history = useNavigate();
  const [advancePayment, setAdvancePayment] = useState({
    requestAmount: null,
    transactionType: null,
  });
  const [reportModal, setReportModal] = useState(false);

  const Accesses = {};
  Accesses['MUSTERROLL_MANAGEMENT'] = useAccessPerView('MUSTERROLL_MANAGEMENT');
  // const [profilePicture, setProfilePicture] = useState(null)

  //! SELECTORS
  const {
    detailLoading,
    workersAttendanceList,
    detailError,
    selectedWorkerId,
    selectedJobId,
    approvingLoading,
    selectedWorker,
    redirectFromPayment,
    advancePaymentLoading,
  } = useSelector(attendanceReducer);
  const { workersList } = useSelector(workersReducer);
  const [tempData, setTempData] = useState([]);

  //! DEPLOYEMNT FORM WALEEDS ACCOUNT
  useEffect(() => {
    dispatch(getWorkers(selectedWorker?.projectId));
    setTempData(workersAttendanceList?.attendance);
    return () => {};
  }, [selectedWorker?.projectId, workersAttendanceList?.attendance.length]);

  const handleAttendanceApprove = async (obj, date) => {
    let resp = await dispatch(
      approveAttendance(
        obj?.jobId,
        obj?.workerId,
        obj.date,
        obj?.value?.hours,
        obj?.value?.name
      )
    );
    if (resp?.status === 200) {
      addToast('Attendance Approved Successfully!', {
        appreance: 'success',
        autoDismiss: true,
      });
      dispatch(getWorkerAttendance(selectedJobId, selectedWorkerId));
    } else {
      addToast(resp?.data?.error, {
        appreance: 'error',
        autoDismiss: true,
      });
    }
  };
  const openAdvancePaymentModal = (transactionType) => {
    setAdvancePayment({
      ...advancePayment,
      transactionType,
    });
    setAdvancePaymentModal(true);
  };
  const handleDateRange = (update) => {
    let [start, end] = update;
    setDateRange(update);

    if (end) {
      dispatch(
        getWorkerAttendance(
          selectedWorker?.jobId,
          selectedWorker?.workerId,
          false,
          moment(start).format('YYYY-MM-DD'),
          moment(end).format('YYYY-MM-DD')
        )
      );
    } else if (!start && !end) {
      dispatch(
        getWorkerAttendance(
          selectedWorker.jobId,
          selectedWorker.workerId,
          false
        )
      );
    }
  };

  const proceedPayment = async () => {
    const { workerId, jobId } = selectedWorker;
    const { transactionType, requestAmount } = advancePayment;
    let resp = await dispatch(
      sendAdvancePayment(jobId, workerId, transactionType, requestAmount)
    );

    if (resp?.message) {
      setAdvancePaymentModal(false);

      addToast(resp.message, {
        appreance: 'warning',
        autoDismiss: true,
      });
    }
    if (resp.status === 200) {
      setAdvancePaymentModal(false);
      addToast('Payment Sent Successfully!', {
        appreance: 'success',
        autoDismiss: true,
      });
      dispatch(getWorkerAttendance(selectedJobId, selectedWorkerId));
    }
  };

  const header = [
    'DATE',
    'ATTENDANCE',
    'ADVANCE',
    'VERIFIED',
    'CHECK-IN',
    'CHECK-OUT',
  ];
  let body = workersAttendanceList?.attendance?.map((val) => [
    val.date ? moment(val.date).format('DD MMM') : '-',
    val.hoursAbbreviation,
    val.advance === 0 ? '-' : val.advance,
    val.hoursAbbreviation === 'A' ||
    val.hoursAbbreviation === '1/2 P' ||
    (val.hoursAbbreviation === 'P' && (!val.checkOut || val.checkOut === ''))
      ? 'Not Verified'
      : 'Verified',
    val.checkIn ? moment(val.checkIn).add(330, 'minutes').format('LLL') : '-',
    val.checkOut ? moment(val.checkOut).add(330, 'minutes').format('LLL') : '-',
  ]);
  async function handleDownloadExcel() {
    downloadExcel({
      fileName: `MusterCard_${selectedWorker?.workerName ?? '-'}`,
      sheet: 'Muster Roll Sheet - Bettamint',
      tablePayload: {
        header,
        // accept two different data structures
        body: [
          ...body,
          ['', '', '', '', '', ''],
          ['-', '-', '-', '-', '-', '-'],
          ['Worker Name:', selectedWorker?.workerName ?? '-', '', '', '', ''],
          ['Worker Skill:', selectedWorker?.sKillName ?? '-', '', '', '', ''],
          ['Project Name:', selectedWorker?.projectName ?? '-', '', '', '', ''],
          [
            'Project Address:',
            workersAttendanceList?.cityName ?? '-',
            '',
            '',
            '',
            '',
          ],
        ],
      },
    });
  }

  const findWorker = () => {
    let data = workersList.filter(
      (item) => item.workerId === selectedWorker?.workerId
    );
    dispatch(setWorkerForDetail(data[0]));
  };
  const handleClick = (data) => {
    setTempData(
      workersAttendanceList?.attendance?.map((item) => {
        if (data.date === item.data) {
          return {
            ...item,
            check: true,
          };
        } else {
          return item;
        }
      })
    );
  };

  console.log('SelectedWorker', selectedWorker);
  const renderViewCardDetails = () => (
    <Grid container style={{}}>
      <Dialog
        open={advancePaymentModal}
        onClose={() => setAdvancePaymentModal(false)}
      >
        {/* <DialogTitle>
					<p style={{ fontFamily: 'Roboto' }}>
						{' '}
						Total Outstanding Payment ₹240{' '}
					</p>
				</DialogTitle> */}
        <DialogContent>
          <DialogContentText>
            <Grid container>
              {/* <div
								style={{
									position: 'absolute',
									top: '50%',
									width: '100%',
									textAlign: 'center',
								}}>
								<CircularProgress style={{ color: 'RGB(132, 185, 57)' }} />
							</div> */}
              <Grid item xs={12} style={{ marginBottom: '15px' }}>
                <p>Enter Advance Payment Amount Below</p>
              </Grid>
              <Grid xs={12}>
                <TextField
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">₹</InputAdornment>
                    ),
                  }}
                  onChange={(e) =>
                    setAdvancePayment({
                      ...advancePayment,
                      requestAmount: parseInt(e.target.value),
                    })
                  }
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            style={{
              textTransform: 'capitalize',
              backgroundColor: 'RGB(226, 220, 247)',
              color: 'RGB(155, 112, 240)',
            }}
            variant="outlined"
            onClick={proceedPayment}
            startIcon={
              advancePaymentLoading ? (
                <CircularProgress
                  size={20}
                  style={{ color: 'RGB(155, 112, 240)' }}
                />
              ) : (
                ''
              )
            }
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
      {/* REPORT */}
      <Dialog
        open={reportModal}
        maxWidth={'lg'}
        fullWidth={'lg'}
        onClose={() => setReportModal(false)}
      >
        <AppBar
          className={classes.appBar}
          style={{ backgroundColor: '#A179F2' }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setReportModal(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>

            <Grid item container justify="flex-end" alignItems="center" xs={12}>
              <Grid item>
                <Typography
                  style={{
                    color: '#90ee90',
                    fontSize: 12,
                    marginRight: 5,
                    fontFamily: 'Roboto',
                    cursor: 'pointer',
                  }}
                  onClick={handleDownloadExcel}
                >
                  Download NMR
                </Typography>
              </Grid>
              <Grid
                item
                container
                justify="center"
                alignItems="center"
                style={{
                  background: '#81B734',
                  width: 25,
                  height: 25,
                  borderRadius: 5,
                  cursor: 'pointer',
                }}
                onClick={handleDownloadExcel}
              >
                <SystemUpdateAltIcon
                  fontSize="small"
                  style={{ color: 'white', textAlign: 'center' }}
                />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>

        <Grid item xs={12} container style={{ padding: '1rem' }}>
          <Grid item md={4} container>
            <Grid container item md={11} xs={12} justify="space-between">
              <Grid item xs={5}>
                <Typography style={{ fontSize: '14px', fontWeight: 700 }}>
                  Worker ID:{' '}
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography style={{ fontSize: '14px' }} align="start">
                  {selectedWorker?.workerId}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              item
              md={11}
              xs={12}
              justify="space-between"
              style={{}}
            >
              <Grid item xs={5}>
                <Typography style={{ fontSize: '14px', fontWeight: 700 }}>
                  Worker Name:
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography style={{ fontSize: '14px' }} align="start">
                  {selectedWorker?.workerName}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              item
              md={11}
              xs={12}
              justify="space-between"
              style={{}}
            >
              <Grid item xs={5}>
                <Typography style={{ fontSize: '14px', fontWeight: 700 }}>
                  Gender:
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography style={{ fontSize: '14px' }} align="start">
                  N/A
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              item
              md={11}
              xs={12}
              justify="space-between"
              style={{}}
            >
              <Grid item xs={5}>
                <Typography style={{ fontSize: '14px', fontWeight: 700 }}>
                  Date of Birth:
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography style={{ fontSize: '14px' }} align="start">
                  N/A
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={4} container>
            <Grid container item xs={12} md={11} justify="space-between">
              <Grid item xs={5}>
                <Typography style={{ fontSize: '14px', fontWeight: 700 }}>
                  Skill:
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography style={{ fontSize: '14px' }} align="start">
                  {selectedWorker?.sKillName}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={11}
              justify="space-between"
              style={{}}
            >
              <Grid item xs={5}>
                <Typography style={{ fontSize: '14px', fontWeight: 700 }}>
                  Date of Joining:
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography style={{ fontSize: '14px' }} align="start">
                  N/A
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              item
              md={11}
              xs={12}
              justify="space-between"
              style={{}}
            >
              <Grid item xs={5}>
                <Typography style={{ fontSize: '14px', fontWeight: 700 }}>
                  Date of Leaving:
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography style={{ fontSize: '14px' }} align="start">
                  N/A
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={4} container>
            <Grid container item xs={12} justify="space-between">
              <Grid item md={6} xs={5}>
                <Typography style={{ fontSize: '14px', fontWeight: 700 }}>
                  Project Name:
                </Typography>
              </Grid>
              <Grid item md={6} xs={7}>
                <Typography style={{ fontSize: '14px' }} align="start">
                  {selectedWorker?.projectName}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} justify="space-between" style={{}}>
              <Grid item md={6} xs={5}>
                <Typography style={{ fontSize: '14px', fontWeight: 700 }}>
                  Contractor Name:
                </Typography>
              </Grid>
              <Grid item md={6} xs={7}>
                <Typography style={{ fontSize: '14px' }} align="start">
                  N/A
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12} justify="space-between" style={{}}>
              <Grid item md={6} xs={5}>
                <Typography style={{ fontSize: '14px', fontWeight: 700 }}>
                  Supervisor:
                </Typography>
              </Grid>
              <Grid item md={6} xs={7}>
                <Typography style={{ fontSize: '14px' }} align="start">
                  N/A
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {approvingLoading || detailLoading ? (
          <div style={{ width: '100%', textAlign: 'center' }}>
            <CircularProgress style={{ color: 'RGB(132, 185, 57)' }} />
          </div>
        ) : (
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow style={{ backgroundColor: 'rgba(120,120,120,0.1)' }}>
                  {/* <TableCell
											align='center'
											// width='15%'  
											style={{ fontSize: 14, fontFamily: 'Roboto' }}>
											DAYS
										</TableCell> */}
                  <TableCell
                    align="center"
                    width="5%"
                    style={{ fontSize: 14, fontFamily: 'Roboto' }}
                  >
                    DATE
                  </TableCell>
                  <TableCell
                    align="center"
                    width="5%"
                    style={{ fontSize: 14, fontFamily: 'Roboto' }}
                  >
                    DAY
                  </TableCell>

                  <TableCell
                    align="center"
                    width="5%"
                    style={{ fontSize: 14, fontFamily: 'Roboto' }}
                  >
                    ATTENDANCE
                  </TableCell>

                  {/* <TableCell
											align='center'
											// width='15%'
											style={{ fontSize: 14, fontFamily: 'Roboto' }}>
											WORKING HOURS
										</TableCell> */}
                  {/* <TableCell
												align='center'
												width='5%'
												style={{ fontSize: 14, fontFamily: 'Roboto' }}>
												ABBREVIATION
											</TableCell> */}
                  <TableCell
                    align="center"
                    width="5%"
                    style={{ fontSize: 14, fontFamily: 'Roboto' }}
                  >
                    ADVANCE
                  </TableCell>
                  <TableCell
                    align="center"
                    width="5%"
                    style={{ fontSize: 14, fontFamily: 'Roboto' }}
                  >
                    VERIFIED
                  </TableCell>
                  <TableCell
                    align="center"
                    width="10%"
                    style={{ fontSize: 14, fontFamily: 'Roboto' }}
                  >
                    CHECK IN
                  </TableCell>
                  <TableCell
                    align="center"
                    width="10%"
                    style={{ fontSize: 14, fontFamily: 'Roboto' }}
                  >
                    CHECK OUT
                  </TableCell>
                  <TableCell
                    align="center"
                    width="10%"
                    style={{ fontSize: 14, fontFamily: 'Roboto' }}
                  >
                    VERIFIED BY BOOKKEPER
                  </TableCell>
                  <TableCell
                    align="center"
                    width="10%"
                    style={{ fontSize: 14, fontFamily: 'Roboto' }}
                  >
                    OVERTIME
                  </TableCell>
                  <TableCell
                    align="center"
                    width="10%"
                    style={{ fontSize: 14, fontFamily: 'Roboto' }}
                  >
                    ADVANCE RS
                  </TableCell>
                  <TableCell
                    align="center"
                    width="10%"
                    style={{ fontSize: 14, fontFamily: 'Roboto' }}
                  >
                    APPROVED BY ADMIN
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ orveflowX: 'scroll' }}>
                {workersAttendanceList?.attendance?.length > 0
                  ? workersAttendanceList?.attendance
                      ?.filter((item) =>
                        moment(item.date, 'M/D/YYYY').isSameOrBefore(
                          moment(),
                          'day'
                        )
                      )
                      ?.map((val, index) => (
                        <TableRow hover key={val.id}>
                          {/* <TableCell
														align='center'
														style={{ fontSize: 12, fontFamily: 'Roboto' }}>
														{val.day}
													</TableCell> */}
                          <TableCell
                            align="center"
                            style={{ fontSize: 12, fontFamily: 'Roboto' }}
                          >
                            {val.date ? moment(val.date).format('DD MMM') : '-'}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: 12, fontFamily: 'Roboto' }}
                          >
                            {val.day}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: 12, fontFamily: 'Roboto' }}
                          >
                            <Grid
                              container
                              alignItems="center"
                              justify="center"
                            >
                              {' '}
                              <p
                                style={{
                                  color:
                                    val.hoursAbbreviation === 'A'
                                      ? 'red'
                                      : val.hoursAbbreviation === '-'
                                      ? 'RGB(116, 126, 129)'
                                      : 'RGB(132, 185, 57)',
                                  marginRight: '10px',
                                  fontWeight: 700,
                                }}
                              >
                                {val.hoursAbbreviation}
                              </p>
                              {val.hoursAbbreviation === 'A' ? (
                                <div className="circle-red"></div>
                              ) : val.hoursAbbreviation === '-' ? (
                                ''
                              ) : (
                                <div className="circle-green"></div>
                              )}
                            </Grid>
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{ fontSize: 12, fontFamily: 'Roboto' }}
                          >
                            {val.advance === 0 ? '-' : val.advance}
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{ fontSize: 12, fontFamily: 'Roboto' }}
                          >
                            {val.hoursAbbreviation === 'A' ||
                            val.hoursAbbreviation === '1/2 P' ||
                            (val.hoursAbbreviation === 'P' &&
                              (!val.checkOut || val.checkOut === '')) ? (
                              <Grid
                                container
                                alignItems="center"
                                justify="center"
                              >
                                {' '}
                                <p
                                  style={{
                                    color: 'red',
                                    marginRight: '10px',
                                  }}
                                >
                                  <CloseIcon />
                                </p>
                              </Grid>
                            ) : val.hoursAbbreviation === '-' ? (
                              '-'
                            ) : (
                              <Grid
                                container
                                alignItems="center"
                                justify="center"
                              >
                                <p
                                  style={{
                                    color: 'RGB(132, 185, 57)',
                                    marginRight: '10px',
                                  }}
                                >
                                  <DoneIcon />
                                </p>
                              </Grid>
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: 12, fontFamily: 'Roboto' }}
                          >
                            {val.checkIn
                              ? moment(val.checkIn)
                                  .add(330, 'minutes')
                                  .format('LLL')
                              : '-'}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: 12, fontFamily: 'Roboto' }}
                          >
                            {val.checkOut
                              ? moment(val.checkOut)
                                  .add(330, 'minutes')
                                  .format('LLL')
                              : '-'}
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{ fontSize: 12, fontFamily: 'Roboto' }}
                          >
                            N/A
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: 12, fontFamily: 'Roboto' }}
                          >
                            N/A
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: 12, fontFamily: 'Roboto' }}
                          >
                            N/A
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: 12, fontFamily: 'Roboto' }}
                          >
                            N/A
                          </TableCell>
                        </TableRow>
                      ))
                  : workersAttendanceList?.attendance?.length == 0 && (
                      <TableRow hover>No Data Found!</TableRow>
                    )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Dialog>

      <Grid
        container
        item
        xs={12}
        justify="space-between"
        alignItems="center"
        style={{ marginTop: '1em', marginBottom: '1em' }}
      >
        <Grid item xs={4} container alignItems="center">
          <IconButton
            onClick={() => {
              dispatch(showPayment(false));
              // let timer = setTimeout(() => {
              // }, 100);
              // clearTimeout(timer);
              dispatch(putNullWorkerAttendance());

              history(-1);
              dispatch(selectWorker(null));
            }}
          >
            {/* <Link to='/app/attendance' style={{ color: 'black' }}> */}{' '}
            <ArrowBackIcon color="black" fontSize="default" />
            {/* </Link> */}
          </IconButton>

          <Typography
            variant="h5"
            style={{ fontSize: 16, fontFamily: 'Roboto' }}
          >
            {/* {workersAttendanceList: {attendance}?.length
							? workersAttendanceList: {attendance}[0]?.workerName
							: '-'} */}
            Muster Roll
          </Typography>
        </Grid>
      </Grid>
      <Grid item container style={{ marginLeft: '10px', marginBottom: '1rem' }}>
        <Grid item container alignItems="center">
          <Grid item style={{ marginRight: '15px', marginBottom: '10px' }}>
            {!detailLoading ? (
              <Avatar
                variant="square"
                className={classes.square}
                src={
                  workersAttendanceList?.profilePicture
                    ? `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${workersAttendanceList?.profilePicture}`
                    : User
                }
              />
            ) : (
              <div
                style={{
                  width: '120px',
                  height: '120px',
                  // textAlign: 'center',
                  display: 'flex',
                  justify: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress style={{ color: 'RGB(132, 185, 57)' }} />
              </div>
            )}
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              <Typography
                variant="subtitle1"
                style={{
                  fontFamily: 'Roboto',
                  fontWeight: 600,
                  fontSize: '1.2rem',
                }}
              >
                {selectedWorker?.workerName ?? '-'}
              </Typography>
              {selectedWorker?.isChecked && (
                <Checkbox
                  checked={true}
                  style={{ color: 'RGBA(33, 180, 64, 1)' }}
                />
              )}
            </Grid>
            <Typography style={{ fontFamily: 'Roboto', fontWeight: 500 }}>
              {selectedWorker?.sKillName ?? '-'}
            </Typography>

            <Typography
              style={{
                fontFamily: 'Roboto',
                fontSize: '10px',
                fontWeight: 500,
              }}
            >
              Start Date:{' '}
              {!selectedWorker?.startDate || selectedWorker?.startDate === ''
                ? '-'
                : moment(selectedWorker?.startDate).format('LL')}
            </Typography>
            <Typography
              style={{
                fontFamily: 'Roboto',
                fontSize: '10px',
                fontWeight: 500,
              }}
            >
              End Date:{' '}
              {!selectedWorker?.endDate || selectedWorker?.endDate === ''
                ? '-'
                : moment(selectedWorker?.endDate).format('LL')}
            </Typography>
            <Typography
              style={{
                fontFamily: 'Roboto',
                fontSize: '10px',
                fontWeight: 500,
              }}
            >
              Reporting Time:{' '}
              {selectedWorker?.jobReportingTime === null ||
              selectedWorker?.jobReportingTime === ''
                ? 'N/A'
                : selectedWorker?.jobReportingTime}
            </Typography>
            <Typography
              style={{
                fontFamily: 'Roboto',
                fontSize: '10px',
                fontWeight: 500,
              }}
            >
              Wage: {selectedWorker?.dailyWage ?? '-'}
            </Typography>
            <Typography
              style={{
                fontFamily: 'Roboto',
                fontSize: '10px',
                fontWeight: 500,
              }}
            >
              Job ID: {selectedWorker?.jobId ?? 'N/A'}
            </Typography>

            <Typography
              style={{
                fontFamily: 'Roboto',
                fontSize: '10px',
                fontWeight: 500,
              }}
            >
              Total Present: {selectedWorker?.jobId ?? 'N/A'}
            </Typography>

            <Typography
              style={{
                fontFamily: 'Roboto',
                fontSize: '10px',
                fontWeight: 500,
              }}
            >
              Total Absent: {selectedWorker?.jobId ?? 'N/A'}
            </Typography>

            <Typography
              style={{
                fontFamily: 'Roboto',
                fontSize: '10px',
                fontWeight: 500,
              }}
            >
              Total Overtime: {selectedWorker?.jobId ?? 'N/A'}
            </Typography>

            <Grid
              item
              xs={12}
              container
              justify="flex-start"
              // style={{ border: "2px solid red" }}
            >
              <Link to="/app/workerprofile/detail">
                <small onClick={findWorker}>View Profile</small>
              </Link>
            </Grid>
            {/* <Typography
							variant='p'
							style={{ fontFamily: 'Roboto', fontWeight: 500 }}>
							Month Pay -{' '}
							<span style={{ color: 'RGB(165, 118, 239)' }}>
								₹ {selectedWorker?.issuedAmount ?? '-'}
							</span>
						</Typography> */}
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <Grid item xs={6} container>
            <Grid item style={{ marginRight: '15px' }}>
              <Avatar
                variant="square"
                className={classes.squareProject}
                src={
                  workersAttendanceList?.projectImage
                    ? `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${workersAttendanceList?.projectImage}`
                    : User1
                }
              ></Avatar>
            </Grid>
            <Grid item>
              <Typography
                varint="subtitle1"
                style={{
                  fontSize: '1rem',
                  fontFamily: 'Roboto',
                  fontWeight: 600,
                }}
              >
                {selectedWorker?.projectName ?? '-'}
              </Typography>
              <Typography
                varint="subtitle1"
                style={{
                  fontSize: '1rem',
                  fontFamily: 'Roboto',
                  // fontWeight: 600,
                  color: 'RGB(180, 180, 180 )',
                  whiteSpace: 'nowrap',
                  width: '150px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {workersAttendanceList?.cityName ?? '-'}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            container
            justify="flex-end"
            alignItems="center"
            xs={6}
            style={{ paddingRight: '1.5rem' }}
          >
            <Button
              className={classes.buttonPurple}
              onClick={() => setReportModal(true)}
            >
              View Report
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={classes.overflow}>
        {detailError ? (
          <p style={{ textAlign: 'center' }}>{detailError}</p>
        ) : (
          <Grid container xs={12} justify="center">
            <Grid
              item
              xs={12}
              container
              justify="space-between"
              alignItems="center"
              style={{
                marginBottom: '15px',
                marginRight: '1.5rem',
                marginLeft: '15px',
              }}
            >
              <Grid item md={6} container alignItems="center">
                <Grid item>
                  {' '}
                  <span style={{ marginRight: '10px' }}>Filter: </span>
                </Grid>
                <Grid
                  item
                  style={{
                    position: 'relative',
                    width: '280px',
                  }}
                >
                  <DatePicker
                    dateFormat="MMMM d, yyyy"
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                      handleDateRange(update);
                    }}
                    isClearable={true}
                    className="font12 date-picker "
                  />
                  {!startDate && (
                    <EventNoteIcon
                      style={{ position: 'absolute', right: '5px', top: '7px' }}
                    />
                  )}
                </Grid>
              </Grid>

              {redirectFromPayment && (
                <Grid
                  item
                  container
                  md={6}
                  xs={12}
                  alignItems="center"
                  justify="flex-end"
                  className={classes.marginTop}
                >
                  <Grid item style={{ marginRight: '10px' }}>
                    <Button
                      size="small"
                      fullWidth
                      style={{
                        textTransform: 'capitalize',
                        backgroundColor: 'RGB(132, 185, 57)',
                        color: '#fff',
                        borderRadius: '10px',
                      }}
                      variant="outlined"
                      onClick={() => openAdvancePaymentModal('Online')}
                    >
                      Pay Advance Online
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      size="small"
                      fullWidth
                      style={{
                        textTransform: 'capitalize',
                        backgroundColor: 'RGB(132, 185, 57)',
                        color: '#fff',
                        borderRadius: '10px',
                      }}
                      variant="outlined"
                      onClick={() => openAdvancePaymentModal('Offline')}
                    >
                      Pay Advance Offline
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
            {approvingLoading || detailLoading ? (
              <div style={{ width: '100%', textAlign: 'center' }}>
                <CircularProgress style={{ color: 'RGB(132, 185, 57)' }} />
              </div>
            ) : (
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow
                      style={{ backgroundColor: 'rgba(120,120,120,0.1)' }}
                    >
                      {/* <TableCell
											align='center'
											// width='15%'  
											style={{ fontSize: 14, fontFamily: 'Roboto' }}>
											DAYS
										</TableCell> */}
                      <TableCell
                        align="center"
                        width="5%"
                        style={{ fontSize: 14, fontFamily: 'Roboto' }}
                      >
                        DATE
                      </TableCell>

                      <TableCell
                        align="center"
                        width="5%"
                        style={{ fontSize: 14, fontFamily: 'Roboto' }}
                      >
                        ATTENDANCE
                      </TableCell>

                      {/* <TableCell
											align='center'
											// width='15%'
											style={{ fontSize: 14, fontFamily: 'Roboto' }}>
											WORKING HOURS
										</TableCell> */}
                      {/* <TableCell
												align='center'
												width='5%'
												style={{ fontSize: 14, fontFamily: 'Roboto' }}>
												ABBREVIATION
											</TableCell> */}
                      <TableCell
                        align="center"
                        width="5%"
                        style={{ fontSize: 14, fontFamily: 'Roboto' }}
                      >
                        ADVANCE
                      </TableCell>
                      <TableCell
                        align="center"
                        width="5%"
                        style={{ fontSize: 14, fontFamily: 'Roboto' }}
                      >
                        VERIFIED
                      </TableCell>
                      <TableCell
                        align="center"
                        width="10%"
                        style={{ fontSize: 14, fontFamily: 'Roboto' }}
                      >
                        CHECK IN
                      </TableCell>
                      <TableCell
                        align="center"
                        width="10%"
                        style={{ fontSize: 14, fontFamily: 'Roboto' }}
                      >
                        CHECK OUT
                      </TableCell>
                      <TableCell
                        align="center"
                        width="10%"
                        style={{
                          fontSize: 14,
                          fontFamily: 'Roboto',
                        }}
                      >
                        ACTION
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ orveflowX: 'scroll' }}>
                    {
                      //     tempData?.length > 0
                      // ? tempData
                      //     ?.filter((item) =>
                      //       moment(item.date, "M/D/YYYY").isSameOrBefore(
                      //         moment(),
                      //         "day"
                      //       )
                      //     )
                      workersAttendanceList?.attendance.length
                        ? workersAttendanceList?.attendance?.map(
                            (val, index) => (
                              <TableRow hover key={val.id}>
                                {/* <TableCell
														align='center'
														style={{ fontSize: 12, fontFamily: 'Roboto' }}>
														{val.day}
													</TableCell> */}
                                <TableCell
                                  align="center"
                                  style={{ fontSize: 12, fontFamily: 'Roboto' }}
                                >
                                  {val.date
                                    ? moment(val.date).format('DD MMM')
                                    : '-'}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontSize: 12, fontFamily: 'Roboto' }}
                                >
                                  <Grid
                                    container
                                    alignItems="center"
                                    justify="center"
                                  >
                                    {' '}
                                    <p
                                      style={{
                                        color:
                                          val.hoursAbbreviation === 'A'
                                            ? 'red'
                                            : val.hoursAbbreviation === '-'
                                            ? 'RGB(116, 126, 129)'
                                            : 'RGB(132, 185, 57)',
                                        marginRight: '10px',
                                        fontWeight: 700,
                                      }}
                                    >
                                      {val.hoursAbbreviation}
                                    </p>
                                    {val.hoursAbbreviation === 'A' ? (
                                      <div className="circle-red"></div>
                                    ) : val.hoursAbbreviation === '-' ? (
                                      ''
                                    ) : (
                                      <div className="circle-green"></div>
                                    )}
                                  </Grid>
                                </TableCell>

                                <TableCell
                                  align="center"
                                  style={{ fontSize: 12, fontFamily: 'Roboto' }}
                                >
                                  {val.advance === 0 ? '-' : val.advance}
                                </TableCell>

                                <TableCell
                                  align="center"
                                  style={{ fontSize: 12, fontFamily: 'Roboto' }}
                                >
                                  {val.hoursAbbreviation === 'A' ||
                                  val.hoursAbbreviation === '1/2 P' ||
                                  (val.hoursAbbreviation === 'P' &&
                                    (!val.checkOut || val.checkOut === '')) ? (
                                    <Grid
                                      container
                                      alignItems="center"
                                      justify="center"
                                    >
                                      {' '}
                                      <p
                                        style={{
                                          color: 'red',
                                          marginRight: '10px',
                                        }}
                                      >
                                        <CloseIcon />
                                      </p>
                                    </Grid>
                                  ) : val.hoursAbbreviation === '-' ? (
                                    '-'
                                  ) : (
                                    <Grid
                                      container
                                      alignItems="center"
                                      justify="center"
                                    >
                                      <p
                                        style={{
                                          color: 'RGB(132, 185, 57)',
                                          marginRight: '10px',
                                        }}
                                      >
                                        <DoneIcon />
                                      </p>
                                    </Grid>
                                  )}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontSize: 12, fontFamily: 'Roboto' }}
                                >
                                  {val.checkIn
                                    ? moment(val.checkIn)
                                        .add(330, 'minutes')
                                        .format('LLL')
                                    : '-'}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontSize: 12, fontFamily: 'Roboto' }}
                                >
                                  {val.checkOut
                                    ? moment(val.checkOut)
                                        .add(330, 'minutes')
                                        .format('LLL')
                                    : '-'}
                                </TableCell>

                                <TableCell
                                  align="center"
                                  style={{ fontSize: 12, fontFamily: 'Roboto' }}
                                >
                                  <Grid
                                    container
                                    xs={12}
                                    // justify='space-between'
                                    direction="column"
                                    alignItems="center"
                                  >
                                    {moment(val.date).isAfter(moment()) ? (
                                      '-'
                                    ) : !val.checkIn ||
                                      !val.checkOut ||
                                      !val.isApproved ? (
                                      <Grid
                                        container
                                        xs={12}
                                        alignItems="center"
                                        justify="center"
                                      >
                                        <Grid item>
                                          <Autocomplete
                                            size="small"
                                            // value={value}
                                            //Only active user and level1 can veridf
                                            disabled={
                                              !Accesses['MUSTERROLL_MANAGEMENT']
                                                .hasAccess.LEVEL1 &&
                                              !Accesses['MUSTERROLL_MANAGEMENT']
                                                .isEditMode
                                            }
                                            onChange={(event, newValue) => {
                                              // if (newValue === null) {
                                              //   return setAssignAttendance(null);
                                              // }
                                              // setAssignAttendance({
                                              //   jobId: selectedWorker?.jobId,
                                              //   workerId:
                                              //     selectedWorker?.workerId,
                                              //   value: newValue,
                                              // });
                                              // handleAttendanceApprove(
                                              //   {
                                              //     jobId: selectedWorker?.jobId,
                                              //     workerId:
                                              //       selectedWorker?.workerId,
                                              //     value: newValue,
                                              //   },
                                              //   val.date
                                              // );
                                              handleAttendanceApprove({
                                                jobId: selectedWorker?.jobId,
                                                workerId:
                                                  selectedWorker?.workerId,
                                                date: moment
                                                  .utc(val.date)
                                                  .format(),
                                                value: newValue,
                                              });
                                            }}
                                            // inputValue={inputValue}
                                            // onInputChange={(event, newInputValue) => {
                                            // 	setInputValue(newInputValue);
                                            // }}
                                            getOptionLabel={(option) =>
                                              option.name
                                            }
                                            options={[
                                              { name: 'P', hours: 8 },
                                              { name: '1/2 P', hours: 4 },
                                              // { name: 'A', hours: 0 },
                                              { name: 'P1', hours: 9 },
                                              { name: 'P2', hours: 10 },
                                              { name: 'P3', hours: 11 },
                                              { name: 'P4', hours: 12 },
                                              { name: 'P5', hours: 13 },
                                              { name: 'P6', hours: 14 },
                                              { name: 'P7', hours: 15 },
                                              { name: 'PP', hours: 16 },
                                            ]}
                                            style={{ width: 230 }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label="Approve Status"
                                                variant="outlined"
                                                className={classes.projectInput}
                                              />
                                            )}
                                          />
                                        </Grid>
                                      </Grid>
                                    ) : (
                                      <Typography
                                        style={{
                                          width: '100%',
                                          color: '#99c45dff',
                                        }}
                                        align="center"
                                      >
                                        Approved
                                      </Typography>
                                    )}
                                  </Grid>
                                </TableCell>
                              </TableRow>
                            )
                          )
                        : workersAttendanceList?.attendance?.length === 0 && (
                            <TableRow hover>No Data Found!</TableRow>
                          )
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );

  return <>{renderViewCardDetails()}</>;
};
export default AttendanceDetail;
