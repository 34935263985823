import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../services/config';

const initialState = {
  loading: false,
  error: null,
  scopeList: [],
  scopeListDetail: [],
  unitList: [],
  boqList: [],
  boqListGC: [],
  boqListGCViewMode: [],
  BOQTitles: [],
  boqProgress: null,
  boqProgressLoading: false,
  metrics: null,
  projectProgressData: null,
  projectProgressDataLoading: false,
  projectBudgetLoading: false,
  projectBudgetData: null,
  financialGraphData: null,
  financialGraphLoading: null,
  bulkUploadLoading: false,
  bulkUploadProgressLoading: false,
  productivitySelectedProject: null,
  BOQTitlesLoading: false,
  shortCloseCostCodeLoading: false,
  boqListV1: [],
  boqListV1Loading: false,
  changeRequestLoading: false,
};

const slice = createSlice({
  name: 'productivity',
  initialState,
  reducers: {
    gettingScopeList(state, action) {
      state.loading = true;
      state.error = null;
    },
    gettingScopeListSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.scopeList = action.payload;
    },
    gettingScopeListFailure(state, action) {
      state.loading = false;
      state.error = null;
    },
    gettingUnitList(state, action) {
      state.loading = true;
      state.error = null;
    },
    gettingUnitListSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.unitList = action.payload;
    },
    gettingUnitListFailure(state, action) {
      state.loading = false;
      state.error = null;
    },

    gettingScopeListDetail(state, action) {
      state.loading = true;
      state.error = null;
    },
    gettingScopeListDetailSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.scopeListDetail = action.payload;
    },
    gettingScopeListDetailFailure(state, action) {
      state.loading = false;
      state.error = null;
    },

    addingBOQ(state, action) {
      state.loading = true;
      state.error = null;
    },
    addingBOQSuccess(state, action) {
      state.loading = false;
      state.error = null;
    },
    addingBOQFailure(state, action) {
      state.loading = false;
      state.error = null;
    },

    gettingBoqList(state, action) {
      state.loading = true;
      state.error = null;
    },
    gettingBoqListSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.boqList = action.payload;
    },
    gettingBoqListFailure(state, action) {
      state.loading = false;
      state.error = null;
    },
    gettingBoqListGC(state, action) {
      state.loading = true;
      state.error = null;
    },
    gettingBoqListGCSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.boqListGC = action.payload?.map((item) => ({
        ...item,
        scopeOfWorkName: state.scopeList.filter(
          (scope) => scope.scopeOfWorkId === item.scopeOfWorkId
        )?.[0]?.name,
      }));
    },
    gettingBoqListGCFailure(state, action) {
      state.loading = false;
      state.error = null;
    },
    gettingBoqListGCViewMode(state, action) {
      state.loading = true;
      state.error = null;
    },
    gettingBoqListGCSuccessViewMode(state, action) {
      state.loading = false;
      state.error = null;
      state.boqListGCViewMode = action.payload?.map((item) => ({
        ...item,
        scopeOfWorkName: state.scopeList.filter(
          (scope) => scope.scopeOfWorkId === item.scopeOfWorkId
        )?.[0]?.name,
      }));
    },
    gettingBoqListGCFailureViewMode(state, action) {
      state.loading = false;
      state.error = null;
    },
    addingProgress(state, action) {
      // state.loading = true;
      state.error = null;
    },
    addingProgressSuccess(state, action) {
      state.loading = false;
      state.error = null;
    },
    addingProgressFailure(state, action) {
      state.loading = false;
      state.error = null;
    },
    updatingChangeRequestQuantity(state, action) {
      // state.loading = true;
      state.error = null;
    },
    updatingChangeRequestQuantitySuccess(state, action) {
      state.loading = false;
      state.error = null;
    },
    updatingChangeRequestQuantityFailure(state, action) {
      state.loading = false;
      state.error = null;
    },
    addingBoq(state, action) {
      // state.loading = true;
      state.error = null;
    },
    addingBoqSuccess(state, action) {
      state.loading = false;
      state.error = null;
    },
    addingBoqFailure(state, action) {
      state.loading = false;
      state.error = null;
    },

    gettingBoqProgressGraph(state, action) {
      state.boqProgressLoading = true;
      state.error = null;
    },

    gettingBoqProgressGraphSuccess(state, action) {
      state.boqProgressLoading = false;
      state.error = null;
      state.boqProgress = action.payload;
    },
    gettingBoqProgressGraphFailure(state, action) {
      state.boqProgressLoading = false;
      state.error = action.payload;
      state.boqProgress = null;
    },

    gettingBoqMetrics(state, action) {
      state.boqProgressLoading = true;
      state.error = null;
    },

    gettingBoqMetricsSuccess(state, action) {
      state.boqProgressLoading = false;
      state.error = null;
      state.metrics = action.payload;
    },
    gettingBoqMetricsFailure(state, action) {
      state.boqProgressLoading = false;
      state.error = action.payload;
      state.metrics = null;
    },
    gettingBoqProgressBySOW(state, action) {
      state.projectProgressDataLoading = true;
      state.error = null;
    },
    gettingBoqProgressBySOWSuccess(state, action) {
      state.projectProgressDataLoading = false;
      state.error = null;
      state.projectProgressData = action.payload;
    },
    gettingBoqProgressBySOWFailure(state, action) {
      state.projectProgressDataLoading = false;
      state.error = action.payload;
      state.projectProgressData = null;
    },
    gettingBoqTitles(state, action) {
      state.BOQTitlesLoading = true;
      state.error = null;
    },
    gettingBoqTitlesSuccess(state, action) {
      state.BOQTitlesLoading = false;
      state.error = null;
      state.BOQTitles = action.payload;
    },
    gettingBoqTitlesFailure(state, action) {
      state.BOQTitlesLoading = false;
      state.error = action.payload;
      state.BOQTitles = [];
    },
    gettingBoqProgressBudget(state, action) {
      state.projectBudgetLoading = true;
      state.error = null;
    },

    gettingBoqProgressBudgetSuccess(state, action) {
      state.projectBudgetLoading = false;
      state.error = null;
      state.projectBudgetData = action.payload;
    },
    gettingBoqProgressBudgetFailure(state, action) {
      state.projectBudgetLoading = false;
      state.error = action.payload;
      state.projectBudgetData = null;
    },
    gettingFinancialGraphData(state, action) {
      state.financialGraphLoading = true;
      state.error = null;
    },

    gettingFinancialGraphDataSuccess(state, action) {
      state.financialGraphLoading = false;
      state.error = null;
      state.financialGraphData = action.payload;
    },
    gettingFinancialGraphDataFailure(state, action) {
      state.financialGraphLoading = false;
      state.error = action.payload;
      state.financialGraphData = null;
    },
    sendingBulkBOQ(state, action) {
      state.bulkUploadLoading = true;
      state.error = null;
    },
    sendingBulkBOQSuccess(state, action) {
      state.bulkUploadLoading = false;
      state.error = null;
    },
    sendingBulkBOQFailure(state, action) {
      state.bulkUploadLoading = false;
      state.error = action.payload;
    },
    shortClosingBoq(state, action) {
      state.shortCloseLoading = true;
      state.error = null;
    },
    shortCloseBoqSuccess(state, action) {
      state.shortCloseLoading = false;
      state.error = null;
    },
    shortCloseBoqFailure(state, action) {
      state.shortCloseLoading = false;
      state.error = action.payload;
    },
    gettingBoqListV1(state, action) {
      state.boqListV1Loading = true;
      state.boqListV1 = [];
      state.error = null;
    },
    gettingBoqListV1Success(state, action) {
      state.boqListV1Loading = false;
      state.boqListV1 = action.payload;
      state.error = null;
    },
    gettingBoqListV1Failure(state, action) {
      state.boqListV1Loading = false;
      state.boqListV1 = [];
      state.error = action.payload;
    },
    sendingBulkProgress(state, action) {
      // state.bulkUploadProgressLoading = true;
      state.error = null;
    },
    sendingBulkProgressSuccess(state, action) {
      state.bulkUploadProgressLoading = false;
      state.error = null;
    },
    sendingBulkProgressFailure(state, action) {
      state.bulkUploadProgressLoading = false;
      state.error = action.payload;
    },
    settingProductivitySelectedProject(state, action) {
      state.productivitySelectedProject = action.payload;
    },
    closingCostCode(state, action) {
      state.shortCloseCostCodeLoading = true;
      state.error = null;
    },
    closingCostCodeSuccess(state, action) {
      state.shortCloseCostCodeLoading = false;
    },
    closingCostCodeFailure(state, action) {
      state.shortCloseCostCodeLoading = false;
      state.error = action.payload;
    },
    approvingChangeRequest(state, action) {
      state.changeRequestLoading = true;
      state.error = null;
    },
    approvingChangeRequestSuccess(state, action) {
      state.changeRequestLoading = false;
    },
    approvingChangeRequestFailure(state, action) {
      state.changeRequestLoading = false;
      state.error = action.payload;
    },
    rejectingChangeRequest(state, action) {
      state.changeRequestLoading = true;
      state.error = null;
    },
    rejectingChangeRequestSuccess(state, action) {
      state.changeRequestLoading = false;
    },
    rejectingChangeRequestFailure(state, action) {
      state.changeRequestLoading = false;
      state.error = action.payload;
    },
  },
});
const {
  gettingScopeList,
  gettingScopeListSuccess,
  gettingScopeListFailure,

  gettingUnitList,
  gettingUnitListSuccess,
  gettingUnitListFailure,

  addingBOQ,
  addingBOQSuccess,
  addingBOQFailure,

  gettingScopeListDetail,
  gettingScopeListDetailSuccess,
  gettingScopeListDetailFailure,

  gettingBoqList,
  gettingBoqListSuccess,
  gettingBoqListFailure,

  gettingBoqListGC,
  gettingBoqListGCSuccess,
  gettingBoqListGCFailure,

  gettingBoqListGCViewMode,
  gettingBoqListGCSuccessViewMode,
  gettingBoqListGCFailureViewMode,

  addingProgress,
  addingProgressSuccess,
  addingProgressFailure,

  addingBoq,
  addingBoqSuccess,
  addingBoqFailure,

  gettingBoqProgressGraph,
  gettingBoqProgressGraphSuccess,
  gettingBoqProgressGraphFailure,

  gettingBoqMetrics,
  gettingBoqMetricsSuccess,
  gettingBoqMetricsFailure,

  gettingBoqProgressBySOW,
  gettingBoqProgressBySOWSuccess,
  gettingBoqProgressBySOWFailure,

  gettingBoqTitles,
  gettingBoqTitlesSuccess,
  gettingBoqTitlesFailure,

  gettingBoqProgressBudget,
  gettingBoqProgressBudgetSuccess,
  gettingBoqProgressBudgetFailure,

  gettingFinancialGraphData,
  gettingFinancialGraphDataSuccess,
  gettingFinancialGraphDataFailure,

  sendingBulkBOQ,
  sendingBulkBOQSuccess,
  sendingBulkBOQFailure,

  sendingBulkProgress,
  sendingBulkProgressSuccess,
  sendingBulkProgressFailure,

  settingProductivitySelectedProject,

  closingCostCode,
  closingCostCodeSuccess,
  closingCostCodeFailure,

  shortClosingBoq,
  shortCloseBoqSuccess,
  shortCloseBoqFailure,

  gettingBoqListV1,
  gettingBoqListV1Success,
  gettingBoqListV1Failure,

  approvingChangeRequest,
  approvingChangeRequestSuccess,
  approvingChangeRequestFailure,

  rejectingChangeRequest,
  rejectingChangeRequestSuccess,
  rejectingChangeRequestFailure,

  updatingChangeRequestQuantity,
  updatingChangeRequestQuantitySuccess,
  updatingChangeRequestQuantityFailure,
} = slice.actions;

export const productivityReducer = (state) => state.productivity;

export const getScopeList = () => async (dispatch) => {
  dispatch(gettingScopeList());
  // if (projectId) {
  const response = await axios.get(
    `${config.baseUrl}/dashboard/Productivity/getscopelist`
  );
  if (response?.status === 200) {
    dispatch(gettingScopeListSuccess(response.data));
  } else {
    dispatch(
      gettingScopeListFailure('Something went wrong while getting scope list!')
    );
  }
  return response;
};

export const getScopeListDetail = (scopeId) => async (dispatch) => {
  dispatch(gettingScopeListDetail());
  // if (projectId) {
  const response = await axios.get(
    `${config.baseUrl}/dashboard/Productivity/getscopedetaillist/${scopeId}`
  );
  if (response?.status === 200) {
    dispatch(gettingScopeListDetailSuccess(response.data));
  } else {
    dispatch(
      gettingScopeListDetailFailure(
        'Something went wrong while getting scope detail list!'
      )
    );
  }
  return response;
};

export const getUnitList = () => async (dispatch) => {
  dispatch(gettingUnitList());
  // if (projectId) {
  const response = await axios.get(`${config.baseUrl}/dashboard/Unit/getall`);
  if (response?.status === 200) {
    dispatch(gettingUnitListSuccess(response.data));
  } else {
    dispatch(
      gettingUnitListFailure('Something went wrong while getting unit list!')
    );
  }
  return response;
};

export const addBOQ = (listObject) => async (dispatch) => {
  dispatch(addingBOQ());
  // if (projectId) {
  const response = await axios.post(
    `${config.baseUrl}/dashboard/Productivity/addupdateboq`,
    listObject
  );
  if (response?.status === 200) {
    dispatch(addingBOQSuccess());
  } else {
    dispatch(addingBOQFailure('Something went wrong while sending BOQ list!'));
  }
  return response;
};

export const getBOQList =
  (projectId = 0, contractorId = 0, pageNumber = 1, pageSize = 20) =>
  async (dispatch) => {
    dispatch(gettingBoqList());
    // if (projectId) {
    const response = await axios.get(
      `${config.baseUrl}/dashboard/Productivity/getboqremainingprogresslist?projectId=${projectId}&pageNumber=${pageNumber}&pageSize=${pageSize}`
    );

    if (response?.status === 200) {
      const boqModified = response?.data?.data.map((item) => ({
        ...item,
        remarks: '',
      }));
      dispatch(
        gettingBoqListSuccess({
          data: boqModified,
          total: response?.data?.pageSize,
        })
      );
    } else {
      dispatch(
        gettingBoqListFailure('Something went wrong while getting BOQ list!')
      );
    }
    return response;
  };

export const getSOWListByBOQ =
  (projectId = 0, contractorId = 0, pageNumber = 1, pageSize = 50) =>
  async (dispatch) => {
    let url = `${config.baseUrl}/dashboard/Productivity/getsowlistbyboq?projectId=${projectId}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    dispatch(gettingBoqList());
    if (contractorId) {
      url += `&contractorId=${contractorId}`;
    }
    const response = await axios.get(url);

    if (response?.status === 200) {
      const boqModified = response?.data;
      dispatch(
        gettingBoqListSuccess({
          data: boqModified,
          total: response?.data?.pageSize,
        })
      );
    } else {
      dispatch(
        gettingBoqListFailure('Something went wrong while getting BOQ list!')
      );
    }
    return response;
  };

export const addProgress = (data) => async (dispatch) => {
  dispatch(addingProgress());
  const response = await axios.post(
    `${config.baseUrl}/dashboard/Productivity/addupdateboqprogress`,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  if (response?.status === 200) {
    dispatch(addingProgressSuccess());
  } else {
    dispatch(
      addingProgressFailure('Something went wrong while saving BOQ progress!')
    );
  }
  return response;
};

export const updateChangeRequestQuantity = (data) => async (dispatch) => {
  dispatch(updatingChangeRequestQuantity());
  const response = await axios.put(
    `${config.baseUrl}/dashboard/Productivity/update-change-request-quantity`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  if (response?.status === 200) {
    dispatch(updatingChangeRequestQuantitySuccess());
  } else {
    dispatch(
      updatingChangeRequestQuantityFailure(
        'Something went wrong while updating change request quantity!'
      )
    );
  }
  return response;
};

export const addUpdateBoq = (data) => async (dispatch) => {
  dispatch(addingBoq());
  const response = await axios.post(
    `${config.baseUrl}/dashboard/Productivity/addupdateboq`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  if (response?.status === 200) {
    dispatch(addingBoqSuccess());
  } else {
    dispatch(addingBoqFailure('Something went wrong while saving BOQ!'));
  }
  return response;
};

export const getBOQListGC =
  (
    projectId = 0,
    contractorId = 0,
    pageNumber = 1,
    pageSize = 1000,
    QualityStatusFilter = '',
    IsHistory = false,
    searchQuery = ''
  ) =>
  async (dispatch) => {
    dispatch(gettingBoqListGC());
    let url =
      QualityStatusFilter !== ''
        ? `${config.baseUrl}/dashboard/Productivity/getboqprogresslist/v2?projectId=${projectId}&contractorId=${contractorId}&pageNumber=${pageNumber}&pageSize=${pageSize}&QualityStatusFilter=${QualityStatusFilter}&search=${searchQuery}`
        : `${config.baseUrl}/dashboard/Productivity/getboqprogresslist/v2?projectId=${projectId}&contractorId=${contractorId}&pageNumber=${pageNumber}&pageSize=${pageSize}&QualityStatusFilter=${QualityStatusFilter}&IsHistory=${IsHistory}&search=${searchQuery}`;

    // if (projectId) {
    const response = await axios.get(url);
    if (response?.status === 200) {
      dispatch(
        gettingBoqListGCSuccess(
          response?.data.result?.sowList.map((item) => ({
            ...item,
            remark: '',
            checked: false,
          }))
        )
      );
    } else {
      dispatch(
        gettingBoqListGCFailure(
          'Something went wrong while getting BOQ GC list!'
        )
      );
    }
    return response;
  };

export const getBOQProgress =
  (projectId = 0, contractorId = 0) =>
  async (dispatch) => {
    dispatch(gettingBoqProgressGraph());
    const response = await axios.get(
      `${config.baseUrl}/dashboard/Productivity/graph/boqprogress?projectId=${projectId}&contractorId=${contractorId}`
    );
    if (response?.status === 200) {
      dispatch(gettingBoqProgressGraphSuccess(response?.data?.result));
    } else {
      dispatch(
        gettingBoqProgressGraphFailure(
          'Something went wrong while getting BOQ Progress!'
        )
      );
    }
    return response;
  };

export const getBOQMetrics =
  (projectId = 0) =>
  async (dispatch) => {
    dispatch(gettingBoqMetrics());
    const response = await axios.get(
      `${config.baseUrl}/dashboard/Productivity/graph/boqmetrics?projectId=${projectId}`
    );
    if (response?.status === 200) {
      dispatch(gettingBoqMetricsSuccess(response?.data.result));
    } else {
      dispatch(
        gettingBoqMetricsFailure(
          'Something went wrong while getting BOQ Progress!'
        )
      );
    }
    return response;
  };

export const getProjectProgressGraph =
  (projectId = 0, contractorId = 0) =>
  async (dispatch) => {
    dispatch(gettingBoqProgressBySOW());
    const response = await axios.get(
      `${config.baseUrl}/dashboard/Productivity/graph/boqprogressbysow?projectId=${projectId}&contractorId=${contractorId}`
    );
    if (response?.status === 200) {
      dispatch(gettingBoqProgressBySOWSuccess(response?.data.result));
    } else {
      dispatch(
        gettingBoqProgressBySOWFailure(
          'Something went wrong while getting BOQ Progress!'
        )
      );
    }
    return response;
  };

export const gettingBoqTitlesBySOW =
  (boqId = 0, sowID = 0, pageNumber = 1, pageSize = 1000) =>
  async (dispatch) => {
    dispatch(gettingBoqTitles());
    const response = await axios.get(
      `${config.baseUrl}/dashboard/Productivity/gettitlesbyBOQ?boqId=${boqId}&scopeOfWorkId=${sowID}&pageNumber=${pageNumber}&pageSize=${pageSize}`
    );
    if (response?.status === 200) {
      response.data.Result.BOQId = boqId;
      dispatch(gettingBoqTitlesSuccess(response?.data.Result));
    } else {
      dispatch(
        gettingBoqTitlesFailure(
          'Something went wrong while getting BOQ titles!'
        )
      );
    }
    return response;
  };

export const getProjectBudget =
  (projectId = 0) =>
  async (dispatch) => {
    dispatch(gettingBoqProgressBudget());
    const response = await axios.get(
      `${config.baseUrl}/dashboard/Productivity/graph/costdetails?projectId=${projectId}`
    );
    if (response?.status === 200) {
      dispatch(gettingBoqProgressBudgetSuccess(response?.data.result));
    } else {
      dispatch(
        gettingBoqProgressBudgetFailure(
          'Something went wrong while getting BOQ Progress!'
        )
      );
    }
    return response;
  };

export const getFinancialProgressData =
  (projectId = 0) =>
  async (dispatch) => {
    dispatch(gettingFinancialGraphData());
    const response = await axios.get(
      `${config.baseUrl}/dashboard/Productivity/graph/costbymonth?projectId=${projectId}`
    );
    if (response?.status === 200) {
      dispatch(gettingFinancialGraphDataSuccess(response?.data.result));
    } else {
      dispatch(
        gettingFinancialGraphDataFailure(
          'Something went wrong while getting BOQ Progress!'
        )
      );
    }
    return response;
  };

export const verifyBOQProgress = (data) => async (dispatch) => {
  dispatch(gettingFinancialGraphData());
  const response = await axios.put(
    `${config.baseUrl}/dashboard/Productivity/verifyprogress`,
    data
  );
  if (response?.status === 200) {
    dispatch(gettingFinancialGraphDataSuccess());
  } else {
    dispatch(
      gettingFinancialGraphDataFailure(
        'Something went wrong while getting BOQ Progress!'
      )
    );
  }
  return response;
};

export const rejectBOQProgress = (data) => async (dispatch) => {
  dispatch(gettingFinancialGraphData());
  const response = await axios.put(
    `${config.baseUrl}/dashboard/Productivity/rejectprogress`,
    data
  );
  if (response?.status === 200) {
    dispatch(gettingFinancialGraphDataSuccess());
  } else {
    dispatch(
      gettingFinancialGraphDataFailure(
        'Something went wrong while getting BOQ Progress!'
      )
    );
  }
  return response;
};

export const approveBOQMeasurementReason = (data) => async (dispatch) => {
  dispatch(gettingFinancialGraphData());
  const response = await axios.put(
    `${config.baseUrl}/dashboard/Productivity/approveprogressreason`,
    data
  );
  if (response?.status === 200) {
    dispatch(gettingFinancialGraphDataSuccess());
  } else {
    dispatch(
      gettingFinancialGraphDataFailure(
        'Something went wrong while getting BOQ Progress!'
      )
    );
  }
  return response;
};

export const getListOfBOQ =
  (
    projectId = 0,
    contractorId = 0,
    pageNumber = 1,
    pageSize = 50,
    sortBy = '',
    orderBy = ''
  ) =>
  async (dispatch) => {
    dispatch(gettingBoqListGCViewMode());
    // if (projectId) {
    const response = await axios.get(
      `${config.baseUrl}/dashboard/Productivity/getboqlist/v2?projectId=${projectId}&contractorId=${contractorId}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&orderBy=${orderBy}`
    );
    if (response?.status === 200) {
      dispatch(
        gettingBoqListGCSuccessViewMode(
          response?.data.map((item) => ({
            ...item,
            boQs: item.boQs.map((param) => ({
              ...param,
              titles: param.titles.map((param1) => ({
                ...param1,
                totalAmount: param1.descriptions.reduce(
                  (accumulator, currentValue) =>
                    accumulator + currentValue.amount,
                  0
                ),
                totalAcutalAmount: param1.descriptions.reduce(
                  (accumulator, currentValue) =>
                    accumulator + currentValue.actualAmount,
                  0
                ),
                descriptions: param1.descriptions.map((param2) => ({
                  ...param2,
                  percentage:
                    (param2.actualQuantity / param2.quantity) * 100 === Infinity
                      ? 0
                      : (param2.actualQuantity / param2.quantity) * 100,
                })),
              })),
            })),
          }))
        )
      );

      // ({ ...item, boQs: item.boQs.map(param => ({ ...param, titles: param.titles.map(param1 => param1.descriptions.map(param2 => ({(...param2.amount / param2.actualAmount) * 100 === Infinity ? 0))})})})))));
    } else {
      dispatch(
        gettingBoqListGCFailureViewMode(
          'Something went wrong while getting BOQ GC list!'
        )
      );
    }
    return response;
  };

export const shortCloseBoq = (data) => async (dispatch) => {
  dispatch(shortClosingBoq());
  const response = await axios.put(
    `${config.baseUrl}/dashboard/Productivity/short-close-boq`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  console.log(response);
  if (response?.status === 200) {
    dispatch(shortCloseBoqSuccess());
  } else {
    dispatch(
      shortCloseBoqFailure('Something went wrong while short close request')
    );
  }
  return response;
};
export const getBoqListV1 = (projectId) => async (dispatch) => {
  dispatch(gettingBoqListV1());
  const response = await axios.get(
    `${config.baseUrl}/dashboard/Productivity/getboqlist/${projectId}`
  );
  if (response?.status === 200) {
    dispatch(gettingBoqListV1Success(response.data.result));
  } else {
    dispatch(
      gettingBoqListV1Failure('Something went wrong while getting boq list')
    );
    return [];
  }
};

export const uploadBulkBOQ = (data) => async (dispatch) => {
  dispatch(sendingBulkBOQ());
  const response = await axios.post(
    `${config.baseUrl}/dashboard/Productivity/boqbulkupload`,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  if (response?.status === 200) {
    dispatch(sendingBulkBOQSuccess());
  } else {
    dispatch(
      sendingBulkBOQFailure('Something went wrong while sending BOQ bulk!')
    );
  }
  return response;
};

export const uploadBulkProgress = (data) => async (dispatch) => {
  dispatch(sendingBulkProgress());
  const response = await axios.post(
    `${config.baseUrl}/dashboard/Productivity/bulkuploadprogress`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  if (response?.status === 200) {
    dispatch(sendingBulkProgressSuccess());
  } else {
    dispatch(
      sendingBulkProgressFailure('Something went wrong while sending BOQ bulk!')
    );
  }
  return response;
};
export const selectProductivityParentProject =
  (project) => async (dispatch) => {
    dispatch(settingProductivitySelectedProject(project));
  };

export const closeCostCode = (data) => async (dispatch) => {
  dispatch(closingCostCode());
  const response = await axios.put(
    `${config.baseUrl}/dashboard/Productivity/short-close-cost-code
`,
    data
  );
  if (response?.status === 200) {
    dispatch(closingCostCodeSuccess());
  } else {
    dispatch(
      closingCostCodeFailure('Something went wrong while getting BOQ Progress!')
    );
  }
  return response;
};

export const approveChangeRequest = (data) => async (dispatch) => {
  dispatch(approvingChangeRequest());
  const response = await axios.put(
    `${config.baseUrl}/dashboard/Productivity/approve-change-request
`,
    data
  );
  if (response?.status === 200) {
    dispatch(approvingChangeRequestSuccess());
  } else {
    dispatch(
      approvingChangeRequestFailure(
        'Something went wrong while approving the change request!'
      )
    );
  }
  return response;
};

export const rejectChangeRequest = (data) => async (dispatch) => {
  dispatch(rejectingChangeRequest());
  const response = await axios.put(
    `${config.baseUrl}/dashboard/Productivity/reject-change-request
`,
    data
  );
  if (response?.status === 200) {
    dispatch(rejectingChangeRequestSuccess());
  } else {
    dispatch(
      rejectingChangeRequestFailure(
        'Something went wrong while rejecting change request!'
      )
    );
  }
  return response;
};

export default slice.reducer;
