import React from 'react';
import {
  Grid,
  makeStyles,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  Container,
} from '@material-ui/core';
import Page from 'src/components/Page';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useNavigate } from 'react-router-dom';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import EventNoteOutlinedIcon from '@material-ui/icons/EventNoteOutlined';
import temp from '../../assests/ImagePlaceHolder.png';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  disabledLink: {
    pointerEvents: 'none',
  },
  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    borderRadius: '10px',
    padding: '10px 1rem',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    color: '#986FEF',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    borderRadius: '10px',
    padding: '10px 1rem',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  headerCellText: {
    fontSize: 12,
    fontFamily: 'Roboto',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  textField: {
    // marginLeft: theme.spacing(1),
    paddingRight: '1.5rem',
    borderRadius: '10px',
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontWeight: 400,
  },
  input: {
    display: 'none',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  hover: {
    ':hover': {
      backgroundColor: '#A179F2',
    },
  },
  textFieldStyleHeading: {
    fontWeight: 400,
    fontFamily: 'Roboto',
    fontSize: '22px',
    color: '#fff',

    '&::placeholder': {
      fontWeight: 400,
      fontFamily: 'Roboto',
      fontSize: '15px',
      // color: 'rgba(126, 131, 134, 0.7)',
      // color: '#ddd',
    },
  },
  textFieldStyle1: {
    fontWeight: 400,
    fontFamily: 'Roboto',
    fontSize: '15px',
    color: '#fff',

    '&::placeholder': {
      fontWeight: 400,
      fontFamily: 'Roboto',
      fontSize: '15px',
      // color: 'rgba(126, 131, 134, 0.7)',
      // color: '#ddd',
    },
  },
  textFieldStyle: {
    fontWeight: 400,
    fontFamily: 'Roboto',
    fontSize: '15px',
    // color: '#fff',

    '&::placeholder': {
      fontWeight: 400,
      fontFamily: 'Roboto',
      // fontSize: '15px',
      // color: 'rgba(126, 131, 134, 0.7)',
      // color: '#fff',
    },
  },
  floatingLabelFocusStyle: {
    color: '#F9F9F9',
  },
  input1: {
    borderRadius: '10px',
    // backgroundColor: 'rgba(126, 131, 134, 0.1)',
    backgroundColor: 'transparent',
  },
  input: {
    borderRadius: '10px',
    backgroundColor: 'rgba(126, 131, 134, 0.1)',
    // backgroundColor: 'transparent',
  },
  profilePictureContainer: {
    paddingLeft: '1rem',
    paddingTop: '2rem',
    // position: 'absolute',
    // top: '50%',
  },
  greenCard: {
    backgroundColor: '#81B734',
    height: '270px',
    borderRadius: '11px',
    marginRight: '10px',
    // position: 'relative',
  },
  placeholderStyle: {
    '& .MuiFilledInput-root:hover': {
      backgroundColor: 'transparent',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
      '& .MuiFilledInput-root.Mui-focused': {
        backgroundColor: 'transparent',
      },
    },
  },
  hRow: {
    marginLeft: '25px',
    borderLeft: '1px solid rgba(200, 200, 200, 1)',
    padding: '10px 0',
    height: '40px',
  },
  skillAndAdharFieldContainer: {
    paddingLeft: '4rem',
    paddingTop: '2rem',
    // border: '2px solid red',
  },
  whiteFieldContainer: {
    paddingLeft: '1rem',
    paddingTop: '5rem',
  },
  headerCellText1: {
    color: 'rgba(173, 186, 195, 1)',
    fontSize: '12px',
  },
  [theme.breakpoints.down('sm')]: {
    greenCard: {
      backgroundColor: '#81B734',
      height: '550px',
      borderRadius: '11px',
      // marginRight: '10px',
      // position: 'relative',
    },
    hRow: {
      marginLeft: '15px',
      borderLeft: '1px solid #fff',
      padding: '10px 0',
      height: '30px',
    },
    skillAndAdharFieldContainer: {
      paddingLeft: '1rem',
      paddingTop: '1rem',
    },
    profilePictureContainer: {
      paddingLeft: '5.5rem',
      paddingTop: '1rem',
      // position: 'absolute',
      // top: '50%',
    },
    whiteFieldContainer: {
      paddingLeft: '0', //'5.5rem',
      paddingTop: '0 ', //'1rem',
    },
    adharCardFiledContainer: {
      paddingLeft: '5.5rem',
      paddingTop: '1rem',
    },
  },
}));

const ContractorDetailPage = () => {
  let classes = useStyles();
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Page className={classes.root} id="zoom-level" title="Contractor">
      <Container maxWidth={false}>
        <Grid
          container
          item
          xs={12}
          justifyContent="space-between"
          alignItems="center"
          style={{
            marginTop: '1em',
            marginBottom: '1em',
          }}
        >
          <Grid item xs={4} container alignItems="center">
            <IconButton>
              <Grid
                item
                // to='..'
                style={{ color: 'black' }}
                onClick={handleBack}
              >
                <ArrowBackIcon color="black" fontSize="default" />
              </Grid>
            </IconButton>
            <Typography variant="h5" style={{ fontFamily: 'Roboto' }}>
              {'Vinod Builders Pvt Ltd' ?? 'N/A'}
            </Typography>
          </Grid>
        </Grid>

        <Grid container xs={12} className={classes.greenCard}>
          <Grid
            item
            md={2}
            xs={12}
            // container
            // jusity='center'
            className={classes.profilePictureContainer}
          >
            <img
              //   src={`${
              //     process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX
              //   }${'obj.url'}`}
              src={temp}
              alt="Worker visual"
              style={{
                width: '250px',
                height: '230px',
                // height: "90px",
                borderRadius: '8px',
                textAlign: 'center',
                marginTop: '1rem',
              }}
            />
          </Grid>
          <Grid md={6} xs={12} className={classes.skillAndAdharFieldContainer}>
            <Grid item md={7} xs={8}>
              <TextField
                className={classes.placeholderStyle}
                label="FULL NAME"
                fullWidth
                variant="filled"
                value={'Vinod Builders Pvt Ltd' ?? 'N/A'}
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    input: `${classes.textFieldStyleHeading} `,
                    root: classes.input1,
                  },
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              container
              // justify='space-between'
              alignItems="center"
            >
              <Grid item md={5}>
                <TextField
                  className={classes.placeholderStyle}
                  label="EMAIL"
                  fullWidth
                  variant="filled"
                  // style={{ width: '715px' }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  value={'abc@gmail.com' ?? 'N/A'}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle1}`,
                      root: classes.input1,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={1} className={classes.hRow}></Grid>
              <Grid item md={5}>
                <TextField
                  className={classes.placeholderStyle}
                  label="PHONE"
                  fullWidth
                  variant="filled"
                  // style={{ width: '715px' }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  value={'+91 11 2419 8000' ?? 'N/A'}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle1}`,
                      root: classes.input1,
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          // flexDirection='column'
          justifyContent="flex-end"
          xs={12}
          className={classes.whiteFieldContainer}
        >
          <div className="whiteFieldContainer2nd">
            <Grid item container xs={12} style={{ marginBottom: '1rem' }}>
              <Grid item xs={6}>
                <TextField
                  label="FIRST NAME"
                  fullWidth
                  variant="filled"
                  style={{ paddingRight: '1rem' }}
                  // style={{ width: '715px' }}
                  value={'asd' ?? 'N/A'}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="LAST NAME"
                  fullWidth
                  variant="filled"
                  // style={{ width: '715px' }}
                  value={'asdsd' ?? 'N/A'}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                    endAdornment: (
                      <InputAdornment>
                        <EventNoteOutlinedIcon
                          style={{ color: 'rgba(168, 175, 183, 1)' }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                marginBottom: '1rem',
              }}
            >
              <TextField
                label="ACCOUNT TYPE"
                fullWidth
                variant="filled"
                // style={{ width: '715px' }}
                value={'TYPE OF ACCOUNT' ?? 'N/A'}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    input: `${classes.textFieldStyle}  `,
                    root: classes.input,
                  },
                  endAdornment: (
                    <InputAdornment>
                      <PhoneOutlinedIcon
                        style={{ color: 'rgba(168, 175, 183, 1)' }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                marginBottom: '1rem',
              }}
            >
              <TextField
                label="EMAIL ADDRESS "
                fullWidth
                variant="filled"
                // style={{ width: '715px' }}
                value={'ABC@GMAIL.COM' ?? 'N/A'}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    input: `${classes.textFieldStyle}  `,
                    root: classes.input,
                  },
                  endAdornment: (
                    <InputAdornment>
                      <PhoneOutlinedIcon
                        style={{ color: 'rgba(168, 175, 183, 1)' }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                marginBottom: '1rem',
              }}
            >
              <TextField
                label="PHONE"
                fullWidth
                variant="filled"
                // style={{ width: '715px' }}
                value={'+' + '91 11 2419 8000' ?? 'N/A'}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    input: `${classes.textFieldStyle}  `,
                    root: classes.input,
                  },
                  endAdornment: (
                    <InputAdornment>
                      <PhoneOutlinedIcon
                        style={{ color: 'rgba(168, 175, 183, 1)' }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item container xs={12} style={{ marginBottom: '1rem' }}>
              <Grid item xs={6}>
                <TextField
                  label="PLAN TYPE"
                  fullWidth
                  variant="filled"
                  style={{ paddingRight: '1rem' }}
                  // style={{ width: '715px' }}
                  value={'asd' ?? 'N/A'}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="GSTIN"
                  fullWidth
                  variant="filled"
                  // style={{ width: '715px' }}
                  value={'asdsd' ?? 'N/A'}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                    endAdornment: (
                      <InputAdornment>
                        <EventNoteOutlinedIcon
                          style={{ color: 'rgba(168, 175, 183, 1)' }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid itme xs={12} style={{ marginBottom: '1rem' }}>
              <TextField
                label="SCOPE OR WORK"
                fullWidth
                variant="filled"
                // style={{ width: '715px' }}
                value={'asd' ?? 'N/A'}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    input: `${classes.textFieldStyle}  `,
                    root: classes.input,
                  },
                  endAdornment: (
                    <InputAdornment>
                      <LocationOnOutlinedIcon
                        style={{ color: 'rgba(168, 175, 183, 1)' }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                marginBottom: '1rem',
              }}
            >
              <TextField
                label="ADDRESS"
                fullWidth
                variant="filled"
                // style={{ width: '715px' }}
                value={
                  'V.V.Nagar, Tisaiyanvilai, Tirunelveli Natak vin 5703, New Delhi, India' ??
                  'N/A'
                }
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    input: `${classes.textFieldStyle}  `,
                    root: classes.input,
                  },
                  endAdornment: (
                    <InputAdornment>
                      <PhoneOutlinedIcon
                        style={{ color: 'rgba(168, 175, 183, 1)' }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item container xs={12} style={{ marginBottom: '1rem' }}>
              <Grid item xs={6}>
                <TextField
                  label="CITY"
                  fullWidth
                  variant="filled"
                  style={{ paddingRight: '1rem' }}
                  // style={{ width: '715px' }}
                  value={'asd' ?? 'N/A'}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="COUNTRY"
                  fullWidth
                  variant="filled"
                  // style={{ width: '715px' }}
                  value={'asdsd' ?? 'N/A'}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                    endAdornment: (
                      <InputAdornment>
                        <EventNoteOutlinedIcon
                          style={{ color: 'rgba(168, 175, 183, 1)' }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} style={{ marginBottom: '1rem' }}>
              <Grid item xs={6}>
                <TextField
                  label="STATE"
                  fullWidth
                  variant="filled"
                  style={{ paddingRight: '1rem' }}
                  // style={{ width: '715px' }}
                  value={'asd' ?? 'N/A'}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="PIN CODE"
                  fullWidth
                  variant="filled"
                  // style={{ width: '715px' }}
                  value={'asdsd' ?? 'N/A'}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                    endAdornment: (
                      <InputAdornment>
                        <EventNoteOutlinedIcon
                          style={{ color: 'rgba(168, 175, 183, 1)' }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} style={{ marginBottom: '1rem' }}>
              <TextField
                label="BANK NAME"
                fullWidth
                variant="filled"
                // style={{ width: '715px' }}
                value={'Asd' ?? 'N/A'}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    input: `${classes.textFieldStyle}  `,
                    root: classes.input,
                  },
                  endAdornment: (
                    <InputAdornment>
                      <AccountBalanceOutlinedIcon
                        style={{ color: 'rgba(168, 175, 183, 1)' }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              style={{ marginBottom: '1rem' }}
              justify="center"
            >
              <Grid item xs={6}>
                <TextField
                  label="BANK ACCOUNT NUMBER"
                  fullWidth
                  variant="filled"
                  style={{ paddingRight: '1rem' }}
                  value={'Asd' ?? 'N/A'}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="IFSC CODE"
                  fullWidth
                  variant="filled"
                  // style={{ width: '715px' }}
                  value={'Asd' ?? 'N/A'}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Container>
    </Page>
  );
};

export default ContractorDetailPage;
