import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import SortSelection from 'src/components/SortSelection';
import { getSkills, jobReducer } from 'src/redux/jobs';
import DateRangePicker from 'src/components/DateRangePicker';
import SearchInput from 'src/components/SearchInput';

const FiltersContainer = ({ filters, setFilters }) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  //! SELECTORS
  const { skillsList } = useSelector(jobReducer);

  //! INSTANCES
  const dispatch = useDispatch();

  //! LIFE CYCLE
  useEffect(() => {
    dispatch(getSkills());
  }, []);

  //! METHODS
  //! SCOPE OF WORK
  const handleFilters = (e) => {
    const { value } = e.target;

    if (value !== 0) {
      setFilters({
        ...filters,
        sortBy: value,
      });
      //       if (
      //         attendanceList?.attendances?.filter(
      //           (attendance) => attendance.sKillName === value
      //         ).length === 0 &&
      //         value != 0
      //       )
      //         return setFilteredArray(null);

      //       setFilteredArray(
      //         attendanceList?.attendances.filter(
      //           (attendance) => attendance.sKillName === value
      //         )
      //       );
    }
  };

  //! DATE RANGE METHOD
  const handleDateRange = (update) => {
    let [start, end] = update;
    setDateRange(update);
  };

  //! SEARCH INPUT
  const handleSearch = (e) => {
    const { value } = e.target;

    setFilters({ ...filters, search: value });
  };
  return (
    <Grid container xs={12} style={{ padding: '10px' }}>
      <Grid item xs={6} container>
        <Grid item md={5} xs={12} container alignItems="center">
          <Grid item>
            <span style={{ fontWeight: 500, fontSize: '11px' }}>Sort By: </span>
          </Grid>
          <Grid item md={9} xs={8} style={{ paddingLeft: '10px' }}>
            <SortSelection
              initialValue="Skills"
              value={filters.sortBy}
              handleFilters={handleFilters}
              data={skillsList?.map((skill) => ({
                value: skill.name,
                label: skill.name,
              }))}
            />
          </Grid>
        </Grid>
        <Grid item md={7} xs={12} container alignItems="center">
          <Grid item>
            <span style={{ fontWeight: 500, fontSize: '11px' }}>
              Search Filter:{' '}
            </span>
          </Grid>
          <Grid item md={9} xs={9} style={{ paddingLeft: '10px' }}>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              handleDateRange={handleDateRange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6} alignItems="center" container justifyContent="flex-end">
        <Grid item>
          <small>Search:</small>
        </Grid>
        <Grid
          item
          style={{ position: 'relative', paddingLeft: '10px' }}
          xs={11}
        >
          <SearchInput
            // style={{ width: '100%' }}
            search={filters.search}
            handleSearch={handleSearch}
            // className='font12'
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FiltersContainer;
