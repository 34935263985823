import React from 'react';
import {
  CardContent,
  Grid,
  Typography,
  CircularProgress,
  Card,
} from '@material-ui/core';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';

const ProjectStatsCards = ({ projectCardData, classes, loading }) => {
  return (
    <Grid
      container
      item
      xs={12}
      // style={{ border: '2px solid red' }}
      justify="space-between"
      alignItems="center"
      className={classes.cardPaddingRight}
    >
      <Grid
        container
        item
        xs={12}
        md={6}
        className={classes.cardPaddingRight1}
        alignItems="center"
        justify="space-between"
        style={{ margin: '15px 0 0 0' }}
      >
        {projectCardData.map(
          (val, index) =>
            index <= 1 && (
              <Grid
                container
                item
                md={6}
                xs={12}
                style={{
                  marginBottom: '1em',
                }}
                key={index + 1}
                // justify='center'
                alignItems="center"
                className={`${classes.padRight}`}
              >
                <Card
                  elevation={6}
                  style={{
                    borderRadius: 10,
                    width: '100%',
                    cursor: 'pointer',
                    height: '70px',
                  }}
                  // onClick={() =>
                  //   val?.link
                  //     ? navigate(val.link, {
                  //         replace: true,
                  //         // state: { from: location },
                  //       })
                  //     : null
                  // }
                >
                  <CardContent style={{ padding: '10px' }}>
                    <Grid
                      container
                      justify="space-between"
                      // alignItems="center"
                      item
                      xs={12}
                    >
                      <Grid item>
                        <Grid item xs>
                          <Typography
                            style={{
                              fontFamily: 'Roboto',
                              fontSize: 12,
                              color: '#B2BEC7',
                            }}
                          >
                            {val.name}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          alignItems="center"
                          direction="row"
                          justify="space-between"
                          item
                          xs={12}
                        >
                          <Grid item xs={4}>
                            <Typography
                              style={{
                                fontFamily: 'Roboto',
                                fontSize: 20,
                                fontWeight: '400',
                              }}
                            >
                              {loading ? (
                                <CircularProgress
                                  style={{
                                    color: '#81B734',
                                    width: '25px',
                                    height: '25px',
                                  }}
                                />
                              ) : (
                                val.count
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          justify="center"
                          alignItems="center"
                          style={{
                            background: '#A179F2',
                            height: 50,
                            width: 50,
                            borderRadius: 10,
                          }}
                        >
                          {val.name === 'Total Contractors' ||
                          val.name === 'Worker Present' ? (
                            <PeopleOutlineIcon
                              style={{ color: '#fff' }}
                              fontSize="large"
                            />
                          ) : (
                            <img
                              src={val.img}
                              alt="logo1"
                              style={{ width: '30px' }}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            )
        )}
      </Grid>
      <Grid
        container
        item
        xs={12}
        md={6}
        // className={classes.cardPaddingRight}
        alignItems="center"
        justify="space-between"
        style={{ margin: '15px 0 0 0' }}
      >
        {projectCardData.map(
          (val, index) =>
            index > 1 && (
              <Grid
                container
                item
                md={6}
                xs={12}
                style={{
                  marginBottom: '1em',
                }}
                // className={index === 0 && classes.cardPaddingRight}
                key={index + 1}
                // justify='center'
                alignItems="center"
                // justifyContent="space-between"
                className={`${index === 2 ? classes.padRight : null}`}
              >
                <Card
                  elevation={6}
                  style={{
                    borderRadius: 10,
                    width: '100%',
                    cursor: 'pointer',
                    height: '70px',
                  }}
                  // onClick={() =>
                  //   navigate(val.link, {
                  //     replace: true,
                  //     // state: { from: location },
                  //   })
                  // }
                >
                  <CardContent style={{ padding: '10px' }}>
                    <Grid
                      container
                      justify="space-between"
                      // alignItems="center"
                      item
                      xs={12}
                    >
                      <Grid item>
                        <Grid item xs>
                          <Typography
                            style={{
                              fontFamily: 'Roboto',
                              fontSize: 12,
                              color: '#B2BEC7',
                            }}
                          >
                            {val.name}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          alignItems="center"
                          direction="row"
                          justify="space-between"
                          item
                          xs={12}
                        >
                          <Grid item xs={4}>
                            <Typography
                              style={{
                                fontFamily: 'Roboto',
                                fontSize: 20,
                                fontWeight: '400',
                              }}
                            >
                              {loading ? (
                                <CircularProgress
                                  style={{
                                    color: '#81B734',
                                    width: '25px',
                                    height: '25px',
                                  }}
                                />
                              ) : (
                                val.count
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          justify="center"
                          alignItems="center"
                          style={{
                            background: '#A179F2',
                            height: 50,
                            width: 50,
                            borderRadius: 10,
                          }}
                        >
                          {val.name === 'Prduction Rate / Month' ? (
                            <TrendingUpIcon
                              style={{ color: '#fff' }}
                              fontSize="large"
                            />
                          ) : (
                            <PeopleOutlineIcon
                              style={{ color: '#fff' }}
                              fontSize="large"
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            )
        )}
      </Grid>
    </Grid>
  );
};

export default ProjectStatsCards;
