import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';
import {
  DefaultToastContainer,
  ToastProvider,
} from 'react-toast-notifications';

// ** Redux Imports
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { Toaster } from 'react-hot-toast';

const CustomToastContainer = (props) => (
  <DefaultToastContainer {...props} style={{ zIndex: 9999 }} />
);

// Just Need to Deploy by Waleed's end 02

ReactDOM.render(
  <ToastProvider components={{ ToastContainer: CustomToastContainer }}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
        <Toaster />
      </BrowserRouter>
    </Provider>
  </ToastProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
