import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWorkers, updateWorker, workersReducer } from 'src/redux/workers';
import {
  Grid,
  makeStyles,
  TextField,
  Button,
  MenuItem,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useToasts } from 'react-toast-notifications';
import { Link, useNavigate } from 'react-router-dom';
import { getJobs, getSkills, jobReducer } from 'src/redux/jobs';
import moment from 'moment';
import {
  getProjectsClassificationList,
  projectReducer,
} from 'src/redux/projects';
import EditIcon from '@material-ui/icons/Edit';
import { useRef } from 'react';
import PanoramaOutlinedIcon from '@material-ui/icons/PanoramaOutlined';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  input1: {
    display: 'none',
  },
  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    color: '#986FEF',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    borderRadius: '10px',
    padding: '10px 1rem',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  headerCellText: {
    fontSize: 12,
    fontFamily: 'Roboto',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  textField: {
    // marginLeft: theme.spacing(1),
    paddingRight: '1.5rem',
    borderRadius: '10px',
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontWeight: 400,
  },
  input: {
    display: 'none',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  hover: {
    ':hover': {
      backgroundColor: '#A179F2',
    },
  },
  textFieldStyle: {
    fontWeight: 400,
    fontFamily: 'Roboto',
    fontSize: '15px',

    '&::placeholder': {
      fontWeight: 400,
      fontFamily: 'Roboto',
      fontSize: '15px',
      color: 'rgba(126, 131, 134, 0.7)',
    },
  },
  input: {
    borderRadius: '10px',
    backgroundColor: 'rgba(249, 249, 249, 1)',
  },
  ImagePlaceHolder: {
    cursor: 'pointer',
  },
  btn: {
    fontSize: '12px',
    backgroundColor: 'rgba(129, 183, 52, 1)',
    color: '#fff',
    fontFamily: 'Roboto',
    textTransform: 'capitalize',
    padding: '.6rem 1.3rem',
  },
  labels: {
    color: 'rgba(173, 186, 195, 1)',
    fontWeight: 600,
    fontSize: '10px',
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
    paddingBottom: '.5rem',
  },
}));

const EditWorkerDetails = () => {
  const [listOfJobs, setListOfJobs] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const [preview, setPreview] = useState(null);
  const [profilePreview, setProfilePreview] = useState(null);

  //! INSTANCES
  const classes = useStyles();
  let dispatch = useDispatch();
  const formData = new FormData();
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const inputRef = useRef(null);

  //! SELECTORS
  const { loading, selectedWorker, selectedWorkerProject } =
    useSelector(workersReducer);
  const { skillsList, jobsList } = useSelector(jobReducer);
  const { projectClassificationList } = useSelector(projectReducer);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });

  //! LIFE-CYCLE
  useEffect(() => {
    // setValue(selectedWorker?.address);
    if (projectClassificationList.length === 0) {
      dispatch(getProjectsClassificationList());
    }
    if (!jobsList) {
      dispatch(getJobs());
    }
    dispatch(getSkills());
    if (!projectId) {
      setProjectId(selectedWorkerProject);
    }
    setListOfJobs(
      jobsList?.filter((job) => job.projectId === selectedWorkerProject && job)
    );
  }, []);
  // const handleAdharCard = (e) => {
  // 	// let selectedImage = e.target.files[0];
  // 	setAdharCard(URL.createObjectURL(e.target.files[0]));
  // 	formik.setFieldValue('adharCard', e.target.files[0], true);
  // };
  const validationSchema = yup.object({
    fullName: yup.string().required('Fullname is required'),
    address: yup.string().required('Address is required'),
    bankName: yup.string().required('Bank name is required'),
    bankAccountNumber: yup.string().required('Bank A/C is required'),
    ifscCode: yup.string().required('IFSC code is required'),
    // healthCard: yup.string().required('Supervisor is required'),
    // policeVerification: yup.string().required('Location is required'),

    // ProfilePicture: yup.string().required('Profile picture is required'),
    // IdentityCard: yup.string().required('Identity card is required'),
    // PanCard: yup.string().required('PAN card is required'),
  });

  const formik = useFormik({
    initialValues: {
      fullName: selectedWorker?.fullName ?? 'N/A',
      address: selectedWorker?.address ?? 'N/A',
      bankName: selectedWorker?.bankName ?? 'N/A',
      bankAccountNumber: selectedWorker?.bankAccountNumber ?? 'N/A',
      ifscCode: selectedWorker?.ifscCode ?? 0,
      healthCard: selectedWorker?.healthCard ?? false,
      policeVerification: selectedWorker?.policeVerification ?? false,
      skillId: selectedWorker?.workerSkills[0]?.skill.skillId,
      gender: selectedWorker?.gender ?? 0,
      dateOfBirth:
        moment(selectedWorker?.dateOfBirth).format('YYYY-MM-DD') ?? 'N/A',
      identityNumber: selectedWorker?.identityNumber ?? 'N/A',
      projectId: selectedWorkerProject ?? 'N/A',
      jobId: selectedWorker?.workerJobs[0]?.jobId,
      skillTypeId: selectedWorker?.workerSkills[0]?.skillTypeId ?? '',
      adharCard: '',
      Identity: '',
      //
      // ProfilePicture: selectedWorker?.contractorId ?? 0,
      // PanCard: selectedWorker?.PanCard ?? '',
    },
    enableReinitialize: true,

    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (selectedWorker?.workerJobs[0]?.jobId === undefined) {
        // const { jobId, ...rest } = values;

        const objectKeys = Object.keys(values);
        const objectValues = Object.values(values);

        objectKeys.forEach((key, index) => {
          formData.append(key, objectValues[index]);
        });
        formData.append('workerId', selectedWorker?.workerId);
        let filesArray =
          formik.values.adharCard !== ''
            ? [
                { file: formik.values.adharCard, type: 'IdentityCard' },
                { file: formik.values.identity, type: 'ProfilePicture' },
              ]
            : [];
        let resp = await dispatch(
          updateWorker(formData, filesArray, selectedWorker?.workerId)
        );

        if (resp.status === 200) {
          formik.resetForm();
          navigate('..');
          if (!selectedWorker) {
            return dispatch(getWorkers());
          }
          dispatch(getWorkers(selectedWorkerProject));
          return addToast('Worker Updated Successfully', {
            appearance: 'success',
            autoDismiss: true,
          });
        }
        if (resp?.response?.data) {
          return addToast(resp.response.data, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      }

      const { adharCard, identity, ...rest } = values;

      const objectKeys = Object.keys(rest);
      const objectValues = Object.values(rest);

      objectKeys.forEach((key, index) => {
        formData.append(key, objectValues[index]);
      });
      formData.append('workerId', selectedWorker?.workerId);
      let filesArray =
        formik.values.adharCard !== ''
          ? [
              { file: formik.values.adharCard, type: 'IdentityCard' },
              { file: formik.values.identity, type: 'ProfilePicture' },
            ]
          : [];
      let resp = await dispatch(
        updateWorker(formData, filesArray, selectedWorker?.workerId)
      );
      if (resp.status === 200) {
        formik.resetForm();
        navigate('..');
        if (!selectedWorker) {
          return dispatch(getWorkers());
        }
        dispatch(getWorkers(selectedWorkerProject));
        return addToast('Worker Updated Successfully', {
          appearance: 'success',
          autoDismiss: true,
        });
      }
      if (resp?.response?.data) {
        return addToast(resp.response.data, {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    },
  });

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });
  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          key={place_id}
          onClick={handleSelect(suggestion)}
          style={{
            cursor: 'pointer',
            padding: '5px',
            borderBottom: '1px solid #222',
            fontFamily: 'Roboto',
          }}
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
    clearSuggestions();
  };

  const handleSelect =
    ({ description }) =>
    () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      // const {
      // 	place_id,
      // 	structured_formatting: { main_text, secondary_text },
      // } = description;

      setValue(description, false);
      clearSuggestions();

      // Get latitude and longitude via utility functions
      getGeocode({ address: description }).then((results) => {
        const { lat, lng } = getLatLng(results[0]);
        formik.setFieldValue('address', results[0].formatted_address, true);
      });
    };

  const handleProjectId = (e) => {
    if (e.target.value === '') {
      setProjectId('');
      formik.setFieldValue('projectId', '');
      return setListOfJobs(jobsList);
    }
    formik.setFieldValue('projectId', e.target.value);
    const projectId = parseInt(e.target.value);
    setProjectId(projectId);
    setListOfJobs(
      jobsList?.filter((job) => job.projectId === projectId && job)
    );
    // let data = jobsList?.filter((job) => job.projectId === projectId && job);
  };
  //
  const handleEditClick = () => {
    inputRef.current.click();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    // onImageChange(file);
    setPreview(URL.createObjectURL(file));
    formik.setFieldValue('adharCard', e.target.files[0], true);
  };
  const handleProfileImage = (e) => {
    // let selectedImage = e.target.files[0];
    setProfilePreview(URL.createObjectURL(e.target.files[0]));
    formik.setFieldValue('identity', e.target.files[0], true);
  };
  return (
    <Grid container justify="center">
      <Grid item md={4} xs={12} style={{ paddingLeft: '1rem' }}>
        {selectedWorker?.workerDocuments?.filter(
          (worker) => worker.documentId === 'ProfilePicture'
        )?.length ? (
          selectedWorker?.workerDocuments
            ?.filter((worker) => worker.documentId === 'ProfilePicture')
            ?.map((obj, index) => (
              <Grid item xs={12} key={index}>
                {obj?.documentId === 'ProfilePicture' && (
                  <Grid item>
                    <input
                      accept="image/*"
                      className={classes.input1}
                      id="icon-button-file"
                      type="file"
                      onChange={handleProfileImage}
                    />
                    <label
                      htmlFor="icon-button-file"
                      className={classes.ImagePlaceHolder}
                    >
                      {profilePreview ? (
                        <img
                          src={profilePreview}
                          alt="project  prev"
                          style={{
                            width: '300px',
                            height: '250px',
                            borderRadius: '10px',
                            // border: Boolean(formik.errors.image) && '1px solid red',
                          }}
                        />
                      ) : (
                        <img
                          src={`${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${obj.url}`}
                          alt="project  prev"
                          style={{
                            width: '300px',
                            height: '250px',
                            borderRadius: '10px',
                            // border: Boolean(formik.errors.image) && '1px solid red',
                          }}
                        />
                      )}
                    </label>
                    <p
                      style={{
                        margin: '4px 14px 0px',
                        color: 'red',
                        fontSize: '12px',
                        fontWeight: '0',
                      }}
                    >
                      {' '}
                      {formik.touched.image && formik.errors.image}
                    </p>
                  </Grid>
                  // <img
                  //   src={`${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${obj.url}`}
                  //   alt="project"
                  //   style={{
                  //     width: "95%",
                  //     height: "350px",
                  //     // height: "90px",
                  //     borderRadius: "10px",
                  //     textAlign: "center",
                  //     marginTop: "1rem",
                  //   }}
                  // />
                )}
              </Grid>
            ))
        ) : (
          <Grid item>
            <input
              accept="image/*"
              className={classes.input1}
              id="icon-button-file"
              type="file"
              onChange={handleProfileImage}
            />
            <label
              htmlFor="icon-button-file"
              className={classes.ImagePlaceHolder}
            >
              {profilePreview ? (
                <img
                  src={profilePreview}
                  alt="project  prev"
                  style={{
                    width: '300px',
                    height: '250px',
                    borderRadius: '10px',
                    // border: Boolean(formik.errors.image) && '1px solid red',
                  }}
                />
              ) : (
                <Grid
                  container
                  style={{
                    width: '300px',
                    height: '250px',
                    borderRadius: '10px',
                    backgroundColor: 'white',
                    border: '1px solid #ddd',
                    marginBottom: '10px',
                  }}
                  justify="center"
                  alignItems="center"
                >
                  <Grid
                    item
                    container
                    justify="center"
                    alignItems="center"
                    direction="column"
                  >
                    <Grid item>
                      <PersonAddOutlinedIcon
                        style={{
                          color: 'RGB(183, 194, 202)',
                          width: '50px',
                          height: '50px',
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      style={{
                        color: 'RGB(151, 196, 87)',
                        fontSize: '12px',
                      }}
                    >
                      +{' '}
                      <span style={{ textDecoration: 'underline' }}>
                        UPLOAD PROFILE PICTURE
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </label>
            <p
              style={{
                margin: '4px 14px 0px',
                color: 'red',
                fontSize: '12px',
                fontWeight: '0',
              }}
            >
              {' '}
              {formik.touched.image && formik.errors.image}
            </p>
          </Grid>
        )}

        {selectedWorker?.workerDocuments?.filter(
          (worker) => worker.documentId === 'IdentityCard'
        )?.length ? (
          selectedWorker?.workerDocuments
            ?.filter((worker) => worker.documentId === 'IdentityCard')
            ?.map((obj, index) => (
              <Grid item xs={12} key={index}>
                {obj?.documentId === 'IdentityCard' && (
                  <Grid item>
                    <input
                      accept="image/*"
                      className={classes.input1}
                      id="icon-button-file1"
                      type="file"
                      onChange={handleImageChange}
                    />
                    <label
                      htmlFor="icon-button-file1"
                      className={classes.ImagePlaceHolder}
                    >
                      {preview ? (
                        <img
                          src={preview}
                          alt="project  prev"
                          style={{
                            width: '300px',
                            height: '150px',
                            borderRadius: '10px',
                            // border: Boolean(formik.errors.image) && '1px solid red',
                          }}
                        />
                      ) : (
                        <img
                          src={`${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${obj.url}`}
                          alt="project  prev"
                          style={{
                            width: '300px',
                            height: '150px',
                            borderRadius: '10px',
                            // border: Boolean(formik.errors.image) && '1px solid red',
                          }}
                        />
                      )}
                    </label>
                    <p
                      style={{
                        margin: '4px 14px 0px',
                        color: 'red',
                        fontSize: '12px',
                        fontWeight: '0',
                      }}
                    >
                      {' '}
                      {formik.touched.image && formik.errors.image}
                    </p>
                  </Grid>
                  // <img
                  //   src={`${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${obj.url}`}
                  //   alt="project"
                  //   style={{
                  //     width: "95%",
                  //     height: "350px",
                  //     // height: "90px",
                  //     borderRadius: "10px",
                  //     textAlign: "center",
                  //     marginTop: "1rem",
                  //   }}
                  // />
                )}
              </Grid>
            ))
        ) : (
          <Grid item>
            <input
              accept="image/*"
              className={classes.input1}
              id="icon-button-file1"
              type="file"
              // onChange={handleProfileImage}
              onChange={handleImageChange}
            />
            <label
              htmlFor="icon-button-file1"
              className={classes.ImagePlaceHolder}
            >
              {preview ? (
                <img
                  src={preview}
                  alt="project  prev"
                  style={{
                    width: '300px',
                    height: '150px',
                    borderRadius: '10px',
                    // border: Boolean(formik.errors.image) && '1px solid red',
                  }}
                />
              ) : (
                <Grid
                  container
                  style={{
                    width: '300px',
                    height: '150px',
                    borderRadius: '10px',
                    backgroundColor: 'white',
                    border: '1px solid #ddd',
                    marginBottom: '10px',
                  }}
                  justify="center"
                  alignItems="center"
                >
                  <Grid
                    item
                    container
                    justify="center"
                    alignItems="center"
                    direction="column"
                  >
                    <Grid item>
                      <PersonAddOutlinedIcon
                        style={{
                          color: 'RGB(183, 194, 202)',
                          width: '50px',
                          height: '50px',
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      style={{
                        color: 'RGB(151, 196, 87)',
                        fontSize: '12px',
                      }}
                    >
                      +{' '}
                      <span style={{ textDecoration: 'underline' }}>
                        UPLOAD ADHAR CARD
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </label>
            <p
              style={{
                margin: '4px 14px 0px',
                color: 'red',
                fontSize: '12px',
                fontWeight: '0',
              }}
            >
              {' '}
              {formik.touched.image && formik.errors.image}
            </p>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        item
        md={8}
        xs={12}
        justify="center"
        style={{ marginTop: 20, paddingRight: '1rem' }}
      >
        <form
          onSubmit={formik.handleSubmit}
          style={{ opacity: loading ? '0.5' : 1 }}
        >
          <Grid
            container
            item
            xs={12}
            style={{ marginBottom: '.5rem' }}
            justify="center"
          >
            <TextField
              id="fullName"
              name="fullName"
              value={formik.values.fullName}
              onChange={formik.handleChange}
              error={formik.touched.fullName && Boolean(formik.errors.fullName)}
              label="FULL NAME"
              fullWidth
              variant="filled"
              InputProps={{
                disableUnderline: true,
                classes: {
                  input: `${classes.textFieldStyle}`,
                  root: classes.input,
                },
              }}
              style={{
                border:
                  formik.touched.fullName &&
                  Boolean(formik.errors.fullName) &&
                  '1px solid red',
                borderRadius: '10px',
              }}
            />
            <p
              style={{
                margin: '4px 14px 0px',
                color: 'red',
                fontSize: '12px',
                fontWeight: '0',
                width: '100%',
              }}
            >
              {' '}
              {formik.touched.fullName && formik.errors.fullName}
            </p>
          </Grid>
          <Grid container item xs={12} justify="space-between">
            <Grid item xs={12} style={{ marginBottom: '.5rem' }}>
              <TextField
                select
                label="SKILL"
                size="small"
                style={{
                  fontFamily: 'Roboto',

                  // paddingRight: '1rem',
                }}
                // placeholder='----'
                variant="filled"
                fullWidth
                id="skillId"
                name="skillId"
                value={formik.values.skillId}
                error={formik.touched.skillId && Boolean(formik.errors.skillId)}
                // style={{ width: "300px" }}
                onChange={(e) => {
                  // formik.handleChange(e.target.value);
                  formik.setFieldValue('skillId', e.target.value);
                }}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    input: `${classes.textFieldStyle}`,
                    root: classes.input,
                  },
                }}
              >
                <MenuItem value="0">Select Skill</MenuItem>

                {skillsList?.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item.skillId}
                    style={{ fontFamily: 'Roboto' }}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
              <p
                style={{
                  margin: '4px 14px 0px',
                  color: 'red',
                  fontSize: '12px',
                  fontWeight: '0',
                }}
              >
                {formik.touched.skillId && formik.errors.skillId}
              </p>{' '}
              {/* <TextField
								label='SKILL SET'
								fullWidth
								variant='filled'
								value={selectedWorker?.workerSkills[0]?.skill.name}
								// id='fullName'
								// name='fullName'
								// value={formik.values.fullName}
								// value='N/A'
								// onChange={formik.handleChange}
								// error={formik.touched.fullName && Boolean(formik.errors.fullName)}
								InputProps={{
									disableUnderline: true,
									classes: {
										input: `${classes.textFieldStyle}  `,
										root: classes.input,
									},
								}}
							/> */}
            </Grid>
            <Grid item xs={12} style={{ marginBottom: '.5rem' }}>
              <TextField
                select
                label="SKILL LEVEL"
                size="small"
                style={{
                  fontFamily: 'Roboto',

                  // paddingRight: '1rem',
                }}
                // placeholder='----'
                variant="filled"
                fullWidth
                id="skillTypeId"
                name="skillTypeId"
                value={formik.values.skillTypeId}
                error={
                  formik.touched.skillTypeId &&
                  Boolean(formik.errors.skillTypeId)
                }
                // style={{ width: "300px" }}
                onChange={(e) => {
                  // formik.handleChange(e.target.value);
                  formik.setFieldValue('skillTypeId', e.target.value);
                }}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    input: `${classes.textFieldStyle}`,
                    root: classes.input,
                  },
                }}
              >
                <MenuItem value="">Select Skill Level</MenuItem>

                {['Supervisor', 'Skilled', 'Helper']?.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item}
                    style={{ fontFamily: 'Roboto' }}
                  >
                    {item}
                  </MenuItem>
                ))}
              </TextField>
              <p
                style={{
                  margin: '4px 14px 0px',
                  color: 'red',
                  fontSize: '12px',
                  fontWeight: '0',
                }}
              >
                {formik.touched.skillId && formik.errors.skillId}
              </p>{' '}
              {/* <TextField
								label='SKILL SET'
								fullWidth
								variant='filled'
								value={selectedWorker?.workerSkills[0]?.skill.name}
								// id='fullName'
								// name='fullName'
								// value={formik.values.fullName}
								// value='N/A'
								// onChange={formik.handleChange}
								// error={formik.touched.fullName && Boolean(formik.errors.fullName)}
								InputProps={{
									disableUnderline: true,
									classes: {
										input: `${classes.textFieldStyle}  `,
										root: classes.input,
									},
								}}
							/> */}
            </Grid>
            {selectedWorker?.workerTypeId === 'Offline' && (
              <Grid
                container
                xs={12}
                justify="space-between"
                className={classes.commonStyleInput}
              >
                <Grid
                  item
                  xs={6}
                  style={{
                    marginBottom: '16px',
                    // paddingRight: '1rem',
                  }}
                >
                  <TextField
                    select
                    label="PROJECTS"
                    size="small"
                    style={{
                      fontFamily: 'Roboto',

                      paddingRight: '1rem',
                    }}
                    // placeholder='----'
                    variant="filled"
                    fullWidth
                    // style={{ width: "300px" }}
                    // defaultValue={projectId}
                    // value={projectId}
                    value={formik.values.projectId}
                    onChange={handleProjectId}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: `${classes.textFieldStyle}`,
                        root: classes.input,
                      },
                    }}
                  >
                    <MenuItem value={''}>Select Project</MenuItem>

                    {projectClassificationList?.map((project) => (
                      <MenuItem
                        key={project.name}
                        value={project?.projectId}
                        style={{ fontFamily: 'Roboto' }}
                      >
                        {project.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={6} style={{ paddingRight: '1rem' }}>
                  <TextField
                    select
                    label="JOBS"
                    size="small"
                    style={{ fontFamily: 'Roboto' }}
                    // placeholder='----'
                    variant="filled"
                    fullWidth
                    // style={{ width: "300px" }}

                    id="jobId"
                    name="jobId"
                    value={formik.values.jobId}
                    onChange={(e) =>
                      formik.setFieldValue('jobId', e.target.value)
                    }
                    error={formik.touched.jobId && Boolean(formik.errors.jobId)}
                    helperText={formik.touched.jobId && formik.errors.jobId}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: `${classes.textFieldStyle}`,
                        root: classes.input,
                      },
                    }}
                  >
                    <MenuItem value="">Select Job</MenuItem>

                    {listOfJobs?.map((job) => (
                      <MenuItem
                        key={job.jobName}
                        value={job?.jobId}
                        style={{ fontFamily: 'Roboto' }}
                      >
                        {job.jobName + ' - ' + job.description}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            )}

            <Grid
              container
              item
              xs={12}
              style={{ marginBottom: '.5rem' }}
              justify="center"
            >
              <TextField
                label="PHONE"
                fullWidth
                variant="filled"
                // style={{ width: '715px' }}
                value={selectedWorker?.phoneNumber ?? 'N/A'}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    input: `${classes.textFieldStyle}  `,
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              style={{ marginBottom: '.5rem' }}
              justify="center"
            >
              <div ref={ref} style={{ position: 'relative', width: '100%' }}>
                <TextField
                  id="address"
                  name="address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  label="ADDRESS"
                  variant="filled"
                  fullWidth
                  // value={value}
                  // onChange={handleInput}
                  // disabled={!ready}
                  placeholder="Enter location"
                  style={{
                    // borderRadius: '10px',
                    // fontFamily: 'Roboto',
                    // fontSize: '15px',
                    // fontWeight: 400,
                    // border: 'none',
                    // width: '100%',
                    // padding: '10px 14px',
                    // background: 'none',
                    // borderColor: '#DCDEE0',
                    // outline: 'none',

                    border:
                      formik.touched.address &&
                      Boolean(formik.errors.address) &&
                      '1px solid red',
                    borderRadius: '10px',
                  }}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                  }}
                />
                <p
                  style={{
                    margin: '4px 14px 0px',
                    color: 'red',
                    fontSize: '12px',
                    fontWeight: '0',
                  }}
                >
                  {' '}
                  {formik.touched.address && formik.errors.address}
                </p>
                {status === 'OK' && (
                  <ul
                    style={{
                      listStyleType: 'none',
                      position: 'absolute',
                      top: '45px',
                      fontFamily: 'Roboto',
                      backgroundColor: '#fff',
                      zIndex: 100,
                    }}
                  >
                    {renderSuggestions()}
                  </ul>
                )}
              </div>
            </Grid>
            <Grid
              container
              item
              xs={6}
              style={{ marginBottom: '.5rem' }}
              justify="center"
            >
              {/* <FormControl
								variant='filled'
								label='GENDER'
								fullWidth
								style={{
									paddingRight: '15px',
								}}
								// size='small'

								className={`${classes.textFieldStyle} ${classes.input}`}>
								<InputLabel
									id='demo-simple-select-filled-label'
									style={{ marginBottom: '20px' }}>
									GENDER
								</InputLabel>
								<Select
									id='gender'
									name='gender'
									value={formik.values.gender}
									error={formik.touched.gender && Boolean(formik.errors.gender)}
									variant='outlined'
									size='small'
									onChange={(e) => {
										// formik.handleChange(e.target.value);
										formik.setFieldValue('gender', e.target.value);
									}}
									style={{ fontFamily: 'Roboto' }}>
									<MenuItem value='0'>Select Gender</MenuItem>

									<MenuItem value={'Male'} style={{ fontFamily: 'Roboto' }}>
										Male
									</MenuItem>
									<MenuItem value={'Female'} style={{ fontFamily: 'Roboto' }}>
										Female
									</MenuItem>
								</Select>
							</FormControl> */}
              <TextField
                select
                fullWidth
                label="GENDER"
                id="gender"
                name="gender"
                value={formik.values.gender}
                error={formik.touched.gender && Boolean(formik.errors.gender)}
                variant="filled"
                size="small"
                style={{
                  fontFamily: 'Roboto',

                  paddingRight: '1rem',
                }}
                onChange={(e) => {
                  // formik.handleChange(e.target.value);
                  formik.setFieldValue('gender', e.target.value);
                }}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    input: `${classes.textFieldStyle}`,
                    root: classes.input,
                  },
                }}
              >
                <MenuItem value="0">Select Gender</MenuItem>

                <MenuItem value={'Male'} style={{ fontFamily: 'Roboto' }}>
                  Male
                </MenuItem>
                <MenuItem value={'Female'} style={{ fontFamily: 'Roboto' }}>
                  Female
                </MenuItem>
              </TextField>
              <p
                style={{
                  margin: '4px 14px 0px',
                  color: 'red',
                  fontSize: '12px',
                  fontWeight: '0',
                }}
              >
                {formik.touched.gender && formik.errors.gender}
              </p>

              {/* <TextField
								label='GENDER'
								fullWidth
								variant='filled'
								style={{ paddingRight: '1rem' }}
								// style={{ width: '715px' }}
								id='gender'
								name='gender'
								value={formik.values.gender}
								onChange={formik.handleChange}
								error={formik.touched.gender && Boolean(formik.errors.gender)}
								InputProps={{
									disableUnderline: true,
									classes: {
										input: `${classes.textFieldStyle}  `,
										root: classes.input,
									},
								}}
							/> */}
            </Grid>
            <Grid
              container
              item
              xs={6}
              style={{ marginBottom: '.5rem' }}
              justify="center"
            >
              {/* ADD DATE PICKER */}
              <TextField
                style={
                  {
                    // marginBottom: 10,
                  }
                }
                label="Date of Birth"
                id="dateOfBirth"
                name="dateOfBirth"
                value={formik.values.dateOfBirth}
                onChange={formik.handleChange}
                error={
                  formik.touched.dateOfBirth &&
                  Boolean(formik.errors.dateOfBirth)
                }
                helperText={
                  formik.touched.dateOfBirth && formik.errors.dateOfBirth
                }
                // placeholder='Number of project days'
                fullWidth
                type="date"
                // margin='normal'
                // backgroundColor: '#F4F6F8',

                InputProps={{
                  shrink: true,
                  disableUnderline: true,
                  classes: {
                    input: `${classes.textFieldStyle}`,
                    root: classes.input,
                  },
                }}
                inputProps={{
                  style: { fontFamily: 'Roboto' },
                }}
                // size='small'
                variant="filled"
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              style={{ marginBottom: '.5rem' }}
              justify="center"
            >
              <TextField
                label="ADHAR CARD NUMBER"
                fullWidth
                variant="filled"
                id="identityNumber"
                name="identityNumber"
                onChange={formik.handleChange}
                error={
                  formik.touched.identityNumber &&
                  Boolean(formik.errors.identityNumber)
                }
                helperText={
                  formik.touched.identityNumber && formik.errors.identityNumber
                }
                // style={{ width: '715px' }}
                value={selectedWorker?.identityNumber}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    input: `${classes.textFieldStyle}  `,
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              style={{ marginBottom: '.5rem' }}
              justify="center"
            >
              <TextField
                label="BANK NAME"
                fullWidth
                variant="filled"
                // style={{ width: '715px' }}
                id="bankName"
                name="bankName"
                value={formik.values.bankName}
                onChange={formik.handleChange}
                error={
                  formik.touched.bankName && Boolean(formik.errors.bankName)
                }
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    input: `${classes.textFieldStyle}  `,
                    root: classes.input,
                  },
                }}
                style={{
                  border:
                    formik.touched.bankName &&
                    Boolean(formik.errors.bankName) &&
                    '1px solid red',
                  borderRadius: '10px',
                }}
              />
              <p
                style={{
                  margin: '4px 14px 0px',
                  color: 'red',
                  fontSize: '12px',
                  fontWeight: '0',
                  width: '100%',
                }}
              >
                {' '}
                {formik.touched.bankName && formik.errors.bankName}
              </p>
            </Grid>
            <Grid
              container
              item
              xs={12}
              style={{ marginBottom: '.5rem' }}
              justify="center"
            >
              <Grid item xs={12} md={6}>
                <TextField
                  label="BANK ACCOUNT NUMBER"
                  fullWidth
                  variant="filled"
                  style={{
                    paddingRight: '1rem',

                    border:
                      formik.touched.bankAccountNumber &&
                      Boolean(formik.errors.bankAccountNumber) &&
                      '1px solid red',
                    borderRadius: '10px',
                  }}
                  id="bankAccountNumber"
                  name="bankAccountNumber"
                  value={formik.values.bankAccountNumber}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.bankAccountNumber &&
                    Boolean(formik.errors.bankAccountNumber)
                  }
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                  }}
                />
                <p
                  style={{
                    margin: '4px 14px 0px',
                    color: 'red',
                    fontSize: '12px',
                    fontWeight: '0',
                    width: '100%',
                  }}
                >
                  {' '}
                  {formik.touched.bankAccountNumber &&
                    formik.errors.bankAccountNumber}
                </p>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="IFSC CODE"
                  fullWidth
                  variant="filled"
                  // style={{ width: '715px' }}
                  id="ifscCode"
                  name="ifscCode"
                  value={formik.values.ifscCode}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.ifscCode && Boolean(formik.errors.ifscCode)
                  }
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                  }}
                  style={{
                    border:
                      formik.touched.ifscCode &&
                      Boolean(formik.errors.ifscCode) &&
                      '1px solid red',
                    borderRadius: '10px',
                  }}
                />
                <p
                  style={{
                    margin: '4px 14px 0px',
                    color: 'red',
                    fontSize: '12px',
                    fontWeight: '0',
                    width: '100%',
                  }}
                >
                  {' '}
                  {formik.touched.ifscCode && formik.errors.ifscCode}
                </p>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              style={{ marginBottom: '.5rem' }}
              justify="center"
            >
              {/* <Grid
								item
								xs={6}
								container
								alignItems='center'
								// justify='space-between'
								style={
									{
										// paddingLeft: '10px',
									}
								}>
								<div
									style={{
										backgroundColor: 'RGB(249, 249, 249)',
										marginRight: '1rem',
										width: '100%',
										display: 'flex',
										alignItems: 'center',
										justify: 'space-between',
									}}>
									<Typography style={{ paddingLeft: '10px' }}>
										Health Card
									</Typography>
									<Checkbox
										style={{ color: 'rgba(152, 111, 239, 1)' }}
										checked={formik.values.healthCard}
										id='healthCard'
										name='healthCard'
										onChange={formik.handleChange}
										error={
											formik.touched.healthCard &&
											Boolean(formik.errors.healthCard)
										}
									/>
								</div>
							</Grid>
							<Grid
								item
								xs={6}
								container
								alignItems='center'
								style={{
									// backgroundColor: '#ddd',
									paddingLeft: '10px',
								}}>
								<div
									style={{
										backgroundColor: 'RGB(249, 249, 249)',

										width: '100%',
										display: 'flex',
										alignItems: 'center',
										justify: 'space-between',
									}}>
									<Typography>Police Verfication</Typography>
									<Checkbox
										style={{ color: 'rgba(152, 111, 239, 1)' }}
										// checked={selectedWorker?.policeVerification}
										// name='gilad'
										checked={formik.values.policeVerification}
										id='policeVerification'
										name='policeVerification'
										onChange={formik.handleChange}
										error={
											formik.touched.policeVerification &&
											Boolean(formik.errors.policeVerification)
										}
									/>
								</div>
							</Grid> */}

              <Grid
                item
                xs={12}
                container
                justify="flex-end"
                alignItems="center"
                style={{ marginTop: '1rem' }}
              >
                <Button
                  variant="contained"
                  className={classes.btn}
                  type="submit"
                  disabled={loading}
                >
                  Update
                </Button>
                <Link to="..">
                  <Button
                    variant="contained"
                    className={classes.btn}
                    style={{
                      backgroundColor: 'rgba(161, 121, 242, 1)',
                      marginLeft: '1rem',
                    }}
                  >
                    Cancel
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
      {loading && (
        <CircularProgress
          style={{
            position: 'absolute',
            top: '45%',
            left: '45%',
            color: 'RGB(129, 183, 52)',
          }}
        />
      )}
    </Grid>
  );
};
export default EditWorkerDetails;
