import { Button, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import Page from 'src/components/Page';
import TopCard from './components/TopCard';
import FilterContainer from './components/FiltersContainer';
import ContractorList from './components/ContractorList';
import SetupBillingModal from '../Billing/SetupBillingModal';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-input': {
      fontSize: '0.8rem', // change the font size to make the text smaller
      padding: theme.spacing(1), // adjust the padding to make the text field smaller
      // width: '10ch', // set the width to make the text field smaller
    },
    '& .MuiInputLabel-root': {
      fontSize: '0.6rem', // change the font size to make the label smaller
    },
  },
  modal: {
    '& .MuiDialog-container .MuiDialog-paperWidthLg': {
      maxWidth: '1200px !important',
    },
  },
  buttonGreen: {
    fontSize: 12,
    padding: '15px 45px',
    borderRadius: 8,
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    fontSize: 12,
    padding: '15px 45px',
    borderRadius: 8,
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    borderRadius: '8px',
    color: '#986FEF',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  imageContainer: {
    width: 50,
    height: 50,
    borderRadius: '100%',
    background: 'rgba(1,1,1,0.2)',
    position: 'relative',
    marginRight: 15,
    flex: 'none',
  },
  tocAction: {
    borderRadius: 10,
    background: 'rgba(1,1,1,0.1)',
    height: '100%',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  bordered: {
    '& .MuiTableCell-root': {
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
      borderRight: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  projectAvatar: {
    width: '40px',
    height: '40px',
    background: 'rgba(1,1,1,0.2)',
    objectFit: 'none',
    borderRadius: 25,
  },
}));

const ContractorListIndex = () => {
  const [filters, setFilters] = useState({
    search: '',
    sortBy: 0,
    filterBy: 0,
    searchModal: '',
    filterModalBy: 0,
  });
  const [openModal, setOpenModal] = useState(false);

  let classes = useStyles();

  const workersCardObj = [
    {
      id: 3,
      name: 'Total Work Orders',
      value: 147,
      img: '/static/images/bettamintImages/dashboard1.png',
      stats: 'Daily Stats*',
      percent: '+12%',
      link: ``,
    },
    {
      id: 2,
      name: 'Total Manpower',
      value: 4250,
      stats: 'Daily Stats*',
      img: '/static/images/bettamintImages/active-workers.png',
      percent: '-5%',
      link: ``,
    },
    {
      id: 4,
      name: 'Total Material Issued',
      value: 10,

      img: '/static/images/bettamintImages/TotalMaterialIssued.png',
      stats: 'Daily Stats*',

      link: ``,

      percent: '+10%',
    },
    {
      id: 1,
      name: 'Production Rate',
      value: 10,
      stats: 'Weekly Stats*',
      img: '/static/images/bettamintImages/worker-absent.png',
      percent: '+10%',
      link: ``,
    },
  ];

  return (
    <Page className={classes.root} id="zoom-level" title="Contractor">
      <SetupBillingModal
        classes={classes}
        open={openModal}
        handleClose={() => setOpenModal(false)}
      />
      <Container maxWidth={false}>
        <Grid
          item
          container
          xs={12}
          style={{
            // background: 'white',
            // padding: '1em',
            borderRadius: '10px',
          }}
        >
          <Grid
            xs={3}
            container
            style={{
              // paddingLeft: '0.75rem',
              paddingBottom: '0.75rem',
            }}
          >
            <Typography variant="h6">All Contractors</Typography>
          </Grid>
          <Grid container item xs={12}>
            {workersCardObj?.map((item, index) => (
              <Grid item xs={3} key={index + 1}>
                <TopCard val={item} index={index} />
              </Grid>
            ))}
          </Grid>
          <Grid
            container
            item
            xs={12}
            justify="flex-end"
            gap={5}
            style={{ marginBottom: '1rem' }}
          >
            <Button
              className={classes.buttonPurpleOpacity}
              style={{ textTransform: 'capitalize' }}
              onClick={() => setOpenModal(true)}
            >
              + Add New Contractor
            </Button>
          </Grid>

          <Grid
            container
            xs={12}
            style={{
              backgroundColor: '#E9EEF2',
              borderRadius: '11px 11px 0 0',
            }}
          >
            <FilterContainer filters={filters} setFilters={setFilters} />
          </Grid>
        </Grid>

        <ContractorList filters={filters} setFilters={setFilters} />
      </Container>
    </Page>
  );
};

export default ContractorListIndex;
