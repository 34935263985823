import React, { useEffect, useRef, useState } from 'react';
import {
  Avatar,
  Button,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  VictoryChart,
  VictoryBar,
  VictoryAxis,
  VictoryTheme,
  VictoryTooltip,
  VictoryStack,
} from 'victory';
import DatePicker from 'react-datepicker';
import defaultProjectImage from '../../../assests/Projects Icon.png';

import { useDispatch, useSelector } from 'react-redux';
import {
  getProjectsClassificationList,
  projectReducer,
} from 'src/redux/projects';
import { Autocomplete } from '@material-ui/lab';

const data = [
  {
    x: 'w-01',
    workDone: 4,
    certifiedWorkDone: 10,
  },
  {
    x: 'w-02',
    workDone: 4,
    certifiedWorkDone: 15,
  },
  {
    x: 'w-03',
    workDone: 2.5,
    certifiedWorkDone: 7.5,
  },
  {
    x: 'w-04',
    workDone: 6,
    certifiedWorkDone: 10,
  },
  {
    x: 'w-05',
    workDone: 2.5,
    certifiedWorkDone: 7.5,
  },
  {
    x: 'w-06',
    workDone: 8.5,
    certifiedWorkDone: 10,
  },
  {
    x: 'w-07',
    workDone: 3.5,
    certifiedWorkDone: 7,
  },
];

export default function WorkOrderChart(props) {
  const { classes } = props;

  const [chartWidth, setChartWidth] = useState(600);
  const chartContainerRef = useRef(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const handleResize = () => {
      if (chartContainerRef.current) {
        setChartWidth(chartContainerRef.current.offsetWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [currentProject, setCurrentProject] = useState(null);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [classifiedProject, setClassifiedProject] = useState('');

  const [startDate, endDate] = dateRange;
  let dispatch = useDispatch();

  const { projectClassificationList } = useSelector(projectReducer);

  useEffect(() => {
    if (projectClassificationList.length === 0) {
      dispatch(getProjectsClassificationList());
    }
  }, [projectClassificationList]); // eslint-disable-line

  const handleProjectChange = (project) => {
    setClassifiedProject([project]);
    setCurrentProject(project);
  };

  return (
    <Grid item container className={classes.graphContainer}>
      <Grid item container className={classes.graph}>
        <Grid item container style={{ height: '100%' }}>
          <Grid item container className={classes.graphTitle}>
            <Typography variant={isSmallScreen ? 'h6' : 'h5'}>
              Work Orders
            </Typography>
            <Button className={classes.purpleOpacity}>View Details</Button>
          </Grid>
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.fontSmall}
            style={{ padding: '0px 15px' }}
          >
            <Grid item xs={6}>
              <Grid container alignItems="center">
                <Grid item style={{ paddingRight: '10px' }}>
                  <Avatar
                    alt="Project Image"
                    style={{
                      width: '35px',
                      height: '35px',
                      marginLeft: '.5rem',
                    }}
                    src={
                      !classifiedProject[0]?.url
                        ? defaultProjectImage
                        : `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${classifiedProject[0]?.url}`
                    }
                  />
                </Grid>
                <Grid item>
                  <Autocomplete
                    autoSelect
                    size="small"
                    options={projectClassificationList}
                    getOptionLabel={(option) => option.name}
                    style={{ minWidth: '150px' }}
                    value={currentProject}
                    onChange={(event, newValue) => {
                      handleProjectChange(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Select Project"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              alignItems="center"
              justifyContent="space-between"
              xs={6}
            >
              <Typography style={{ fontSize: '0.9rem' }}>Filters:</Typography>
              <Grid style={{ width: '80%' }}>
                <DatePicker
                  showIcon
                  dateFormat="dd MMM, yyyy"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container justifyContent="center">
            <Grid
              item
              xs={12}
              ref={chartContainerRef}
              style={{ padding: '0px 20px' }}
            >
              <VictoryChart theme={VictoryTheme.grayscale} width={chartWidth}>
                <VictoryAxis
                  dependentAxis
                  style={{
                    grid: { stroke: 'rgba(1,1,1,0.05)' },
                    axis: { stroke: 'transparent' },
                    ticks: { stroke: 'transparent' },
                    tickLabels: {
                      fill: 'rgba(112, 112, 112, 1)',
                      fontSize: 14,
                      fontFamily: 'Roboto',
                    },
                  }}
                />
                <VictoryAxis
                  inDependentAxis
                  style={{
                    axis: { stroke: 'black' },
                    ticks: { stroke: 'black' },
                    tickLabels: {
                      fill: 'rgba(112, 112, 112, 1)',
                      fontSize: 14,
                      fontFamily: 'Roboto',
                    },
                  }}
                />
                <VictoryStack
                  colorScale={['RGB(133, 186, 89)', '#9872EF']}
                  style={{
                    parent: { border: '3px solid #9872EF' },
                  }}
                >
                  <VictoryBar
                    barWidth={40}
                    data={data}
                    x="x"
                    labels={({ datum }) =>
                      `WORK DONE \n ${datum.certifiedWorkDone?.toLocaleString()}%`
                    }
                    y="workDone"
                    alignment="start"
                    barRatio={0.8}
                    style={{ labels: { fill: 'RGB(133, 186, 89)' } }}
                    labelComponent={
                      <VictoryTooltip
                        label={({ datum }) => `label yahan bhi he`}
                        style={{ fill: 'white' }}
                        flyoutStyle={{
                          padding: '10px 20px', // Adjust the padding as needed
                          // padding: "5px 1rem",
                          color: 'white',
                          height: 40,
                          fontWeight: 'bold',
                          fill: 'black', // Background color of the tooltip box
                        }}
                        textStyle={{
                          padding: 8, // Add padding to the text content
                          // You can also customize other text styles like font size, font family, etc. here
                        }}
                      />
                    }
                  />
                  <VictoryBar
                    cornerRadius={{ top: 4, bottom: 0 }}
                    barWidth={40}
                    data={data}
                    x="x"
                    y="certifiedWorkDone"
                    labels={({ datum }) =>
                      `CERTIFIED \n ${datum.certifiedWorkDone?.toLocaleString()}% Paid`
                    }
                    alignment="start"
                    barRatio={0.8}
                    style={{ labels: { fill: '#9872EF' } }}
                    labelComponent={
                      <VictoryTooltip
                        label={({ datum }) => `label yahan bhi he`}
                        style={{ fill: 'white' }}
                        flyoutStyle={{
                          padding: '10px 20px', // Adjust the padding as needed
                          // padding: "5px 1rem",
                          color: 'white',
                          height: 40,
                          fontWeight: 'bold',
                          fill: 'black', // Background color of the tooltip box
                        }}
                        textStyle={{
                          padding: 8, // Add padding to the text content
                          // You can also customize other text styles like font size, font family, etc. here
                        }}
                      />
                    }
                  />
                </VictoryStack>
              </VictoryChart>
            </Grid>
          </Grid>
          <Grid
            container
            justify="space-between"
            style={{ padding: '0px 20px' }}
          >
            <Grid
              item
              xs={12}
              container
              justify="space-between"
              alignItems="center"
            >
              <Grid item container xs={4}>
                <Typography>
                  Turnover Rate
                  <span
                    style={{
                      marginLeft: 10,
                      padding: 5,
                      backgroundColor: 'RGB(133, 186, 89)',
                      color: 'white',
                      borderRadius: 5,
                      fontSize: 14,
                      fontWeight: 'bold',
                    }}
                  >
                    93%
                  </span>
                </Typography>
              </Grid>
              <Grid
                item
                container
                xs={7}
                alignItems="center"
                justifyContent="space-around"
              >
                <Grid
                  item
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: 'rgba(178, 190, 199, 1)',
                    }}
                    className={classes.fontSmall}
                  >
                    Total Order Value
                  </Typography>
                  <div className="helper-box"></div>
                </Grid>
                <Grid
                  item
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: 'rgba(178, 190, 199, 1)',
                    }}
                    className={classes.fontSmall}
                  >
                    Work Done
                  </Typography>
                  <div className="skilled-box"></div>
                </Grid>
                <Grid
                  item
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: 'rgba(178, 190, 199, 1)',
                    }}
                    className={classes.fontSmall}
                  >
                    Certified Work
                  </Typography>
                  <div className="supervisor-box"></div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container alignItems="end">
            <Grid item xs={4} style={{ padding: 10 }}>
              <Grid
                item
                container
                justifyContent="center"
                style={{
                  backgroundColor: 'rgb(243, 246, 248)',
                  borderRadius: 10,
                  height: 70,
                  flexDirection: 'column',
                  padding: '0px 10px',
                }}
              >
                <Typography align="center" style={{ color: '#81B734' }}>
                  ₹00
                </Typography>
                <Typography align="center">Total Work Done</Typography>
              </Grid>
            </Grid>
            <Grid item xs={4} style={{ padding: 10 }}>
              <Grid
                item
                container
                justifyContent="center"
                style={{
                  backgroundColor: 'rgb(243, 246, 248)',
                  borderRadius: 10,
                  height: 70,
                  flexDirection: 'column',
                  padding: '0px 10px',
                }}
              >
                <Typography align="center" style={{ color: '#A179F2' }}>
                  ₹00
                </Typography>
                <Typography align="center">Total Certified</Typography>
              </Grid>
            </Grid>
            <Grid item xs={4} style={{ padding: 10 }}>
              <Grid
                item
                container
                justifyContent="center"
                style={{
                  backgroundColor: 'rgb(243, 246, 248)',
                  borderRadius: 10,
                  height: 70,
                  flexDirection: 'column',
                  padding: '0px 10px',
                }}
              >
                <Typography align="center" style={{ color: '#F5A623' }}>
                  ₹00
                </Typography>
                <Typography align="center">Total Cumulative</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
