import React, { useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import toast from 'react-hot-toast';
import moment from 'moment';
import {
  Grid,
  Card,
  Typography,
  Button,
  CardActions,
  CardActionArea,
  CardMedia,
  IconButton,
  CircularProgress,
  Menu,
  MenuItem,
  DialogContent,
  DialogTitle,
  Dialog,
} from '@material-ui/core';
import ArrowBack from '@material-ui/icons/KeyboardArrowLeft';
import ArrowForward from '@material-ui/icons/KeyboardArrowRight';
import TimerIcon from '@material-ui/icons/Timer';
import RestoreIcon from '@material-ui/icons/Restore';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import ImageIcon from '@material-ui/icons/Image';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import defaultProjectImage from '../../../assests/Projects Icon.png';

import Slider from 'react-slick';
import FilterProjectComponent from 'src/components/FilterProjectComponent';
import { useDispatch, useSelector } from 'react-redux';
import { projectReducer } from 'src/redux/projects';
import FilterByLabourContractorComponent from 'src/components/FilterByLaourContractorComponent';
import { ExpandMore } from '@material-ui/icons';
import { getFieldNoteList, markFieldNoteAction } from 'src/redux/fieldNote';

function CustomNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <IconButton
      className={`${className} custom-arrow next-arrow`}
      style={{
        ...style,
        padding: 20,
        background: '#acacac',
        opacity: 0.8,
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 10,
        color: 'black',
      }}
      onClick={onClick}
    >
      <ArrowForward />
    </IconButton>
  );
}
function CustomPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <IconButton
      className={`${className} custom-arrow prev-arrow`}
      style={{
        ...style,
        padding: 20,
        background: '#acacac',
        opacity: 0.8,
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 10,
        color: 'black',
      }}
      onClick={onClick}
    >
      <ArrowBack />
    </IconButton>
  );
}

function CenterMode(props) {
  const {
    classes,
    items,
    handleProjectChange,
    currentProject,
    isLoading,
    refetch,
  } = props;
  const [LabourContractor, setLabourContractor] = useState(null);
  const [preview, setPreview] = useState(null);
  const [image, setImage] = useState(null);
  const [openFileUploadModal, setOpenFileUploadModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState([]);
  const [markStatus, setMarkStatus] = useState(null);

  const { projectClassificationList } = useSelector(projectReducer);
  let dispatch = useDispatch();

  const handleImage = (e) => {
    if (e.target.files.length) {
      setPreview(URL?.createObjectURL(e.target.files[0]));
      setImage(e.target.files[0]);
    }
  };

  const handleClick = (event, index) => {
    const newAnchorEls = [...anchorEl];
    newAnchorEls[index] = event.currentTarget;
    setAnchorEl(newAnchorEls);
  };

  const handleClose = (index) => {
    const newAnchorEls = [...anchorEl];
    newAnchorEls[index] = null;
    setAnchorEl(newAnchorEls);
  };

  const markFieldNote = async (index, fieldNoteId, mark) => {
    handleClose(index);
    setOpenFileUploadModal(true);

    setMarkStatus({
      fieldNoteId,
      mark,
    });
    return;
  };

  const markTheFieldNoteFromServer = async () => {
    let formData = new FormData();
    formData.append('VerificationImage', image);
    formData.append('Action', markStatus.mark);
    let resp = await dispatch(
      markFieldNoteAction(markStatus.fieldNoteId, formData),
    );
    if (resp?.status === 200) {
      dispatch(getFieldNoteList());
      toast.success('Field Note Mark Successfully!', {
        position: 'top-right',
      });
    } else {
      toast.error('Field Note Marked unsuccessful!', {
        position: 'top-right',
      });
    }
    setOpenFileUploadModal(false);
    await refetch(currentProject);
  };

  const classifyProject = (project) => {
    if (project) {
      handleProjectChange(project);
    } else {
      handleProjectChange('');
    }
  };

  const renderFileUploadModal = () => {
    return (
      <Dialog
        open={openFileUploadModal}
        onClose={() => setOpenFileUploadModal(false)}
        keepMounted
        maxWidth="lg"
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          style={{
            padding: '15px',
          }}
          id="customized-dialog-title"
          onClose={() => setOpenFileUploadModal(false)}
        >
          Upload FieldNote Image
        </DialogTitle>
        <DialogContent
          style={{
            padding: '15px',
          }}
        >
          <Grid
            item
            xs={12}
            // className={classes.paddingLeft}
            container
            direction="column"
          >
            {/* Profile */}
            <Grid item>
              <input
                accept="image/*"
                className={classes.input1}
                id="icon-button-file"
                type="file"
                onChange={handleImage}
              />

              <label
                htmlFor="icon-button-file"
                className={classes.ImagePlaceHolder}
              >
                {preview ? (
                  <img
                    src={`${preview}`}
                    alt="project url"
                    style={{
                      width: '300px',
                      height: '250px',
                      borderRadius: '10px',
                      marginBottom: '10px',
                    }}
                  />
                ) : (
                  <Grid
                    container
                    className={classes.imageCard}
                    justify="center"
                    alignItems="center"
                  >
                    <Grid
                      item
                      container
                      justify="center"
                      alignItems="center"
                      direction="column"
                    >
                      <Grid item>
                        <ImageIcon
                          style={{
                            color: 'RGB(183, 194, 202)',
                            width: '50px',
                            height: '50px',
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        style={{
                          color: 'RGB(151, 196, 87)',
                          fontSize: '12px',
                        }}
                      >
                        +{' '}
                        <span style={{ textDecoration: 'underline' }}>
                          UPLOAD PICTURE
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </label>
            </Grid>
          </Grid>
          <Grid container justify="flex-end">
            <Button
              className={classes.buttonPurple}
              onClick={markTheFieldNoteFromServer}
            >
              Mark
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  const renderFilters = () => {
    return (
      <Grid
        item
        container
        xs={12}
        alignItems="center"
        justify="space-between"
        style={{ padding: '1rem' }}
      >
        <Grid container item xs={12}>
          <Grid item container alignItems="center">
            <Grid item>
              <Typography variant="subtitle">Select Project</Typography>
            </Grid>
            <Grid item>
              <FilterProjectComponent
                projectClassificationList={projectClassificationList}
                src={defaultProjectImage}
                value={currentProject}
                onChange={classifyProject}
              />
            </Grid>
            <Grid
              xs={4}
              style={{
                paddingLeft: '10px',
                maxWidth: 200,
                marginLeft: 10,
                padding: '10px 0px',
              }}
            >
              <FilterByLabourContractorComponent
                value={LabourContractor}
                onChange={() => {}}
                setInitialUser={(user) => setLabourContractor(user)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const statusCell = (item) => {
    return (
      <Grid
        container
        justify="space-between"
        style={{
          maxWidth: '100px',
          backgroundColor: 'rgba(99,99,99, 0.5)',
          borderRadius: '10px',
          position: 'absolute',
          top: '10px',
          right: '10px',
          color: '#fff',
          height: '35px',
        }}
      >
        <Grid
          style={{
            width: '67%',
            textAlign: 'right',
            padding: '3px 0',
            paddingRight: '5px',
          }}
          container
          alignItems="center"
        >
          <p
            style={{
              fontSize: '10px',
              fontWeight: 300,
              width: '100%',
            }}
          >
            STATUS
          </p>
          <p
            style={{
              fontSize: '12px',
              width: '100%',
            }}
          >
            {item?.action === '' || !item?.action
              ? 'N/A'
              : item.action === 'MarkAsResolved'
                ? 'Resolved'
                : item.action === 'ReworkRequired'
                  ? 'Rework'
                  : item.action === 'DecisionPending' && 'Pending'}
          </p>
        </Grid>
        <Grid
          container
          style={{
            backgroundColor: '#f5f1fdff',
            // height: "42px",
            // width: "120px",
            position: 'relative',
            // border: "1px solid red",
            borderRadius: '10px',
            width: '30%',
          }}
          alignItems="center"
          justifyContent="center"
        >
          {item.action === 'MarkAsResolved' ? (
            <CheckCircleOutlinedIcon
              style={{
                color: '#986FEF',
                fontSize: '26px',
              }}
            />
          ) : item.action === 'ReworkRequired' ? (
            <RestoreIcon
              style={{
                color: '#986FEF',
                fontSize: '26px',
              }}
            />
          ) : item.action === 'DecisionPending' ? (
            <TimerIcon
              style={{
                color: '#986FEF',
                fontSize: '26px',
              }}
            />
          ) : (
            <ErrorOutlineOutlinedIcon
              style={{
                color: 'red',
                fontSize: '26px',
              }}
            />
          )}
        </Grid>
      </Grid>
    );
  };

  const renderActionMenu = (item, index) => {
    return (
      <Menu
        disabled={true}
        id="simple-menu"
        anchorEl={anchorEl[index]}
        keepMounted
        open={Boolean(anchorEl[index])}
        onClose={() => handleClose(index)}
      >
        <MenuItem
          onClick={() => {
            markFieldNote(index, item.fieldNoteId, 'MarkAsResolved');
          }}
          style={{
            backgroundColor: '#a0d558',
          }}
        >
          <Grid container alignItems="center">
            <CheckCircleIcon className={classes.whiteColor} />
            <span
              style={{
                paddingLeft: '10px',
              }}
              className={classes.whiteColor}
            >
              Resolved
            </span>
          </Grid>
        </MenuItem>
        <MenuItem
          onClick={() =>
            markFieldNote(
              index,

              item.fieldNoteId,
              'ReworkRequired',
            )
          }
          // style={{ width: "210px" }}
          style={{
            backgroundColor: 'rgba(161, 120, 242, 0.20)',
          }}
        >
          <Grid
            container
            alignItems="center"
            // justify="space-between"
          >
            <RestoreIcon className={classes.purpleColor} />

            <span
              style={{ paddingLeft: '10px' }}
              className={classes.purpleColor}
            >
              Rework
            </span>
          </Grid>
        </MenuItem>
        <MenuItem
          onClick={() =>
            markFieldNote(
              index,

              item.fieldNoteId,
              'DecisionPending',
            )
          }
          style={{
            backgroundColor: '#687378',
          }}
          // style={{ width: "210px" }}
        >
          <Grid
            container
            alignItems="center"
            // justify="space-between"
          >
            <TimerIcon className={classes.whiteColor} />

            <span
              style={{ paddingLeft: '10px' }}
              className={classes.whiteColor}
            >
              Pending
            </span>
          </Grid>
        </MenuItem>
      </Menu>
    );
  };

  const locationCell = (item, index) => {
    return (
      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{
          padding: '0 1rem',
          position: 'absolute',
          bottom: '20px',
          zIndex: 100,
        }}
      >
        <Grid
          item
          container
          style={{
            color: '#e0e1e1ff',
          }}
          alignItems="center"
          xs={4}
        >
          <Grid item>
            <RoomOutlinedIcon style={{ fontSize: '30px' }} />
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle1"
              align="left"
              style={{
                fontSize: '10px',
                textShadow: '2px 2px 4px #000000',
              }}
            >
              LOCATION
            </Typography>
            <Typography
              style={{
                textShadow: '2px 2px 4px #000000',
                fontSize: '14px',
              }}
            >
              {item?.location ?? 'N/A'}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={4} container style={{ justifyContent: 'end' }}>
          <Button
            style={{
              background: '#986FEF',
              color: 'white',
              borderRadius: 10,
              fontSize: 15,
              textTransform: 'capitalize',
            }}
            endIcon={<ExpandMore />}
            onClick={(event) => handleClick(event, index)}
          >
            Action
          </Button>
          {renderActionMenu(item, index)}
        </Grid>
      </Grid>
    );
  };

  const dateTime = (item) => {
    return (
      <Typography style={{ fontSize: 11 }}>
        {item?.dateTime ? moment(item?.dateTime).format('h:mma dddd') : 'N/A'} |{' '}
        {item?.dateTime ? moment(item?.dateTime).format('D MMM, YYYY') : 'N/A'}{' '}
      </Typography>
    );
  };

  const renderContent = () => {
    if (isLoading) return <CircularProgress style={{ margin: 100 }} />;
    return renderCarousal();
  };

  const renderCarousal = () => {
    if (items.length === 0) {
      return (
        <Typography variant="h4" style={{ padding: 100 }}>
          Data not available.
        </Typography>
      );
    }
    const sliderWidth = items.length > 2 ? '150%' : '100%';
    return (
      <Grid style={{ width: sliderWidth, padding: '10px 0px' }}>
        <div className="slider-container" style={{ padding: '10px 0px' }}>
          <Slider {...settings}>
            {items.map((item, index) => (
              <div key={index} style={{ margin: '0px 10px' }}>
                <Card
                  elevation={0}
                  style={{
                    height: 490,
                    // width: 470,
                    // marginLeft: '35%',
                    margin: '0px 10px',
                    boxShadow: '0px 3px 10px rgba(0,0,0,0.4)',
                  }}
                >
                  <CardActionArea style={{ position: 'relative' }}>
                    <CardMedia
                      component="img"
                      alt="Contemplative Reptile"
                      height="350px"
                      image={item.thumbnail}
                      style={{ objectFit: 'cover' }}
                      title="Contemplative Reptile"
                    />
                    {statusCell(item)}
                    {locationCell(item, index)}
                  </CardActionArea>
                  <CardActions>
                    <Grid
                      container
                      justifyContent="center"
                      style={{ padding: 10 }}
                    >
                      <Grid item xs={12} style={{ marginTop: 10 }}>
                        {dateTime(item)}
                        <Typography
                          style={{
                            fontWeight: 400,
                          }}
                        >
                          {item?.description &&
                          item?.description?.split(' ')?.length > 15
                            ? item?.description
                                ?.split(' ')
                                ?.slice(0, 15)
                                .join(' ') + '...'
                            : (item?.description ?? 'N/A')}
                        </Typography>
                      </Grid>
                      <Grid container style={{ marginTop: 10 }}>
                        <Grid item xs={5}>
                          <Typography
                            gutterBottom
                            style={{ fontSize: 11, color: 'rgba(1,1,1,0.9)' }}
                            variant="h5"
                            component="h2"
                          >
                            Project
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            align="left"
                            style={{
                              fontSize: '15px',
                              fontWeight: 400,
                            }}
                          >
                            {item?.project?.name ?? 'N/A'}
                          </Typography>
                        </Grid>
                        <Grid item container xs={2} justifyContent="center">
                          <div
                            style={{
                              height: '80%',
                              width: 1,
                              background: 'rgba(1,1,1,0.1)',
                            }}
                          ></div>
                        </Grid>
                        <Grid item xs={5}>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                            style={{ fontSize: 11, color: 'rgba(1,1,1,0.9)' }}
                          >
                            Assigned to
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            align="left"
                            style={{
                              fontSize: '15px',
                              fontWeight: 400,
                            }}
                          >
                            {item?.contractor?.fullName ?? 'N/A'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              </div>
            ))}
          </Slider>
          <style jsx>{`
            .custom-arrow {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              z-index: 2;
              padding: 0;
            }
            .next-arrow {
              right: 25px;
            }
            .prev-arrow {
              left: 25px;
            }
            .custom-arrow::before,
            .custom-arrow::after {
              content: none;
            }
            .slick-prev {
              left: 19% !important;
              z-index: 1;
            }
            .slick-next {
              right: 20% !important;
              z-index: 1;
            }
            .slick-slide.slick-center div {
            }
          `}</style>
        </div>
      </Grid>
    );
  };

  const settings = {
    infinite: items.length > 2 ? true : false,
    centerMode: true,
    slidesToShow: items.length > 2 ? 3 : 1,
    cetnerPadding: 60,
    speed: 500,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };
  return (
    <Grid
      container
      xs={12}
      justifyContent="center"
      style={{ marginBottom: 30 }}
    >
      {renderFilters()}
      {renderContent()}
      {renderFileUploadModal()}
    </Grid>
  );
}

export default CenterMode;
