import React, { useState, useEffect, useCallback } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  TextareaAutosize,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import FilterProjectComponent from 'src/components/FilterProjectComponent';
import {
  getProjects,
  getProjectsClassificationList,
  projectReducer,
} from 'src/redux/projects';
import defaultProjectImage from '../../assests/Projects Icon.png';
import FilterByLabourContractorComponent from 'src/components/FilterByLaourContractorComponent';
import { getContractors, usersReducer } from 'src/redux/users';
import SortSelection from 'src/components/SortSelection';
import {
  approveBOQMeasurementReason,
  closeCostCode,
  getBOQListGC,
  getBOQProgress,
  getScopeList,
  productivityReducer,
  rejectBOQProgress,
  selectProductivityParentProject,
  verifyBOQProgress,
} from 'src/redux/productivity';
// import DatePicker, { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
// import EventNoteIcon from "@material-ui/icons/EventNote";
import SearchInput from 'src/components/SearchInput';
import Page from 'src/components/Page';
import toast, { Toaster } from 'react-hot-toast';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// registerLocale("es", es);
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import { breakString } from 'src/App';
import { getNewProductivity } from '.';
// import { Link } from "react-router-dom";
import { debounce } from 'lodash';
import SearchIcon from '@material-ui/icons/Search';
import { Tooltip } from 'antd';
import CloseIcon from '@material-ui/icons/Close';
import Quality from '../../assests/quantityImageForModal.png';
import Measurement from '../../assests/measurementImageForModal.png';
import DoneIcon from '@material-ui/icons/Done';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SOWAccordian1 from './SOWAccordian1';
import { ExpandLess as ExpandLessIcon } from '@material-ui/icons';
import useAccessPerView from 'src/common/Access';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    // paddingTop: theme.spacing(1),
  },
  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    textTransform: 'capitalize',
    marginBottom: '5px',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonDisabled: {
    backgroundColor: 'grey',
    textTransform: 'capitalize',
    marginBottom: '5px',
    '&.Mui-disabled': {
      background: '#c0c0c0',
      color: '#fff',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonRed: {
    color: theme.palette.common.white,
    backgroundColor: 'tomato',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'red',
    },
  },
  buttonPurpleOpacity: {
    color: '#986FEF',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    borderRadius: '10px',
    textTransform: 'capitalize',
    padding: '5px 15px',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  marginLeft: { marginLeft: '20px' },
  paddingLeft: {
    paddingLeft: '20px',
  },
  columnStyle: {
    fontSize: '11px',
    color: '#ADBAC3',
  },
  progressBar: {
    borderRadius: 10, // Adjust the value as per your preference
    backgroundColor: 'hsl(210,20.45%,82.75%)', // Change to any color you want
    '& .MuiLinearProgress-bar': {
      backgroundColor: '#81B734', // Change to any color you want
    },
  },

  [theme.breakpoints.down('sm')]: {
    marginLeft: { marginLeft: '0px' },
    paddingLeft: {
      paddingLeft: '0px',
    },
  },
}));
const Row = (props) => {
  const { classes, index, item, handleEdit } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  console.log('Row-props', props);

  const renderIcon = () => {
    // if (item.descriptions.length <= 1) {
    //   return <></>;
    // }
    const toggleExpand = () => setIsExpanded((prev) => !prev);
    if (isExpanded) {
      return (
        <IconButton onClick={toggleExpand}>
          <ExpandLessIcon color="black" />
        </IconButton>
      );
    }
    return (
      <IconButton onClick={toggleExpand}>
        <ExpandMoreIcon color="inherit" />
      </IconButton>
    );
  };

  const renderACONumber = () => {
    return (
      <Grid
        item
        container
        alignItems="center"
        style={{ flexWrap: 'nowrap', color: '#B2BEC7' }}
      >
        <Typography
          style={{ textWrap: 'nowrap', color: 'inherit', fontSize: 11 }}
        >
          {`AC${index}`}
        </Typography>
        {renderIcon()}
      </Grid>
    );
  };

  const renderTitle = () => {
    return (
      <Grid
        item
        container
        alignItems="center"
        style={{ flexWrap: 'nowrap', color: '#B2BEC7' }}
      >
        <DynamicCell value={item.title} />
        {renderIcon()}
      </Grid>
    );
  };

  const getValueInLakhsString = (value) => {
    const totalCostInLakhs = value / 100000;

    const formattedTotalCost = totalCostInLakhs.toFixed(1);

    const resultString = `₹ ${formattedTotalCost} Lakhs`;
    return resultString;
  };

  const renderRow = () => {
    return (
      <TableRow>
        <TableCell align="center">{renderACONumber()}</TableCell>
        <TableCell style={{ fontSize: 11 }}>{renderTitle()}</TableCell>
        <TableCell align="center" style={{ fontSize: 11 }}>
          <Grid
            item
            container
            alignItems="center"
            style={{ flexWrap: 'nowrap', color: '#B2BEC7' }}
          >
            <DynamicCell value={item.descriptions[0].description} />
          </Grid>
        </TableCell>
        <TableCell align="center" style={{ textWrap: 'nowrap', fontSize: 11 }}>
          {item.descriptions[0].unitCode}
        </TableCell>
        <TableCell align="center" style={{ textWrap: 'nowrap', fontSize: 11 }}>
          {item.descriptions[0].quantity}
        </TableCell>
        <TableCell align="center" style={{ textWrap: 'nowrap', fontSize: 11 }}>
          {`₹ ${item.descriptions[0].rate}`}
        </TableCell>
        <TableCell align="center" style={{ textWrap: 'nowrap', fontSize: 11 }}>
          {`${getValueInLakhsString(item.descriptions[0].amount)}`}
        </TableCell>
        <TableCell align="center" style={{ textWrap: 'nowrap', fontSize: 11 }}>
          {item.descriptions[0].actualQuantity}
        </TableCell>
        <TableCell align="center" style={{ textWrap: 'nowrap', fontSize: 11 }}>
          {`${getValueInLakhsString(item.descriptions[0].actualAmount)}`}
        </TableCell>
        <TableCell align="center" style={{ textWrap: 'nowrap', fontSize: 11 }}>
          {`${item.descriptions[0].percentage.toFixed(1)}%`}
        </TableCell>
        <TableCell style={{ textWrap: 'nowrap', fontSize: 11 }} align="center">
          <Button
            onClick={() => handleEdit(item)}
            className={classes.buttonPurpleOpacity}
          >
            Change Request
          </Button>
        </TableCell>
      </TableRow>
    );
  };

  const renderSubRows = (description) => {
    const height = isExpanded ? 40 : 0;
    return (
      <TableRow
        style={{
          transition: 'height 0.3s ease',
          height,
          visibility: isExpanded ? 'visible' : 'collapse',
        }}
      >
        <TableCell />
        <TableCell />
        <TableCell
          align="center"
          style={{
            fontSize: 11,
          }}
        >
          <Grid
            item
            container
            alignItems="center"
            style={{ flexWrap: 'nowrap', color: '#B2BEC7' }}
          >
            <DynamicCell value={description.description} />
          </Grid>
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 11,
          }}
        >
          {description.unitCode}
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 11,
          }}
        >
          {description.quantity}
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 11,
          }}
        >
          {`₹ ${description.rate}`}
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 11,
          }}
        >
          {`${getValueInLakhsString(description.amount)}`}
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 11,
          }}
        >
          {description.actualQuantity}
        </TableCell>
        <TableCell
          align="center"
          style={{
            textWrap: 'nowrap',
            fontSize: 11,
          }}
        >
          {`${getValueInLakhsString(description.actualAmount)}`}
        </TableCell>
        <TableCell align="center" style={{ textWrap: 'nowrap', fontSize: 11 }}>
          {`${description.percentage.toFixed(1)}%`}
        </TableCell>
        <TableCell style={{ textWrap: 'nowrap', fontSize: 11 }} align="center">
          <Button className={classes.buttonPurpleOpacity}>
            Change Request
          </Button>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      {renderRow()}
      {item.descriptions
        .slice(1)
        .map((description) => renderSubRows(description))}
    </>
  );
};

const DynamicCell = (props) => {
  const { value } = props;
  const [showMoreEnabled, setShowMoreEnabled] = useState(false);

  const renderActionLabel = () => {
    if (showMoreEnabled) {
      return 'show less';
    }
    return 'show more';
  };

  const handleToggle = () => {
    setShowMoreEnabled((prev) => !prev);
  };

  const renderValue = () => {
    if (showMoreEnabled) {
      return value;
    }
    return value?.split(' ')?.slice(0, 3).join(' ');
  };

  return (
    <Grid
      item
      container
      alignItems="center"
      justify={showMoreEnabled ? 'space-between' : 'left'}
      style={{ gap: 5, flexWrap: 'nowrap' }}
    >
      <Typography
        style={{ fontSize: 11, overflow: 'auto', maxHeight: 50 }}
        align="left"
      >
        {renderValue()}
      </Typography>
      <small
        style={{ color: 'blue', cursor: 'pointer', textWrap: 'nowrap' }}
        onClick={handleToggle}
      >
        {value.split(' ').length > 4 && renderActionLabel()}
      </small>
    </Grid>
  );
};

const TABLE_COLUMNS = [
  { label: 'WORK ORDER NO', width: '5%' },
  { label: 'COST CODE', width: '5%' },
  { label: 'TITLE', width: '5%' },
  { label: 'DESCRIPTION', width: '5%' },
  { label: 'UNIT (UOM)', width: '5%' },
  { label: 'QUANTITY', width: '5%' },
  { label: 'PROGRESS', width: '5%' },
  { label: 'conditionalRender', width: '10%' },
  { label: 'FIELD NOTE', width: '5%' },
];
const noWrapColumns = ['WORK ORDER NO', 'COMPLETION %'];

const ProductivityListView = ({
  setGCListView,
  hideActions = false,
  openTheFieldNote,
  filter,
  isOpenLastModal,
  setIsOpenLastModal,
  actionModal,
  setActionModal,
  currentTab,
  setCurrentTab,
  setRejectStuff,
  fetchParentPageData,
}) => {
  const [classifiedProject] = useState('');
  const [LabourContractor, setLabourContractor] = useState(0);
  const [filters, setFilters] = useState({
    search: '',
    sortBy: 0,
    filterBy: 0,
    searchModal: '',
    filterModalBy: 0,
  });

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [filteredArray, setFilteredArray] = useState([]);
  const [boqList, setBoqList] = useState(null);
  const [currentProject, setCurrentProject] = useState('');
  const [selectedList, setSelectedList] = useState(0);
  const [item, setItem] = useState(null);
  const [showFIleNoteButton, setShowFIleNoteButton] = useState(false);
  const [rejectScreen, setRejectScreen] = useState(false);
  const [rejectScreenMeasurement, setRejectScreenMeasurement] = useState(false);

  const [selectedValue, setSelectedValue] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);
  const [curSOWExpanded, setCurSOWExpanded] = useState(-1);
  const [rejectedQuantity, setRejectedQuantity] = useState(0);
  const [calculations, setCalculations] = useState({
    percentage: { inProcess: true, value: 0 },
    amount: { inProcess: true, value: 0 },
  });

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  //! INSTANCES
  const classes = useStyles();
  let dispatch = useDispatch();

  //! SELECTORS
  const { projectClassificationList } = useSelector(projectReducer);
  const {
    scopeList,
    boqListGC,
    loading,
    financialGraphLoading,
    productivitySelectedProject,
  } = useSelector(productivityReducer);
  const { contractorList } = useSelector(usersReducer);
  //! LIFECYCLE

  const getGrandTotalAmount = (boqList) => {
    return boqList?.reduce((grandTotal, item) => {
      const totalAmountForItem = item.dtoboqList.reduce(
        (totalBoqAmount, subItem) => {
          const totalAmountForSubItem = subItem.titles.reduce(
            (totalTitleAmount, title) => {
              const totalAmountForTitle = title.descriptions.reduce(
                (total, current) => total + current.amount,
                0
              );
              return totalTitleAmount + totalAmountForTitle;
            },
            0
          );
          return totalBoqAmount + totalAmountForSubItem;
        },
        0
      );
      return grandTotal + totalAmountForItem;
    }, 0);
  };
  const getGrandTotalPercentage = (boqList) => {
    let weightedPercentageSum = 0;
    let totalAmount = 0;

    boqList?.forEach((item) => {
      item.dtoboqList.forEach((subItem) => {
        subItem.titles.forEach((title) => {
          title.descriptions.forEach((description) => {
            if (description.amount > 0) {
              weightedPercentageSum +=
                (description.actualAmount / description.amount) *
                description.amount;
              totalAmount += description.amount;
            }
          });
        });
      });
    });

    const overallPercentage = (weightedPercentageSum / totalAmount) * 100;
    return overallPercentage;
  };
  useEffect(() => {
    const calculateBoqGrandTotal = () => {
      setCalculations((prev) => ({
        amount: { value: 0, inProcess: true },
        percentage: { value: 0, inProcess: true },
      }));
      const totalActualAmount = getGrandTotalAmount(boqListGC);
      setCalculations((prev) => ({
        ...prev,
        amount: { value: totalActualAmount, inProcess: false },
      }));
      const totalPercentage = getGrandTotalPercentage(boqListGC);
      setCalculations((prev) => ({
        ...prev,
        percentage: { value: totalPercentage, inProcess: false },
      }));
    };
    if (boqListGC?.length > 0) {
      setBoqList(boqListGC.map((item) => ({ ...item, showMore: false })));
      calculateBoqGrandTotal();
    }
  }, [boqListGC]);
  useEffect(() => {
    dispatch(getProjects());
    if (projectClassificationList.length === 0) {
      dispatch(getProjectsClassificationList());
    }
    if (projectClassificationList.length > 0) {
      if (!contractorList)
        dispatch(getContractors(projectClassificationList[0]?.projectId));
      setCurrentProject(
        productivitySelectedProject ?? projectClassificationList[0]
      );
      if (!scopeList) dispatch(getScopeList());
      dispatch(
        getBOQListGC(
          productivitySelectedProject?.projectId ??
            projectClassificationList[0]?.projectId,
          0,
          1,
          50,
          filter,
          filter ? null : false
        )
      );
    }
  }, [projectClassificationList]);

  useEffect(() => {
    if (boqListGC.length > 0) {
      setBoqList(boqListGC); //
    }
  }, [boqListGC]);
  //! METHODS

  const classifyProject = (project) => {
    if (project) {
      setCurrentProject(project);
      dispatch(getContractors(project?.projectId));
      setLabourContractor(0);
      dispatch(getBOQListGC(project?.projectId));
      dispatch(selectProductivityParentProject(project));
    } else {
      setCurrentProject(projectClassificationList[0]);
      dispatch(selectProductivityParentProject(projectClassificationList[0]));

      dispatch(getContractors(projectClassificationList[0]?.projectId));
      setLabourContractor(0);
      dispatch(getBOQListGC(projectClassificationList[0]?.projectId));
    }
  };
  const handleLabourContractor = (val) => {
    if (!val) {
      setLabourContractor(0);
      return dispatch(getBOQListGC(currentProject?.projectId, 0));
    }
    setLabourContractor(val);
    dispatch(getBOQListGC(currentProject?.projectId, val.userId));
  };

  //! FILTERS

  const handleDateRange = (update) => {
    let [start, end] = update;
    setDateRange(update);
    const dynamicFieldNoteList =
      filteredArray?.length > 0 ? filteredArray : boqListGC;

    if (end) {
      let newData = dynamicFieldNoteList.filter((filedNote) => {
        const dateTime = new Date(filedNote.dateTime).setHours(0, 0, 0, 0);

        if (dateTime >= start.getTime() && dateTime <= end.getTime()) {
          return filedNote;
        }
      });

      if (newData?.length === 0) {
        return setFilteredArray(null);
      } else {
        return setFilteredArray(newData);
      }
    }
    if (!start && !end) {
      if (filters.sortBy !== 0) {
        // return handleFilters({ target: { value: filters.sortBy } });
        return setFilteredArray([]);
      }
      return setFilteredArray([]);
    }
  };

  const handleFilters = (e) => {
    const { value } = e.target;

    if (parseInt(value) === 0) {
      setFilters({ ...filters, sortBy: value });

      return setFilteredArray([]);
    }

    setFilters({ ...filters, sortBy: value });
    if (
      boqListGC
        .filter(
          (item) =>
            item.isMeasurementApproved === false &&
            item.isQualityApproved === false
        )
        ?.filter((item) => item.scopeOfWorkId === parseInt(value)).length === 0
    )
      return setFilteredArray(null);

    setFilteredArray(
      boqListGC
        .filter(
          (item) =>
            item.isMeasurementApproved === false &&
            item.isQualityApproved === false
        )
        .filter((item) => item.scopeOfWorkId === parseInt(value))
    );
  };

  const debouncedSearch = debounce((value) => {
    dispatch(
      getBOQListGC(
        productivitySelectedProject?.projectId ??
          projectClassificationList[0]?.projectId,
        0,
        1,
        50,
        filter,
        filter ? null : false,
        value
      )
    );
  }, 2000);

  const search = useCallback(
    (e) => {
      const { value } = e.target;
      setFilters({ ...filters, search: value });

      // Call the debounced function with the input value
      debouncedSearch(value);
    },
    [debouncedSearch, filters]
  );

  const handleRemarkChange = (value, id, setData, data) => {
    setData(
      data?.map((item) =>
        item.boqId === id ? { ...item, remark: value } : item
      )
    );
  };

  // const updateToggleSwitch = (id, setData, data, booleanValue) => {
  //   setData(
  //     data.map((item) =>
  //       item.boqId === id ? { ...item, checked: booleanValue } : item
  //     )
  //   );
  // };

  const getValueInLakhsString = (value) => {
    const totalCostInLakhs = value / 100000;

    const formattedTotalCost = totalCostInLakhs.toFixed(1);

    const resultString = `₹. ${formattedTotalCost} Lakhs`;
    return resultString;
  };
  const sendProgressVerification = async (
    boqProgressId,
    boqVerificationType
  ) => {
    let toastId = toast(
      <div
        style={
          {
            // background: '#777',
            // color: '#fff',
          }
        }
      >
        <Typography>Are you sure ?</Typography>
        <Grid container justify="flex-end" style={{ marginTop: '10px' }}>
          <Button
            style={{
              color: '#fff',
              backgroundColor: 'green',
              textTransform: 'capitalize',
            }}
            onClick={async () => {
              toast.dismiss(toastId);

              let resp = await dispatch(
                verifyBOQProgress({ boqProgressId, boqVerificationType })
              );
              if (resp.status === 200) {
                console.log('boqVerificationType', boqVerificationType);
                if (currentTab === 0) setCurrentTab(1);

                toast.success(boqVerificationType + ' Approved successfully!');
                if (currentTab === 1) {
                  dispatch(
                    getBOQListGC(
                      currentProject?.projectId,
                      LabourContractor?.userId
                    )
                  );
                  dispatch(getBOQProgress(currentProject?.projectId));
                  setActionModal(false);
                  setIsOpenLastModal(false);
                  setCurrentTab(0);
                }
              } else {
                toast.success('Something went wrong!');
              }
            }}
          >
            Yes
          </Button>
          <Button
            style={{
              color: '#fff',
              backgroundColor: 'tomato',
              marginLeft: '10px',
              textTransform: 'capitalize',
            }}
            onClick={() => {
              toast.dismiss(toastId);
            }}
          >
            cancel
          </Button>
        </Grid>
      </div>
    );
  };
  const moveBack = () => {
    setGCListView(false);
    getNewProductivity();
    fetchParentPageData(productivitySelectedProject);
  };
  const openPreviousModal = () => {
    setActionModal(true);
  };

  const rejectProject = async (
    value,
    boqProgressId,
    boqVerificationType,
    item
  ) => {
    let toastId = toast(
      <div>
        <Typography>Are you sure ?</Typography>
        <Grid container justify="flex-end" style={{ marginTop: '10px' }}>
          <Button
            style={{
              color: '#fff',
              backgroundColor: 'green',
              textTransform: 'capitalize',
            }}
            onClick={async () => {
              toast.dismiss(toastId);
              setShowFIleNoteButton(true);

              if (boqVerificationType === 'Quality') {
                openTheFieldNote(
                  {
                    ...item,
                    userRejectedValue: rejectedQuantity,
                    rejectObject: {
                      boqProgressId,
                      [boqVerificationType === 'Quality'
                        ? 'qualityRejectStatus'
                        : 'measurementRejectStatus']: value,
                      boqVerificationType,
                      rejectedQuantity: parseInt(rejectedQuantity),
                      currentProject: currentProject?.projectId,
                      currentLabourContractor: LabourContractor?.userId,
                    },
                  },
                  openPreviousModal
                );
                setActionModal(false);
              }
              if (boqVerificationType === 'Measurement') {
                setIsOpenLastModal(false);
                let rejectResp = await dispatch(
                  rejectBOQProgress({
                    boqProgressId,
                    [boqVerificationType === 'Quality'
                      ? 'boqQualityRejectStatus'
                      : 'boqMeasurementRejectStatus']: value,
                    boqVerificationType,
                    rejectedQuantity: parseInt(rejectedQuantity),
                  })
                );
                if (rejectResp.status === 400) {
                  toast.error(rejectResp.data.title);
                } else if (rejectResp.status === 200) {
                  await dispatch(
                    getBOQListGC(
                      currentProject?.projectId,
                      LabourContractor?.userId
                    )
                  );
                  setCurrentTab(1);
                }
              }
              // }
              // setCurrentTab(1);
              setRejectScreenMeasurement(false);
              setItem({
                ...item,
                [boqVerificationType === 'Quality'
                  ? 'boqQualityRejectStatus'
                  : 'boqMeasurementRejectStatus']: value,
              });

              setRejectedQuantity(0);

              // }
              // dispatch(getBOQProgress(currentProject?.projectId));
              // // setActionModal(false);

              // // let status = breakString(value);
              // toast.success(boqVerificationType + ' Rejected Successfully');
              // setRejectedQuantity(0);
              // }
            }}
          >
            Yes
          </Button>
          <Button
            style={{
              color: '#fff',
              backgroundColor: 'tomato',
              marginLeft: '10px',
              textTransform: 'capitalize',
            }}
            onClick={() => {
              toast.dismiss(toastId);
            }}
          >
            cancel
          </Button>
        </Grid>
      </div>
    );
  };
  const approveMeasureReason = async (value, boqProgressId) => {
    let resp = await dispatch(
      approveBOQMeasurementReason({
        boqProgressId,
        measurementApprovedStatus: value,
      })
    );

    if (resp.status === 400) {
      toast.error(resp.data.title);
    } else if (resp.status === 200) {
      dispatch(
        getBOQListGC(currentProject?.projectId, LabourContractor?.userId)
      );
      dispatch(getBOQProgress(currentProject?.projectId));

      let status = breakString(value);
      toast.success('Measurement Approved Successfully');
    }
  };

  const changeTab = (event, newValue) => {
    if (
      item?.isQualityApproved ||
      // item?.boqQualityRejectStatus ||
      showFIleNoteButton
    )
      setCurrentTab(newValue);
  };
  const openActionModal = (item) => {
    setItem(item);
    setActionModal(true);
  };

  const handleChange1 = () => {
    // handleSOWExpanded(isExpanded ? -1 : index);
    setIsExpanded((prev) => !prev);
  };

  const handleSOWExpanded = (sowIndex) => {
    setCurSOWExpanded(sowIndex);
  };

  const openEditProgress = (boq) => {
    //  handleEdit(boq);
    console.log('openEditProgress');
  };
  const SummaryPercentageBar = (props) => {
    const { percentage, billingCost, measurementLeft } = props;

    const animatePercentageValue = (div) => {
      if (div) {
        setTimeout(() => {
          div.style.maxWidth = `${100}%`;
        }, 300);
      }
    };

    function LinearProgressWithLabel(props) {
      return (
        <Box display="flex" alignItems="center">
          <Box width="100%" mr={1}>
            <LinearProgress
              variant="determinate"
              {...props}
              className={classes.progressBar}
              sx={{
                borderRadius: 10,
                '& .MuiLinearProgress-bar': {
                  bgcolor: 'red', // Change to any color you want
                },
              }}
            />
          </Box>
          <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary">{`${Math.round(
              props.value
            )}%`}</Typography>
          </Box>
        </Box>
      );
    }
    return (
      <Grid container>
        <Tooltip
          title={
            <Grid container direction="column">
              <Grid item>
                <span style={{ fontWeight: 900 }}>{measurementLeft} </span>
                qty left
              </Grid>
              <Grid item>
                <span style={{ fontWeight: 900 }}>₹{billingCost} Lakhs </span>
                Cost
              </Grid>
            </Grid>
          }
          placement="top"
        >
          <div style={{ width: '100%' }}>
            <LinearProgressWithLabel value={percentage} />
          </div>
        </Tooltip>
      </Grid>
    );
  };

  return (
    <Page
      className={classes.root}
      id="zoom-level"
      title="Productivity"
      style={{ zoom: '90%' }}
    >
      <Dialog
        open={actionModal || isOpenLastModal}
        onClose={() => {
          // if (currentTab === 0 && !rejectScreen) {
          setActionModal(false);
          setItem(null);
          setCurrentTab(0);
          setShowFIleNoteButton(false);
          setIsOpenLastModal(false);
          // }
        }}
        // style={{ zIndex: currentTsab === 0 ? 1000 : 0 }}
        keepMounted
        maxWidth="xl"
      >
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <Grid item container xs={6} direction="column">
              <Grid item container alignItems="center">
                {rejectScreen && (
                  <KeyboardBackspaceIcon
                    style={{ cursor: 'pointer', marginRight: '10px' }}
                    onClick={() => setRejectScreen(false)}
                  />
                )}{' '}
                <Grid item>
                  {' '}
                  {currentTab === 1 ? 'Quality' : 'Measurement'}
                </Grid>
              </Grid>
              {!rejectScreen && (
                <Grid
                  style={{ color: 'rgba(142, 153, 168, 1)', fontSize: '10px' }}
                >
                  Step - {currentTab + 1} of 2
                </Grid>
              )}
            </Grid>
            <Grid item>
              {/* {currentTab === 0 && !rejectScreen && ( */}
              <CloseIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setActionModal(false);
                  setItem(null);
                  setCurrentTab(0);
                  setShowFIleNoteButton(false);
                  setIsOpenLastModal(false);
                }}
              />
              {/* )} */}
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {currentTab === 0 ? (
            rejectScreenMeasurement ? (
              <Grid container direction="column">
                <Grid item xs={8}>
                  <Typography align="left">
                    Please select a reason given below to reject this progress!
                  </Typography>
                  <Typography align="left" style={{ padding: '10px 0' }}>
                    Total Quantity:{' '}
                    <b>
                      {' '}
                      {item?.quantity ?? 'N/A'} {item?.unitName}
                    </b>
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  style={{ paddingLeft: '2rem' }}
                >
                  <RadioGroup value={selectedValue} onChange={handleChange}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        value="IncorrectMeasurement"
                        control={<Radio />}
                        label="Incorrect Measurement"
                      />
                      {selectedValue === 'IncorrectMeasurement' && (
                        <TextField
                          type="number"
                          value={rejectedQuantity}
                          onChange={(e) => {
                            let value = e.target.value;
                            if (value <= item?.quantity) {
                              setRejectedQuantity(value);
                            } else {
                              alert(
                                "Quantity can't be greater " +
                                  item?.quantity +
                                  item.unitName
                              );
                            }
                          }}
                          placeholder="Please Enter Rejected Value"
                          InputProps={{ inputProps: { max: item?.quantity } }}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        value="VariationDisapproved"
                        control={<Radio />}
                        label="Variation Disapproved"
                      />
                      {selectedValue === 'VariationDisapproved' && (
                        <TextField
                          type="number"
                          value={rejectedQuantity}
                          onChange={(e) => {
                            let value = e.target.value;
                            if (value <= item?.quantity) {
                              setRejectedQuantity(value);
                            } else {
                              alert(
                                "Quantity can't be greater " +
                                  item?.quantity +
                                  item?.unitName
                              );
                            }
                          }}
                          placeholder="Please Enter Rejected Value"
                          InputProps={{ inputProps: { max: item?.quantity } }}
                        />
                      )}
                    </Grid>
                  </RadioGroup>
                </Grid>
                <Grid item container style={{ padding: '10px 2rem 2rem 2rem' }}>
                  <Button
                    disabled={selectedValue === ''}
                    className={classes.buttonGreen}
                    style={{
                      color: '#fff',
                      marginTop: '10px',
                      padding: '10px 0',
                      // width: '90%',
                    }}
                    endIcon={<NoteAddIcon />}
                    onClick={() => {
                      rejectProject(
                        selectedValue,
                        item?.contractorBOQProgressId,
                        'Measurement',
                        item
                      );
                    }}
                    size="small"
                    fullWidth
                  >
                    {financialGraphLoading
                      ? 'Loading...'
                      : 'Reject Measurement'}
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid container direction="column">
                <Grid
                  container
                  justify="center"
                  direction="column"
                  alignItems="center"
                >
                  <Grid item xs={3}>
                    <img src={Measurement} alt="Measurement" />
                  </Grid>
                  <Grid item xs={8}>
                    <Typography align="center" style={{ width: '100%' }}>
                      Please select preferred value of the Measurement Approval
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  style={{ marginTop: '20px' }}
                >
                  <Grid item>
                    <Button
                      fullWidth
                      size="small"
                      className={
                        item?.isMeasurementApproved
                          ? classes.buttonDisabled
                          : classes.buttonPurple
                      }
                      style={{
                        width: '140px',
                        padding: '8px 0',
                        marginRight: '10px',
                      }}
                      disabled={item?.isMeasurementApproved}
                      onClick={() => setRejectScreenMeasurement(true)}
                    >
                      Reject
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      className={
                        item?.isMeasurementApproved ||
                        item?.boqMeasurementRejectStatus
                          ? classes.buttonDisabled
                          : classes.buttonGreen
                      }
                      size="small"
                      fullWidth
                      onClick={() =>
                        sendProgressVerification(
                          item?.contractorBOQProgressId,
                          'Measurement'
                        )
                      }
                      disabled={
                        item?.isMeasurementApproved ||
                        item?.boqMeasurementRejectStatus
                      }
                      style={{ width: '140px', padding: '8px 0' }}
                    >
                      {loading || financialGraphLoading
                        ? 'Loading...'
                        : item?.isMeasurementApproved
                        ? 'Approved'
                        : 'Approve'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )
          ) : rejectScreen ? (
            <Grid container direction="column">
              <Grid item xs={8}>
                <Typography align="left">
                  Please select a reason given below to reject this progress!
                </Typography>
                <Typography align="left" style={{ padding: '10px 0' }}>
                  Total Quantity:{' '}
                  <b>
                    {item?.quantity ?? 'N/A'} {item?.unitName}
                  </b>
                </Typography>
              </Grid>
              <Grid
                item
                container
                direction="column"
                style={{ paddingLeft: '2rem' }}
              >
                <RadioGroup value={selectedValue} onChange={handleChange}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      value="PoorWorkmanship"
                      control={<Radio />}
                      label="Poor Workmanship"
                    />
                    {selectedValue === 'PoorWorkmanship' && (
                      <TextField
                        value={rejectedQuantity}
                        onChange={(e) => {
                          let value = e.target.value;
                          if (value <= item?.quantity) {
                            setRejectedQuantity(value);
                          } else {
                            alert(
                              "Quantity can't be greater " +
                                item?.quantity +
                                item?.unitName
                            );
                          }
                        }}
                        type="number"
                        placeholder="Please Enter Rejected Quantity"
                        InputProps={{ inputProps: { max: item?.quantity } }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      value="QualityDefects"
                      control={<Radio />}
                      label="Material Defects"
                    />
                    {selectedValue === 'QualityDefects' && (
                      <TextField
                        value={rejectedQuantity}
                        onChange={(e) => {
                          let value = e.target.value;
                          if (value <= item?.quantity) {
                            setRejectedQuantity(value);
                          } else {
                            alert(
                              "Quantity can't be greater " +
                                item?.quantity +
                                item?.unitName
                            );
                          }
                        }}
                        type="number"
                        placeholder="Please Enter Rejected Quantity"
                        InputProps={{ inputProps: { max: item?.quantity } }}
                      />
                    )}
                  </Grid>
                </RadioGroup>
              </Grid>
              <Grid item container style={{ padding: '10px 2rem 2rem 2rem' }}>
                {/* {item?.remainingQuantity > item?.quantity ? ( */}
                <Button
                  disabled={selectedValue === ''}
                  className={classes.buttonGreen}
                  style={{
                    color: '#fff',
                    marginTop: '10px',
                    padding: '10px 0',
                    // width: '90%',
                  }}
                  endIcon={<NoteAddIcon />}
                  onClick={() => {
                    rejectProject(
                      selectedValue,
                      item?.contractorBOQProgressId,
                      'Quality',
                      item
                    );
                  }}
                  size="small"
                  fullWidth
                >
                  {financialGraphLoading ? 'Loading...' : 'Add Field Note'}
                </Button>
                {/* ) : (
                    <Button
                      disabled={selectedValue === ''}
                      className={classes.buttonGreen}
                      style={{
                        color: '#fff',
                        marginTop: '10px',
                        padding: '10px 0',
                        // width: '90%',
                      }}
                      endIcon={<NoteAddIcon />}
                      onClick={() => {
                        rejectProject(
                          selectedValue,
                          item?.contractorBOQProgressId,
                          'Quality',
                          item
                        );
                      }}
                      size="small"
                      fullWidth
                    >
                      {financialGraphLoading ? 'Loading...' : 'Reject Quality'}
                    </Button> */}
                {/* )} */}
              </Grid>
            </Grid>
          ) : (
            <Grid container direction="column">
              <Grid
                container
                justify="center"
                direction="column"
                alignItems="center"
              >
                <Grid item xs={3}>
                  <img src={Quality} alt="quality" />
                </Grid>
                <Grid item xs={8}>
                  <Typography align="center" style={{ width: '100%' }}>
                    Please select preferred value of the Quality!
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                justify="center"
                alignItems="center"
                style={{ marginTop: '20px' }}
              >
                <Grid item>
                  <Button
                    fullWidth
                    size="small"
                    className={
                      item?.boqQualityRejectStatus
                        ? classes.buttonDisabled
                        : classes.buttonPurple
                    }
                    style={{
                      width: '140px',
                      padding: '8px 0',
                      marginRight: '10px',
                    }}
                    disabled={item?.boqQualityRejectStatus}
                    onClick={() => setRejectScreen(true)}
                  >
                    Reject
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    className={
                      item?.isQualityApproved || item?.boqQualityRejectStatus
                        ? classes.buttonDisabled
                        : classes.buttonGreen
                    }
                    size="small"
                    fullWidth
                    onClick={() =>
                      sendProgressVerification(
                        item?.contractorBOQProgressId,
                        'Quality'
                      )
                    }
                    disabled={
                      item?.isQualityApproved || item?.boqQualityRejectStatus
                    }
                    style={{ width: '140px', padding: '8px 0' }}
                  >
                    {loading || financialGraphLoading
                      ? 'Loading...'
                      : item?.isQualityApproved
                      ? 'Approved'
                      : 'Approve'}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </DialogContent>
      </Dialog>
      <div style={{ marginLeft: '10px' }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography
            color="inherit"
            to="/"
            onClick={moveBack}
            style={{ cursor: 'pointer' }}
          >
            productivity
          </Typography>
          <Typography color="inherit" style={{ cursor: 'pointer' }}>
            {filter === 'WorkmanshipCost'
              ? 'Poor Workmanship'
              : filter === 'MaterialDefect'
              ? 'Material Defects'
              : filter === 'Variations'
              ? 'Variations'
              : filter === 'changeRequest'
              ? 'Change Request'
              : 'Verify Progress'}
          </Typography>
        </Breadcrumbs>
      </div>
      <Container maxWidth={true}>
        <Button
          style={{ width: '20px', marginBottom: '10px' }}
          onClick={moveBack}
        >
          <ArrowBackIcon style={{ color: '#000' }} />
        </Button>
        <Grid
          container
          // maxWidth={false}
          style={{
            background: 'white',
            borderRadius: '10px',
            // padding: "1rem",
          }}
        >
          <Grid
            container
            item
            alignItems="center"
            style={{
              padding: '1rem',
            }}
          >
            <Grid item md={4} xs={12}>
              <Grid item container alignItems="center">
                <Grid item>
                  <Typography variant="subtitle">Select Project</Typography>
                </Grid>
                <Grid item>
                  <FilterProjectComponent
                    projectClassificationList={projectClassificationList}
                    src={
                      !classifiedProject[0]?.url
                        ? defaultProjectImage
                        : `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${classifiedProject[0]?.url}`
                    }
                    value={currentProject}
                    onChange={classifyProject}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={2} xs={12}>
              <FilterByLabourContractorComponent
                value={LabourContractor}
                onChange={handleLabourContractor}
                setInitialUser={(user) => setLabourContractor(user)}
                currentProject={projectClassificationList[0]}
              />
            </Grid>
            {filter === undefined && (
              <Grid container item xs={6} justify="flex-end">
                <Button
                  // className={classes.buttonPurpleOpacity}
                  style={{
                    marginRight: '10px',
                    textTransform: 'capitalize',
                    backgroundColor: selectedList === 0 ? '#81B734' : '',
                    color: selectedList === 0 ? '#fff' : '#111',
                  }}
                  variant="outlined"
                  onClick={async () => {
                    setSelectedList(0);
                    let resp = await dispatch(
                      getBOQListGC(
                        productivitySelectedProject?.projectId ??
                          projectClassificationList[0]?.projectId,
                        0,
                        1,
                        50,
                        filter,
                        false
                      )
                    );
                    if (resp.status === 200) {
                      setBoqList(resp.data.result?.sowList);
                    }
                  }}
                >
                  Default List
                </Button>

                <Button
                  // className={classes.buttonPurpleOpacity}
                  variant="outlined"
                  style={{
                    textTransform: 'capitalize',
                    backgroundColor: selectedList === 1 ? '#81B734' : '',
                    color: selectedList === 1 ? '#fff' : '#111',
                  }}
                  onClick={async () => {
                    setSelectedList(1);
                    let resp = await dispatch(
                      getBOQListGC(
                        productivitySelectedProject?.projectId ??
                          projectClassificationList[0]?.projectId,
                        0,
                        1,
                        50,
                        filter,
                        true
                      )
                    );
                    if (resp.status === 200) {
                      setBoqList(resp.data.result?.sowList);
                    }
                  }}
                >
                  History
                </Button>
              </Grid>
            )}
          </Grid>

          <Grid
            xs={12}
            container
            style={{ marginTop: '1rem' }}
            alignItems="center"
            className="input-container-nav"
            justify="space-between"
          >
            {/* container */}

            <Grid item container md={6} xs={12}>
              <Grid
                item
                md={5}
                xs={12}
                container
                alignItems="center"
                justify="space-between"
                // className='padding5'
                // style={{ padding: '2px' }}
                className={classes.marginTop}
              >
                <Grid item xs={1}>
                  <small>SOW: </small>
                </Grid>
                <Grid item md={10} xs={9}>
                  <SortSelection
                    initialValue="Scope Of Work"
                    value={filters.sortBy}
                    handleFilters={handleFilters}
                    data={scopeList?.map((sow) => ({
                      value: sow.scopeOfWorkId,
                      label: sow.name,
                    }))}
                  />
                </Grid>
              </Grid>

              {/* //! 2.DatePickerInput */}
              {/* <Grid
                item
                md={7}
                xs={12}
                container
                alignItems="center"
                // justify="space-between"
                style={{ zIndex: 10 }}
                className={classes.paddingLeft}
              >
                <Grid item style={{ marginRight: "10px" }}>
                  <small>Search Filters:</small>
                </Grid>
                <Grid
                  item
                  style={{
                    position: "relative",
                    // border: "1px solid red",
                  }}
                  xs={6}
                >
                  <DatePicker
                    dateFormat="MMM d, yy"
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                      handleDateRange(update);
                    }}
                    isClearable={true}
                    className="font12 date-picker "
                  />
                  {!startDate && (
                    <EventNoteIcon
                      style={{
                        position: "absolute",
                        right: "5px",
                        top: "7px",
                      }}
                    />
                  )}
                </Grid>
              </Grid> */}
            </Grid>

            <Grid
              md={5}
              xs={12}
              item
              container
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={2}>
                <small className="font14">Search:</small>
              </Grid>
              <Grid item md={10} xs={8} style={{ position: 'relative' }}>
                {/* {" "}
                <SearchInput
                  search={filters.search}
                  handleSearch={handleChange}
                /> */}
                <input
                  id="bootstrap-input"
                  type="search"
                  placeholder="Type here ..."
                  value={filters.search}
                  onChange={search}
                  className="input"
                />
                {!filters.search && (
                  <SearchIcon
                    style={{ position: 'absolute', right: '8px', top: '8px' }}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item container>
            {loading ? (
              'Loading...'
            ) : (
              <Grid item container>
                {boqListGC?.length === 0
                  ? 'No Data Found!'
                  : boqList?.map((sowItem, index) => {
                      return (
                        <SOWAccordian1
                          key={index + '12'}
                          hideActions={hideActions}
                          classes={classes}
                          sowItem={sowItem}
                          index={index}
                          scopeList={scopeList}
                          handleSOWExpanded={handleSOWExpanded}
                          expandedSOW={curSOWExpanded}
                          handleEdit={openEditProgress}
                          openActionModal={openActionModal}
                          selectedList={selectedList}
                          filter={filter}
                        />
                      );
                    })}
                <Grid container item justify="flex-end" alignItems="center">
                  {/* <TablePagination
                      component="div"
                      count={1000}
                      rowsPerPageOptions={[
                        5,
                        10,
                        20,
                        { label: 'All', value: boqList?.length },
                      ]}
                      rowsPerPage={paginationData?.rowsPerPage}
                      page={paginationData?.page}
                      colSpan={3}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    /> */}

                  <Grid
                    item
                    style={{ maxWidth: 260, padding: '10px 0px' }}
                    container
                    justify="flex-end"
                  >
                    <Grid item container xs={8}>
                      <Typography
                        style={{
                          fontSize: 18,
                          fontWeight: 600,
                          width: '100%',
                        }}
                      >
                        {boqListGC?.length === 0 ? (
                          ''
                        ) : calculations.amount.inProcess ? (
                          <>Loading ...</>
                        ) : (
                          getValueInLakhsString(calculations.amount.value)
                        )}
                      </Typography>
                      <Typography
                        style={{
                          color: '#ADBAC3',
                          fontSize: 12,
                        }}
                      >
                        Total Actual Amount
                      </Typography>
                    </Grid>
                    {/* <Grid item xs={6} container>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 600,
                            width: '100%',
                          }}
                        >
                          {calculations.percentage.inProcess ? (
                            <>Loading ...</>
                          ) : (
                            `${calculations.percentage.value.toFixed(2)}%`
                          )}
                        </Typography>
                        <Typography
                          style={{
                            color: '#ADBAC3',
                            fontSize: 10,
                          }}
                        >
                          % Percentage
                        </Typography>
                      </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default ProductivityListView;

//sandbox upto date
