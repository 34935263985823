import React, { useRef } from 'react';
import {
  Button,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SortSelection from '../SortSelection';
import moment from 'moment';
import SearchInput from '../SearchInput';

import { DateRangePicker } from 'react-date-range';
import EventNoteIcon from '@material-ui/icons/EventNote';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {
  attendanceReducer,
  getAttendance,
  getWorkerAttendance,
  selectAProject,
  selectWorker,
} from 'src/redux/attendance';
import { workersReducer } from 'src/redux/workers';
import { useNavigate } from 'react-router';
import { projectReducer } from 'src/redux/projects';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    color: '#986FEF',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    borderRadius: '10px',
    padding: '10px 1rem',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  headerCellText: {
    fontSize: 12,
    fontFamily: 'Roboto',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  textField: {
    // marginLeft: theme.spacing(1),
    paddingRight: '1.5rem',
    borderRadius: '10px',
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontWeight: 400,
  },
  input: {
    display: 'none',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  hover: {
    ':hover': {
      backgroundColor: '#A179F2',
    },
  },
  textFieldStyleHeading: {
    fontWeight: 400,
    fontFamily: 'Roboto',
    fontSize: '22px',
    color: '#fff',

    '&::placeholder': {
      fontWeight: 400,
      fontFamily: 'Roboto',
      fontSize: '15px',
      // color: 'rgba(126, 131, 134, 0.7)',
      // color: '#ddd',
    },
  },
  textFieldStyle1: {
    fontWeight: 400,
    fontFamily: 'Roboto',
    fontSize: '15px',
    color: '#fff',

    '&::placeholder': {
      fontWeight: 400,
      fontFamily: 'Roboto',
      fontSize: '15px',
      // color: 'rgba(126, 131, 134, 0.7)',
      // color: '#ddd',
    },
  },
  textFieldStyle: {
    fontWeight: 400,
    fontFamily: 'Roboto',
    fontSize: '15px',
    // color: '#fff',

    '&::placeholder': {
      fontWeight: 400,
      fontFamily: 'Roboto',
      // fontSize: '15px',
      // color: 'rgba(126, 131, 134, 0.7)',
      // color: '#fff',
    },
  },
  floatingLabelFocusStyle: {
    color: '#F9F9F9',
  },
  input1: {
    borderRadius: '10px',
    // backgroundColor: 'rgba(126, 131, 134, 0.1)',
    backgroundColor: 'transparent',
  },
  input: {
    borderRadius: '10px',
    backgroundColor: 'rgba(126, 131, 134, 0.1)',
    // backgroundColor: 'transparent',
  },
  profilePictureContainer: {
    paddingLeft: '1rem',
    paddingTop: '2rem',
    // position: 'absolute',
    // top: '50%',
  },
  greenCard: {
    backgroundColor: '#81B734',
    height: '260px',
    borderRadius: '11px',
    marginRight: '10px',
    // position: 'relative',
  },
  placeholderStyle: {
    '& .MuiFilledInput-root:hover': {
      backgroundColor: 'transparent',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
      '& .MuiFilledInput-root.Mui-focused': {
        backgroundColor: 'transparent',
      },
    },
  },
  hRow: {
    marginLeft: '25px',
    borderLeft: '1px solid rgba(200, 200, 200, 1)',
    padding: '10px 0',
    height: '40px',
  },
  skillAndAdharFieldContainer: {
    paddingLeft: '4rem',
    paddingTop: '4rem',
    // border: '2px solid red',
  },
  whiteFieldContainer: {
    paddingLeft: '1rem',
    paddingTop: '5rem',
  },
  headerCellText1: {
    color: 'rgba(173, 186, 195, 1)',
    fontSize: '12px',
  },
  [theme.breakpoints.down('sm')]: {
    greenCard: {
      backgroundColor: '#81B734',
      height: '550px',
      borderRadius: '11px',
      // marginRight: '10px',
      // position: 'relative',
    },
    hRow: {
      marginLeft: '15px',
      borderLeft: '1px solid #fff',
      padding: '10px 0',
      height: '30px',
    },
    skillAndAdharFieldContainer: {
      paddingLeft: '1rem',
      paddingTop: '1rem',
    },
    profilePictureContainer: {
      paddingLeft: '5.5rem',
      paddingTop: '1rem',
      // position: 'absolute',
      // top: '50%',
    },
    whiteFieldContainer: {
      paddingLeft: '0', //'5.5rem',
      paddingTop: '0 ', //'1rem',
    },
    adharCardFiledContainer: {
      paddingLeft: '5.5rem',
      paddingTop: '1rem',
    },
  },
}));
export default function WorkHistoryTable({
  workerHistoryList,
  skillsList,
  workerHistoryLoading,
}) {
  const [filteredArray, setFilteredArray] = useState([]);
  const [range, setRange] = useState([
    {
      // startDate: new Date(),
      // endDate: addDays(new Date(), 7),
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
      noValue: true,
    },
  ]);

  const [open, setOpen] = useState(false);
  const refOne = useRef(null);
  const [filters, setFilters] = useState({
    search: '',
    sortBy: 0,
    filterBy: 0,
    searchModal: '',
    filterModalBy: 0,
  });

  //! INSTANCES
  const classes = useStyles();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  //! SELCTORS
  const { selectedWorker, selectedProject } = useSelector(workersReducer);
  const { projectClassificationList } = useSelector(projectReducer);
  const { workersAttendanceList } = useSelector(attendanceReducer);

  //! METHODS
  const handleFilters = (e) => {
    const { value } = e.target;
  };
  const handleSearch = (e) => {
    const { value } = e.target;

    setFilters({
      ...filters,
      search: value,
    });

    if (!value) {
      return setFilteredArray([]);
    }
  };

  const handleDateRange = (update) => {
    let date = update[0];
  };

  const handleFiltersStatus = (e) => {
    const { value } = e.target;

    setFilters({ ...filters, filterBy: value });
    if (value == 0) return setFilteredArray([]);
  };

  const redirectToMusterRollPage = async (row) => {
    // return;
    //  dispatch(getAttendance(selectedProject ?  selectedProject.projectId :projectClassificationList[0]?.projectId));

    let resp = await dispatch(
      getWorkerAttendance(row?.jobId, workerHistoryList?.workerId, false)
    );
    if (resp?.status === 200) {
      dispatch(
        selectWorker({
          ...resp?.data,
          workerId: row?.workerId,
          sKillName: selectedWorker?.workerSkills[0]?.skill.name ?? 'N/A',
        })
      );
    }

    dispatch(
      selectAProject(
        selectedProject ? selectedProject : projectClassificationList[0]
      )
    );
    navigate('/app/attendance/detail');
  };

  return (
    <Grid container style={{ marginBottom: '1rem' }}>
      {' '}
      {/* //! Filter Container */}
      <Grid
        xs={12}
        container
        // spacing={3}
        // style={{ marginTop: '1rem' }}
        alignItems="center"
        className="input-container-nav"
        justify="space-between"
        // style={ { border: '2px solid green' } }
      >
        {/* container */}

        {/* //! 1.SortInput */}
        <Grid
          item
          md={3}
          xs={12}
          container
          alignItems="center"
          justify="space-between"
          // className='padding5'
          // style={{ padding: '2px' }}
          className={classes.marginTop}
        >
          <Grid item xs={2}>
            <small>View As: </small>
          </Grid>
          <Grid item md={10} xs={9}>
            <SortSelection
              initialValue="Skills"
              value={filters.sortBy}
              handleFilters={handleFilters}
              data={skillsList?.map((skill) => ({
                value: skill.name,
                label: skill.name,
              }))}
            />
          </Grid>
        </Grid>

        {/* //! 2.DatePickerInput */}
        <Grid
          item
          md={3}
          xs={12}
          container
          justify="space-between"
          alignItems="center"
          className={`${classes.marginRight} ${classes.marginTop}`}
          // style={ { border: '2px solid red' } }
          // className={ classes.marginTop }
        >
          <Grid item xs={3} className={classes.marginLeft}>
            <small>Search Filter:</small>
          </Grid>
          <Grid
            item
            // md={10}
            xs={9}
            style={{
              position: 'relative',
            }}
          >
            <div className="calendarWrap">
              <input
                value={
                  !range[0]?.noValue
                    ? `${moment(range[0]?.startDate).format(
                        'Do MMM'
                      )} - ${moment(range[0]?.endDate).format('Do MMM')}`
                    : ''
                }
                readOnly
                className="inputBox"
                // type='search'
                onClick={() => setOpen((open) => !open)}
              />
              {!range[0].noValue ? (
                <HighlightOffIcon
                  onClick={() => {
                    setRange([
                      {
                        startDate: new Date(),
                        endDate: new Date(),
                        key: 'selection',
                        noValue: true,
                      },
                    ]);
                    setFilteredArray([]);
                  }}
                  style={{
                    position: 'absolute',
                    top: '7px',
                    right: '5px',
                    cursor: 'pointer',
                  }}
                />
              ) : (
                <EventNoteIcon
                  onClick={() => setOpen((open) => !open)}
                  style={{
                    position: 'absolute',
                    top: '7px',
                    right: '5px',
                    cursor: 'pointer',
                  }}
                />
              )}

              <div ref={refOne}>
                {open && (
                  <DateRangePicker
                    onChange={(item) => {
                      setRange([{ ...item.selection, noValue: false }]);
                      handleDateRange([{ ...item.selection, noValue: false }]);
                    }}
                    // style={{ width: '100px' }}
                    editableDateInputs={true}
                    moveRangeOnFirstSelection={false}
                    ranges={range}
                    // months={2}
                    // direction='horizontal'
                    className="calendarElement"
                  />
                )}
              </div>
            </div>
          </Grid>
        </Grid>

        {/* //! 3.SearchInput */}
        <Grid
          item
          md={3}
          xs={12}
          container
          justify="space-between"
          alignItems="center"
          className={classes.marginTop}
        >
          <Grid item md={1} xs={3}>
            <small style={{ paddingRight: '15px' }}>Search:</small>
          </Grid>
          <Grid item xs={9} md={10} style={{ position: 'relative' }}>
            <SearchInput
              search={filters.search}
              handleSearch={handleSearch}
              // className='font12'
            />
          </Grid>
        </Grid>
      </Grid>
      {workerHistoryLoading ? (
        <Typography align="center">Fetching Details ... </Typography>
      ) : (
        <TableContainer style={{ marginTOp: '1rem' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  size="small"
                  align="center"
                  // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                  className={classes.headerCellText1}
                >
                  MUSTER ROLL NO
                </TableCell>{' '}
                <TableCell
                  size="small"
                  align="center"
                  // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                  className={classes.headerCellText1}
                >
                  JOB ID
                </TableCell>
                <TableCell
                  size="small"
                  align="center"
                  // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                  className={classes.headerCellText1}
                >
                  PROJECT NAME
                </TableCell>
                <TableCell
                  size="small"
                  align="center"
                  // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                  className={classes.headerCellText1}
                >
                  CONTRACTOR NAME
                </TableCell>
                <TableCell
                  size="small"
                  align="center"
                  // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                  className={classes.headerCellText1}
                >
                  TOTAL MAN DAY
                </TableCell>
                <TableCell
                  size="small"
                  align="center"
                  // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                  className={classes.headerCellText1}
                >
                  TOTAL PRESENT
                </TableCell>
                <TableCell
                  size="small"
                  align="center"
                  // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                  className={classes.headerCellText1}
                >
                  TOTAL ABSENT
                </TableCell>
                <TableCell
                  size="small"
                  align="center"
                  // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                  className={classes.headerCellText1}
                >
                  TOTAL OT
                </TableCell>
                <TableCell
                  size="small"
                  align="center"
                  // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                  className={classes.headerCellText1}
                >
                  START DATE
                </TableCell>
                <TableCell
                  size="small"
                  align="center"
                  // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                  className={classes.headerCellText1}
                >
                  END DATE
                </TableCell>
                <TableCell
                  size="small"
                  align="left"
                  // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                  className={classes.headerCellText1}
                >
                  ACTIONS
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {filteredArray?.length ? (
                filteredArray?.map((val, index) => (
                  <TableRow hover key={val?.jobId}>
                    <TableCell
                      align="center"
                      style={{
                        fontSize: 12,
                        fontWeight: 'bold',
                        fontFamily: 'Roboto',
                      }}
                    >
                      {'N/A'}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontSize: 12,
                        fontWeight: 'bold',
                        fontFamily: 'Roboto',
                      }}
                    >
                      {val?.job?.jobId ?? 'N/A'}
                    </TableCell>
                    <TableCell>{val?.job?.projectName ?? 'N/A'}</TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontSize: 12,
                        fontWeight: 'bold',
                        fontFamily: 'Roboto',
                      }}
                    >
                      {'N/A'}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontSize: 12,
                        fontWeight: 'bold',
                        fontFamily: 'Roboto',
                      }}
                    >
                      {val?.job?.manDays ?? 'N/A'}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontSize: 12,
                        fontWeight: 'bold',
                        fontFamily: 'Roboto',
                      }}
                    >
                      {'N/A'}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontSize: 12,
                        fontWeight: 'bold',
                        fontFamily: 'Roboto',
                      }}
                    >
                      {'N/A'}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontSize: 12,
                        fontWeight: 'bold',
                        fontFamily: 'Roboto',
                      }}
                    >
                      {'N/A'}
                    </TableCell>

                    <TableCell
                      align="left"
                      style={{ fontSize: 12, fontFamily: 'Roboto' }}
                    >
                      {val?.joiningDate
                        ? moment(val?.joiningDate).format('LL')
                        : 'N/A'}
                    </TableCell>

                    <TableCell
                      align="left"
                      style={{ fontSize: 12, fontFamily: 'Roboto' }}
                    >
                      {val?.endDate
                        ? moment().add(val?.endDate, 'days').format('LL')
                        : 'N/A'}
                    </TableCell>

                    <TableCell
                      align="left"
                      style={{ fontSize: 12, fontFamily: 'Roboto' }}
                    >
                      <Button
                        size="small"
                        style={{ textTransform: 'capitalize' }}
                        className={classes.buttonGreen}
                        onClick={() => redirectToMusterRollPage(val)}
                      >
                        View Muster Roll
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : workerHistoryList?.workerJobs?.length === 0 ||
                filters.search !== '' ||
                filteredArray === null ? (
                <TableRow>No Search Found!</TableRow>
              ) : (
                workerHistoryList?.workerJobs?.map((val, index) => (
                  <TableRow hover key={val?.jobId}>
                    <TableCell
                      align="center"
                      style={{
                        fontSize: 12,
                        fontWeight: 'bold',
                        fontFamily: 'Roboto',
                      }}
                    >
                      {'N/A'}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontSize: 12,
                        fontWeight: 'bold',
                        fontFamily: 'Roboto',
                      }}
                    >
                      {val?.job?.jobId ?? 'N/A'}
                    </TableCell>
                    <TableCell>{val?.job?.projectName ?? 'N/A'}</TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontSize: 12,
                        fontWeight: 'bold',
                        fontFamily: 'Roboto',
                      }}
                    >
                      {'N/A'}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontSize: 12,
                        fontWeight: 'bold',
                        fontFamily: 'Roboto',
                      }}
                    >
                      {val?.job?.manDays ?? 'N/A'}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontSize: 12,
                        fontWeight: 'bold',
                        fontFamily: 'Roboto',
                      }}
                    >
                      {'N/A'}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontSize: 12,
                        fontWeight: 'bold',
                        fontFamily: 'Roboto',
                      }}
                    >
                      {'N/A'}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontSize: 12,
                        fontWeight: 'bold',
                        fontFamily: 'Roboto',
                      }}
                    >
                      {'N/A'}
                    </TableCell>

                    <TableCell
                      align="left"
                      style={{ fontSize: 12, fontFamily: 'Roboto' }}
                    >
                      {val?.joiningDate
                        ? moment(val?.joiningDate).format('LL')
                        : 'N/A'}
                    </TableCell>

                    <TableCell
                      align="left"
                      style={{ fontSize: 12, fontFamily: 'Roboto' }}
                    >
                      {val?.endDate
                        ? moment().add(val?.endDate, 'days').format('LL')
                        : 'N/A'}
                    </TableCell>

                    <TableCell
                      align="left"
                      style={{ fontSize: 12, fontFamily: 'Roboto' }}
                    >
                      <Button
                        size="small"
                        style={{ textTransform: 'capitalize' }}
                        className={classes.buttonGreen}
                        onClick={() => redirectToMusterRollPage(val)}
                      >
                        View Muster Roll
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Grid>
  );
}
