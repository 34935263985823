import {
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React from 'react';
import temp from '../../../assests/G.H Builders IMG.png';
import ContractorProgressLinearChart from 'src/views/Billing/components/ContractorProgressLinearChart';
import { Link } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  listSection: {
    backgroundColor: 'inherit',
    margin: '5px',
    cursor: 'pointer',
  },

  ul: {
    backgroundColor: 'inherit',
    // padding: 0,
  },
  root: {
    // backgroundColor: theme.palette.background.dark,
    // minHeight: '100%',
    // maxWidth: '400px',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  buttonGreen: {
    color: theme.palette.common.white,
    fontFamily: 'Roboto',
    backgroundColor: '#81B734',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    fontFamily: 'Roboto',
    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    borderRadius: '10px',
    padding: '.5rem 1rem ',
    textTransform: 'capitalize',
    color: '#986FEF',
    fontFamily: 'Roboto',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  headerCellText: {
    fontSize: '11px',
    fontWeight: 500,
    fontFamily: 'Roboto',
    //     lineHeight: '13.75px',
  },
  headerCellText1: {
    fontSize: '10px',
    fontWeight: 600,
    fontFamily: 'Roboto',
    color: '#ADBAC3',
  },
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    width: '100%',
  },
  input: {
    display: 'none',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  hover: {
    ':hover': {
      backgroundColor: '#A179F2',
    },
  },
  searchFilterContainer: {
    // marginLeft: '15px',
  },
  marginBottom: {
    marginBottom: 0,
  },

  [theme.breakpoints.down('sm')]: {
    searchFilterContainer: {
      marginLeft: '0px',
    },
    marginBottom: {
      marginBottom: '15px',
    },
  },
}));

const ContractorList = ({ filters, setFilters }) => {
  const classes = useStyles();

  const renderChart = () => {
    // props
    const insightData = [
      {
        percentage: 64,
        color: 'linear-gradient(to right, #4426a8ff, #c0429eff)',
        heading: 'Material Available',
      },
      {
        percentage: 28,
        color: '#EAB401',
        heading: 'Material Used',
      },
    ];

    const grossTotal = 'N/A';

    return (
      <ContractorProgressLinearChart
        insightData={insightData}
        date={<></>}
        summaryLabel={<></>}
        grossTotal={grossTotal}
        renderPercentage={false}
        renderAmount={false}
      />
    );
  };

  let filteredArray = [];
  let listOfContractor = [
    {
      no: '01',
      contractorName: 'Vinod Shukla',
    },
    {
      no: '02',
      contractorName: 'Vinod Shukla',
    },
    {
      no: '03',
      contractorName: 'Vinod Shukla',
    },
    {
      no: '01',
      contractorName: 'Vinod Shukla',
    },
    {
      no: '02',
      contractorName: 'Vinod Shukla',
    },
    {
      no: '03',
      contractorName: 'Vinod Shukla',
    },
  ];
  return (
    <Grid
      container
      item
      xs={12}
      style={{
        marginTop: '1rem',
        // overflowX: "scroll",
      }}
    >
      {false ? (
        <div
          style={{
            textAlign: 'center',
            height: '76vh',
            width: '100%',
          }}
        >
          <CircularProgress style={{ color: '#81B734' }} />
        </div>
      ) : (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" className={classes.headerCellText1}>
                Sr #.
              </TableCell>
              <TableCell align="left" className={classes.headerCellText1}>
                CONTRACTOR
              </TableCell>
              <TableCell align="center" className={classes.headerCellText1}>
                WORK ORDERS
              </TableCell>
              <TableCell align="center" className={classes.headerCellText1}>
                MANPOWER
              </TableCell>
              <TableCell align="center" className={classes.headerCellText1}>
                MATERIALS
              </TableCell>
              <TableCell align="center" className={classes.headerCellText1}>
                FIELD NOTES
              </TableCell>

              <TableCell align="center" className={classes.headerCellText1}>
                ACTION
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredArray?.length ? (
              filteredArray?.map((val) => (
                <TableRow hover key={val.jobId}>
                  <TableCell
                    align="center"
                    className={classes.headerCellText}
                    width="7%"
                  >
                    A
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.headerCellText}
                    width="14%"
                  >
                    B
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.headerCellText}
                    width="12%"
                  >
                    C
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.headerCellText}
                    width="12%"
                  >
                    D
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.headerCellText}
                    width="12%"
                  >
                    E
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.headerCellText}
                    width="12%"
                  >
                    F
                  </TableCell>

                  <TableCell
                    align="center"
                    className={classes.headerCellText}
                    width="40%"
                  >
                    <Grid container xs={11} alignItems="center">
                      <Grid item xs={12} style={{ marginBottom: '10px' }}>
                        <Button
                          fullWidth
                          className={`${classes.buttonPurpleOpacity}`}
                          style={{
                            borderRadius: '9px',
                            fontSize: '11px',
                            fontWeight: 500,
                            textTransform: 'capitalize',
                          }}
                          size="small"
                          //   onClick={() => openDetails(val)}
                        >
                          Details
                        </Button>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))
            ) : filters.search !== '' || filteredArray === null ? (
              <TableRow>No Search Found!</TableRow>
            ) : (
              listOfContractor?.map((val) => (
                <TableRow hover key={val.no}>
                  <TableCell
                    align="center"
                    className={classes.headerCellText}
                    width="7%"
                  >
                    {val.no ?? 'N/A'}
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.headerCellText}
                    width="14%"
                  >
                    <Grid container alignItems="center">
                      <img
                        src={temp}
                        alt="company logo"
                        style={{ marginRight: '5px' }}
                      />{' '}
                      {val.contractorName ?? 'N/A'}
                    </Grid>
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.headerCellText}
                    width="10%"
                  >
                    <Grid container alignItems="flex=start">
                      <Grid
                        item
                        style={{ paddingRight: '7px', fontWeight: 500 }}
                      >
                        10
                      </Grid>
                      <Grid item xs={10}>
                        {renderChart()}
                        <Grid container item justifyContent="center">
                          {' '}
                          <small style={{ color: '#ADBAC3' }}>
                            AVG PROGRESS
                          </small>
                        </Grid>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.headerCellText}
                    width="10%"
                  >
                    <Grid container alignItems="flex=start">
                      <Grid
                        item
                        style={{ paddingRight: '7px', fontWeight: 500 }}
                      >
                        10
                      </Grid>
                      <Grid item xs={10}>
                        {renderChart()}
                        <Grid container item justifyContent="center">
                          {' '}
                          <small style={{ color: '#ADBAC3' }}>AVG</small>
                        </Grid>
                      </Grid>
                    </Grid>
                  </TableCell>{' '}
                  <TableCell
                    align="center"
                    className={classes.headerCellText}
                    width="10%"
                  >
                    <Grid container alignItems="flex=start">
                      <Grid
                        item
                        style={{ paddingRight: '7px', fontWeight: 500 }}
                      >
                        10
                      </Grid>
                      <Grid item xs={10}>
                        {renderChart()}
                        <Grid container item justifyContent="center">
                          {' '}
                          <small style={{ color: '#ADBAC3' }}>
                            CONTRACTOR SCORE
                          </small>
                        </Grid>
                      </Grid>
                    </Grid>
                  </TableCell>{' '}
                  <TableCell
                    align="center"
                    className={classes.headerCellText}
                    width="10%"
                  >
                    <Grid container alignItems="flex=start">
                      <Grid
                        item
                        style={{ paddingRight: '7px', fontWeight: 500 }}
                      >
                        10
                      </Grid>
                      <Grid item xs={10}>
                        {renderChart()}
                        <Grid container item justifyContent="center">
                          {' '}
                          <small style={{ color: '#ADBAC3' }}>AVG</small>
                        </Grid>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.headerCellText}
                    width="10%"
                  >
                    <Grid container xs={11} alignItems="center">
                      <Grid item xs={12} style={{ marginBottom: '10px' }}>
                        <Link to="../contractors/detail">
                          <Button
                            fullWidth
                            className={`${classes.buttonPurpleOpacity}`}
                            style={{
                              borderRadius: '9px',
                              fontSize: '11px',
                              fontWeight: 500,
                              textTransform: 'capitalize',
                            }}
                            size="small"
                            //   onClick={() => openDetails(val)}
                          >
                            View Detail
                          </Button>
                        </Link>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        //  </Zoom>
      )}
    </Grid>
  );
};

export default ContractorList;
