import React from 'react';
import {
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import FilterProjectComponent from '../FilterProjectComponent';
import { ConfigProvider, Popover } from 'antd';
import defaultProjectImage from '../../assests/Projects Icon.png';
import moment from 'moment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { productivityReducer } from 'src/redux/productivity';
import { useDispatch, useSelector } from 'react-redux';
import dummyImage from '../../assests/dummyProjectImageForProductivity.png';
import { useState } from 'react';
import { getLoggedInUserData } from 'src/App';
import { useTheme } from '@material-ui/styles';
import { useNavigate } from 'react-router';
import MusterRoll from '../Reports/MusterRool';
import DatePicker from 'react-datepicker';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import {
  getProgressReport,
  getProjectProgressReport,
  reportsReducer,
} from 'src/redux/reports';
import EventNoteIcon from '@material-ui/icons/EventNote';
import toast from 'react-hot-toast';
import useAccessPerView from 'src/common/Access';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonGrey: {
    color: theme.palette.common.white,
    backgroundColor: '#ddd',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    color: '#986FEF',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    borderRadius: '3px',
    textTransform: 'capitalize',
    padding: '5px 15px',
    fontSize: '12px',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  buttonWithFont: {
    fontWeight: 500,
    fontFamily: 'Roboto',

    // Add other styles if needed
  },
  cardPaddingRight: {
    paddingRight: '2rem',
  },
  marginTop: {
    marginTop: '0px',
  },
  width: {
    width: '90%',
  },

  caroselHeight: {
    height: '38em',
  },
  table: {
    minWidth: 650,
  },
  DARAndDLRColums: {
    color: '#c2c1cdff',
    fontSize: '12px',
  },
  marginRight: {
    marginRight: '10px',
  },
  greenContainer: {
    backgroundColor: '#81B734',
    height: '210px',
  },
  containerPadding: {
    padding: '1em',
    // borderRadius: "10px",
  },

  progressBar: {
    padding: '10px',
    borderRadius: '40px',
    height: '25px',
    '& .MuiLinearProgress-bar': {
      background: 'linear-gradient(to right, #4426a8ff, #c0429eff)',
    },
  },
  progressBarColorPrimary: {
    backgroundColor: 'white', // Set the background color of the bar
  },

  customSelect: {
    border: 'none',
    borderBottom: 'none',
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },

  label: {
    color: '#8E99A8',
  },
  modal: {
    '& .MuiDialog-container .MuiDialog-paperWidthLg': {
      maxWidth: '1200px !important',
    },
  },
  colorWhite: { color: '#000000' },
  weight700: { fontWeight: 700 },
  weight400: { fontWeight: 400 },
  greenCard: {
    paddingRight: '10px',
    backgroundColor: 'rgba(255,255,255, 0.35)',
    borderRadius: '10px',
  },
  radius: {
    borderRadius: '10px',
    backgroundColor: '#fff',
    overflow: 'hidden',
  },
  [theme.breakpoints.down('sm')]: {
    cardPaddingRight: {
      paddingRight: '0',
    },
    marginTop: {
      marginTop: '1.5rem',
    },
    width: {
      width: '100%',
    },
    caroselHeight: {
      height: '30em',
    },
  },
}));
const BOQProgressGreenContainer = (props) => {
  const [purpleTooltipOpen, setPurpleTooltipOpen] = useState('');
  const Accesess = {};

  Accesess['MEASUREMENT_SUBMISSION'] = useAccessPerView(
    'MEASUREMENT_MANAGEMENT'
  );
  Accesess['MEASUREMENT_APPROVAL'] = useAccessPerView('MEASUREMENT_APPROVAL');
  Accesess['CONTRACT_CONTRACTOR_MANAGEMENT'] = useAccessPerView(
    'CONTRACT_CONTRACTOR_MANAGEMENT'
  );

  const [greenTooltipOpen, setGreenTooltipOpen] = useState('');
  const [isDPR, setIsDPR] = useState(false);
  // const [dateRangeHistory, setDateRangeHistory] = useState([null, null]);
  // const [startDateHistory, endDateHistory] = dateRangeHistory;
  const [dailyProgressReportDate, setDailyProgressReportDate] = useState();

  const [reportSelectedDate, setReporteSelectedDate] = useState(null);

  const [endDate, setEndDate] = useState(null);

  //! PROPS
  const {
    projectClassificationList,
    currentProject,
    classifiedProject,
    setOpenInsertBOQ,
    classifyProject,
    setGCListView,
    setOpenUpdateProgress,
    showButtons = true,
    radius = 0,
    setIsViewBOQ,
  } = props;

  //! SELECTORS
  const { boqProgress, boqProgressLoading } = useSelector(productivityReducer);
  const { loading } = useSelector(reportsReducer);

  //! INSTANCES
  let classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  let dispatch = useDispatch();
  const handleDateRangeHistory = (update) => {
    setDailyProgressReportDate(update);
  };
  const onChangeDateReport = (update, isDateEnd) => {
    // let [start, end] = update;

    if (isDateEnd) {
      return setEndDate(update);
    }
    setReporteSelectedDate(update);

    // Calculate the start and end of the week
    // const startOfWeek = moment(date).startOf("week"); // Sunday
    // const endOfWeek = moment(date).endOf("week"); // Saturday

    // if (date) {
    //  dispatch(
    //    getAttendanceTrendline(
    //      currentProject?.projectId,
    //      startOfWeek.format("YYYY-MM-DD HH:mm:ss"),
    //      endOfWeek.format("YYYY-MM-DD HH:mm:ss")
    //    )
    //  );
    //  setAttendanceCurrentWeek({
    //    startOfWeek,
    //    endOfWeek,
    //  });
    // } else {
    //  setAttendanceCurrentWeek(null);
    //  dispatch(
    //    getAttendanceTrendline(
    //      currentProject?.projectId,
    //      moment().startOf("week").format("YYYY-MM-DD HH:mm:ss"),
    //      moment().endOf("week").format("YYYY-MM-DD HH:mm:ss")
    //    )
    //  );
    // }
  };
  function trimText(text, maxLength) {
    if (text?.length > maxLength) {
      return text?.slice(0, maxLength - 3) + '...';
    } else {
      return text;
    }
  }

  const downloadExcelFile = async () => {
    let resp = await dispatch(
      getProgressReport(
        currentProject?.projectId,
        moment(dailyProgressReportDate).format('YYYY-MM-DD')
      )
    );
    const contentType = resp.headers['content-type'];

    if (resp.status === 200) {
      if (contentType === 'application/json; charset=utf-8') {
        return toast.error('No Data Found!');
      }
      if (resp.data.success === false) return toast.error('No Data Found!');

      toast.success('Excel file downloading');
      const fileURL = window.URL.createObjectURL(new Blob([resp.data]));
      // Create a temporary anchor element
      const link = document.createElement('a');
      link.href = fileURL;
      // Set the download attribute if you want to specify a file name
      link.setAttribute(
        'download',
        `Daily Progress Report ${moment(dailyProgressReportDate).format(
          'MMM - YYYY'
        )}.xlsx`
      );

      // Append the link to the DOM
      document.body.appendChild(link);

      // Trigger a click to initiate the download
      link.click();

      // Clean up
      document.body.removeChild(link);
    } else if (resp.status === 500) {
      toast.error('No Data Found!');
    } else {
      toast.error('Something went wrong!');
    }
  };

  let navigate = useNavigate();

  return (
    <Grid container className={classes.radius}>
      <Dialog
        open={isDPR}
        onClose={() => {
          setIsDPR(false);
        }}
        keepMounted
        maxWidth="xl"
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          style={{
            padding: '15px',
          }}
          id="customized-dialog-title"
          onClose={() => {
            setIsDPR(false);
          }}
        >
          Download Daily Progress Report
        </DialogTitle>
        <DialogContent
          style={{
            padding: '15px',
            height: '450px',
          }}
        >
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            justify="space-between"
            style={{ padding: '1rem' }}
          >
            <Grid container item xs={12}>
              <Grid item container alignItems="center">
                <Grid item>
                  <Typography variant="subtitle">Select Project</Typography>
                </Grid>
                <Grid item>
                  <FilterProjectComponent
                    projectClassificationList={projectClassificationList}
                    src={
                      !classifiedProject[0]?.url
                        ? defaultProjectImage
                        : `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${classifiedProject[0]?.url}`
                    }
                    value={currentProject}
                    onChange={classifyProject}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item container>
              <Grid
                container
                item
                md={4}
                xs={12}
                className={classes.marginTop}
                style={{ marginTop: '1rem' }}
                justify="space-between"
              >
                {' '}
                <Grid item style={{ marginRight: '10px' }}>
                  <Typography variant="subtitle1">Select Date</Typography>
                </Grid>
                <Grid
                  item
                  style={{
                    position: 'relative',
                  }}
                  xs={6}
                >
                  <DatePicker
                    dateFormat="MMMM d, yyyy"
                    // selectsRange={true}
                    // startDate={reportSelectedDate}
                    // endDate={endDateHistory}
                    selected={dailyProgressReportDate}
                    onChange={(update) => {
                      handleDateRangeHistory(update);
                    }}
                    isClearable={true}
                    className="font12 date-picker "
                  />
                  {!dailyProgressReportDate && (
                    <EventNoteIcon
                      style={{
                        position: 'absolute',
                        right: '5px',
                        top: '7px',
                      }}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ marginTop: '1rem' }}>
                <Button
                  variant="outlined"
                  startIcon={<SystemUpdateAltIcon />}
                  // style={{ marginLeft: "20px" }}
                  onClick={downloadExcelFile}
                  style={{
                    backgroundColor: '#81b734',
                    color: '#fff',
                    textTransform: 'capitalize',
                  }}
                  disabled={loading}
                >
                  {loading ? 'Downloading...' : 'Download Report'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>{' '}
      <Grid
        container
        item
        alignItems="center"
        justify="space-between"
        className={classes.containerPadding}
      >
        <Grid container item xs={12} md={!showButtons ? 5 : 4} sm={5}>
          <Grid item container alignItems="center">
            {matches && (
              <Grid item>
                <Typography variant="subtitle" style={{ fontSize: '.8rem' }}>
                  Select Project
                </Typography>
              </Grid>
            )}
            <Grid item container={!matches}>
              <FilterProjectComponent
                projectClassificationList={projectClassificationList}
                src={
                  !classifiedProject[0]?.url
                    ? defaultProjectImage
                    : `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${classifiedProject[0]?.url}`
                }
                value={currentProject}
                onChange={classifyProject}
              />
            </Grid>
          </Grid>
        </Grid>

        {showButtons ? (
          <Grid container item justify="flex-end" md={8} xs={12}>
            {getLoggedInUserData()?.user?.leadTypeId !== 'LabourContractor' && (
              <Button
                endIcon={<ExpandMoreIcon />}
                className={`${classes.buttonPurpleOpacity} ${classes.marginRight}`}
                onClick={() => setOpenInsertBOQ(true)}
                // Only Active user and Level 1 can insert
                disabled={
                  !Accesess['CONTRACT_CONTRACTOR_MANAGEMENT'].hasAccess
                    .LEVEL1 &&
                  !Accesess['CONTRACT_CONTRACTOR_MANAGEMENT'].isEditMode &&
                  !Accesess['MEASUREMENT_APPROVAL'].hasAccess.LEVEL1 &&
                  !Accesess['MEASUREMENT_APPROVAL'].isEditMode
                }
              >
                Insert BoQ's
              </Button>
            )}
            {['Contractor'].includes(
              getLoggedInUserData()?.user?.leadTypeId
            ) ? (
              <Button
                endIcon={<ExpandMoreIcon />}
                className={`${classes.buttonPurpleOpacity} ${classes.marginRight}`}
                onClick={() => setOpenUpdateProgress(true)}
                // Only Active user and Level 1 can Update
                disabled={
                  !Accesess['MEASUREMENT_SUBMISSION'].hasAccess.LEVEL1 &&
                  !Accesess['MEASUREMENT_SUBMISSION'].isEditMode
                }
              >
                Update Progress
              </Button>
            ) : (
              <Button
                endIcon={<ExpandMoreIcon />}
                className={`${classes.buttonPurpleOpacity} ${classes.marginRight}`}
                onClick={() => setOpenUpdateProgress(true)}
                // Only Active user and Level 1 can Update
                disabled={
                  !Accesess['MEASUREMENT_SUBMISSION'].hasAccess.LEVEL1 &&
                  !Accesess['MEASUREMENT_SUBMISSION'].isEditMode
                }
              >
                Update Progress
              </Button>
            )}
            {getLoggedInUserData()?.user?.leadTypeId !== 'LabourContractor' && (
              <Button
                endIcon={<ExpandMoreIcon />}
                className={`${classes.buttonPurpleOpacity} ${classes.marginRight}`}
                onClick={() => setGCListView(true)}
                // Only LEVEL1 and ACTIVE USER will be able to verify progress
                disabled={
                  !Accesess['MEASUREMENT_APPROVAL'].hasAccess.LEVEL1 &&
                  !Accesess['MEASUREMENT_APPROVAL'].isEditMode
                }
              >
                Verify Progress
              </Button>
            )}
            <Button
              endIcon={<ExpandMoreIcon />}
              className={`${classes.buttonPurpleOpacity} ${classes.marginRight}`}
              onClick={() => setIsViewBOQ(true)}
            >
              View BoQ's
            </Button>
            <Button
              endIcon={<ExpandMoreIcon />}
              className={`${classes.buttonPurpleOpacity}`}
              onClick={() => setIsDPR(true)}
            >
              DPR
            </Button>
          </Grid>
        ) : (
          <Grid container item justify="flex-end" xs={6}>
            <Button
              endIcon={<ExpandMoreIcon />}
              className={`${classes.buttonPurpleOpacity} ${classes.buttonWithFont}  `}
              style={{
                marginRight: '25px',
                fontFamily: 'Roboto',
                fontWeight: 500,
              }}
              onClick={() =>
                navigate('../productivity', {
                  replace: true,
                  // state: { from: location },
                })
              }
            >
              productivity
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid container className={classes.greenContainer}>
        <Grid
          item
          // xs={3}
          container
          alignItems="center"
          justify="center"
          style={{
            width: '18%',
          }}
        >
          {boqProgressLoading ? (
            <Grid
              item
              style={{ height: '100%' }}
              container
              alignItems="center"
              justify="center"
            >
              {' '}
              <CircularProgress />
            </Grid>
          ) : (
            <img
              src={
                currentProject?.url
                  ? `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${currentProject?.url}`
                  : dummyImage
              }
              style={{
                width: '90%',
                borderRadius: '10px',
                height: '174px',
                boxShadow:
                  '0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
              }}
              alt="Project visual"
            />
          )}
        </Grid>
        <Grid
          item
          // xs={9}
          style={{
            padding: '0 15px',
            width: '82%',
          }}
        >
          <Grid
            container
            xs={12}
            justify="space-between"
            alignItems="center"
            style={{
              marginTop: '10px',
            }}
          >
            <Grid
              // xs={4}
              item
              container
              style={{
                // paddingRight: "10px",
                maxWidth: '230px',
              }}
              alignItems="center"
            >
              <Avatar
                alt="Project Image"
                src={
                  currentProject?.url
                    ? `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${currentProject?.url}`
                    : defaultProjectImage
                }
                style={{
                  width: '50px',
                  height: '50px',
                  marginRight: '1rem',
                }}
              />
              <Grid item container xs={8}>
                {' '}
                <Grid item xs={12}>
                  {' '}
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: '#FFFFFF',
                      fontWeight: 600,
                      fontSize: '.8em',
                    }}
                  >
                    {trimText(currentProject?.name, 28) ?? 'N/A'}
                  </Typography>
                </Grid>
                <Typography
                  style={{
                    color: '#C8E1A3',
                    fontSize: '.7em',
                  }}
                >
                  {currentProject?.projectTypeId ?? 'N/A'}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              // xs={6}
              // xl={5}
              item
              container
              alignItems="center"
              justify="flex-end"
              style={{
                maxWidth: '500px',
              }}
            >
              <Grid
                // xs={3}
                item
                container
                justify="center"
                // alignItems="center"

                className={classes.greenCard}
                style={{
                  padding: '8px 0',
                  maxWidth: '180px',
                  marginRight: '10px',
                }}
              >
                <Grid item>
                  <Typography
                    style={{ fontSize: '12px' }}
                    className={`${classes.colorWhite} ${classes.weight700}`}
                  >
                    Rs {boqProgress?.squareFeetCost?.toLocaleString() ?? 'N/A'}{' '}
                  </Typography>
                  <Typography
                    style={{ fontSize: '9px' }}
                    className={`${classes.colorWhite} ${classes.weight400} `}
                  >
                    Per Sqft
                  </Typography>
                </Grid>
                <Grid item>
                  <div
                    style={{
                      marginLeft: '4px',
                      backgroundColor: '#fff',
                      color: '#81B734',
                      borderRadius: '4px',
                      fontSize: '12px',
                      padding: '2px',
                    }}
                  >
                    +{boqProgress?.squareFeetCostPercentage.toFixed(2) ?? 'N/A'}
                    %
                  </div>
                </Grid>
              </Grid>
              <Grid
                justify="center"
                className={classes.greenCard}
                item
                container
                style={{
                  maxWidth: '170px',
                  padding: '8px 0px',
                  marginRight: '10px',
                }}
                xs={6}
              >
                {' '}
                <Grid item>
                  <Typography
                    style={{ fontSize: '12px' }}
                    className={`${classes.colorWhite} ${classes.weight700}`}
                  >
                    {boqProgress?.estimatedCompletionDate
                      ? moment(boqProgress?.estimatedCompletionDate).format(
                          'DD MMM, YYYY'
                        )
                      : 'N/A'}
                  </Typography>
                  <Typography
                    style={{ fontSize: '9px' }}
                    className={`${classes.colorWhite} ${classes.weight400} `}
                  >
                    AI based Est. Completion
                  </Typography>
                </Grid>
                <Grid item>
                  <div
                    style={{
                      marginLeft: '4px',
                      backgroundColor: '#fff',
                      color: '#81B734',
                      borderRadius: '4px',
                      fontSize: '12px',
                      padding: '2px',
                    }}
                  >
                    +{boqProgress?.estimatedCompletionDatePercentage ?? 'N/A'}%
                  </div>
                </Grid>
              </Grid>
              <Grid
                className={classes.greenCard}
                item
                container
                justify="center"
                // alignItems="center"
                style={{
                  padding: '8px 0',
                  width: '75px',
                }}
                // xs={3}
              >
                <Grid item>
                  <Typography
                    style={{ fontSize: '12px' }}
                    className={`${classes.colorWhite} ${classes.weight700}`}
                  >
                    {boqProgress?.estimatedDelayInDays ?? 'N/A'} Days
                  </Typography>
                  <Typography
                    style={{ fontSize: '9px' }}
                    className={`${classes.colorWhite} ${classes.weight400} `}
                  >
                    Est. Delay
                  </Typography>
                </Grid>
                {/* <Grid item>
                  <div
                    style={{
                      marginLeft: "4px",
                      backgroundColor: "#fff",
                      color: "#81B734",
                      borderRadius: "4px",
                      fontSize: "12px",
                      padding: "2px",
                    }}
                  >
                    +{boqProgress?.estimatedCompletionDatePercentage ?? "N/A"}%
                  </div>
                </Grid> */}
              </Grid>
            </Grid>

            <Grid
              container
              justify="flex-end"
              alignItems="center"
              item
              xs={2}
              style={{
                maxWidth: '100px',
              }}
            >
              <Grid item xs={12}>
                <Typography
                  align="right"
                  variant="h6"
                  style={{ color: '#fff' }}
                >
                  {boqProgressLoading ? (
                    <CircularProgress />
                  ) : isNaN(
                      (boqProgress?.verifiedMeasurement /
                        boqProgress?.totalMeasurement) *
                        100
                    ) ? (
                    0
                  ) : (
                    `${Math.round(
                      (boqProgress?.verifiedMeasurement /
                        boqProgress?.totalMeasurement) *
                        100
                    )}%`
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography align="right" style={{ color: '#C8E1A3' }}>
                  {/* Work Done */}
                  Completion
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {boqProgress && (
            <Grid
              container
              xs={12}
              style={{
                marginTop: '2.1rem',
                position: 'relative',
              }}
            >
              <Grid
                container
                item
                xs={12}
                alignItems="center"
                justify="space-between"
                style={{
                  color: '#fff',
                  paddingBottom: '5px',
                }}
              >
                <Grid item>
                  <small>0%</small>
                </Grid>
                <Grid item>
                  <small>100%</small>
                </Grid>
              </Grid>
              <div
                style={{
                  width: '100%',
                  backgroundColor: '#fff',
                  padding: '5px',
                  borderRadius: '40px',
                  height: boqProgress?.totalMeasurement !== 0 ? 'auto' : '35px',
                }}
              >
                {boqProgress?.totalMeasurement !== 0 && (
                  <>
                    <ConfigProvider
                      theme={{
                        components: {
                          Popover: {
                            titleMinWidth: 40,
                            colorBgElevated: '#A179F2',
                            colorTextHeading: '#fff',
                            padding: 0,
                            sizePopupArrow: 22,
                          },
                        },
                      }}
                    >
                      <Popover
                        open={purpleTooltipOpen}
                        popupAlign={{ offset: [18, -28] }}
                        style={{ position: 'fixed' }}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        content={
                          <Grid container style={{ width: '120px' }}>
                            <Typography
                              variant="subtitle1"
                              style={{
                                color: '#fff',
                                fontSize: '14px',
                                width: '100%',
                              }}
                              align="center"
                            >
                              {/* Rs:{' '} */}
                              {boqProgress?.completedMeasurementAmount?.toLocaleString()}{' '}
                              ₹
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              style={{
                                color: '#fff',
                                fontSize: '14px',

                                width: '100%',
                              }}
                              align="center"
                            >
                              {(
                                (boqProgress?.completedMeasurement /
                                  boqProgress?.totalMeasurement) *
                                100
                              ).toFixed(1)}{' '}
                              %
                              {/* <span style={{ color: "#ddd" }}>|</span>{" "}
                                  {boqProgress?.completedMeasurement.toLocaleString()} */}
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              style={{
                                color: '#fff',
                                fontSize: '9px',
                                width: '100%',
                                fontWeight: 300,
                              }}
                              align="center"
                            >
                              Work Done
                            </Typography>
                            {/* <div
                              style={{
                                position: 'absolute',
                                width: '1px',
                                height: '17px',
                                backgroundColor: '#000000',
                                opacity: '0.2',
                                right: '23px',
                                top: '90  px',
                              }}
                            ></div> */}
                          </Grid>
                        }
                        placement="topRight"
                      >
                        <div
                          onMouseOver={() => {
                            setPurpleTooltipOpen(true);
                            if (greenTooltipOpen) {
                              setGreenTooltipOpen(false);
                            }
                          }}
                          style={{
                            cursor: 'pointer',

                            width:
                              boqProgress?.completedMeasurement === 0
                                ? '0%'
                                : isNaN(
                                    (boqProgress?.completedMeasurement /
                                      boqProgress?.totalMeasurement) *
                                      100
                                  )
                                ? '0%'
                                : (boqProgress?.completedMeasurement /
                                    boqProgress?.totalMeasurement) *
                                    100 >=
                                  100
                                ? 100 + '%'
                                : (boqProgress?.completedMeasurement /
                                    boqProgress?.totalMeasurement) *
                                    100 +
                                  '%',

                            padding:
                              boqProgress?.completedMeasurement === 0
                                ? 0
                                : '10px',
                            borderTopLeftRadius: '40px',
                            borderBottomLeftRadius: '40px',
                            borderTopRightRadius:
                              Math.round(
                                (boqProgress?.completedMeasurement /
                                  boqProgress?.totalMeasurement) *
                                  100
                              ) >= 100
                                ? '40px'
                                : 0,
                            borderBottomRightRadius:
                              Math.round(
                                (boqProgress?.completedMeasurement /
                                  boqProgress?.totalMeasurement) *
                                  100
                              ) >= 100
                                ? '40px'
                                : 0,

                            height: '25px',
                            background:
                              'linear-gradient(to right, #4426a8ff, #c0429eff)',
                          }}
                        ></div>
                      </Popover>
                    </ConfigProvider>
                    <ConfigProvider
                      theme={{
                        components: {
                          Popover: {
                            /* here is your component tokens */
                            titleMinWidth: 110,
                            colorBgElevated: '#81B734',
                            colorTextHeading: '#fff',
                            padding: 0,
                            sizePopupArrow: 22,
                          },
                        },
                      }}
                    >
                      {boqProgress?.todaysMeasurement !== 0 && (
                        <Popover
                          popupAlign={{ offset: [18, -28] }}
                          content={
                            <Grid container style={{ width: '120px' }}>
                              <Typography
                                variant="subtitle1"
                                style={{
                                  color: '#fff',
                                  fontSize: '14px',
                                  width: '100%',
                                }}
                                align="center"
                              >
                                {/* Rs:{' '} */}
                                {boqProgress?.verifiedMeasurementAmount?.toLocaleString()}{' '}
                                ₹
                              </Typography>
                              <Typography
                                variant="subtitle1"
                                style={{
                                  color: '#fff',
                                  fontSize: '14px',
                                  width: '100%',
                                }}
                                align="center"
                              >
                                {isNaN(
                                  (boqProgress?.verifiedMeasurement /
                                    boqProgress?.totalMeasurement) *
                                    100
                                )
                                  ? '0%'
                                  : (boqProgress?.verifiedMeasurement /
                                      boqProgress?.totalMeasurement) *
                                      100 >
                                    100
                                  ? '100%'
                                  : (
                                      (boqProgress?.verifiedMeasurement /
                                        boqProgress?.totalMeasurement) *
                                      100
                                    ).toFixed(1) + '%'}
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                style={{
                                  color: '#fff',
                                  fontSize: '9px',
                                  width: '100%',
                                  fontWeight: 300,
                                }}
                                align="center"
                              >
                                Certified
                              </Typography>
                              {/* <div
                                style={{
                                  position: 'absolute',
                                  width: '1px',
                                  height: '17px',
                                  backgroundColor: '#000000',
                                  opacity: '0.2',
                                  right: '23px',
                                  top: '59px',
                                }}
                              ></div> */}
                            </Grid>
                          }
                          open={greenTooltipOpen}
                          placement="topRight"
                        >
                          <div
                            onMouseOver={() => {
                              setGreenTooltipOpen(true);
                              if (purpleTooltipOpen) {
                                setPurpleTooltipOpen(false);
                              }
                            }}
                            style={{
                              position: 'absolute',
                              cursor: 'pointer',
                              top: '37.6%',
                              borderTopLeftRadius: '40px',
                              borderBottomLeftRadius: '40px',
                              width:
                                boqProgress?.verifiedMeasurement === 0
                                  ? 0
                                  : isNaN(
                                      (boqProgress?.verifiedMeasurement /
                                        boqProgress?.totalMeasurement) *
                                        100
                                    )
                                  ? '0%'
                                  : (boqProgress?.verifiedMeasurement /
                                      boqProgress?.totalMeasurement) *
                                      100 >
                                    100
                                  ? '98.6%'
                                  : (boqProgress?.verifiedMeasurement /
                                      boqProgress?.totalMeasurement) *
                                      100 -
                                      0.7 <
                                    2
                                  ? '1%'
                                  : (boqProgress?.verifiedMeasurement /
                                      boqProgress?.totalMeasurement) *
                                      100 -
                                    0.7 +
                                    '%',
                              height: '21px',
                              background: '#bcd794ff',
                              color: '#558511',
                              borderTopRightRadius:
                                Math.round(
                                  (boqProgress?.verifiedMeasurement /
                                    boqProgress?.totalMeasurement) *
                                    100
                                ) > 99
                                  ? '40px'
                                  : 0,
                              borderBottomRightRadius:
                                Math.round(
                                  (boqProgress?.verifiedMeasurement /
                                    boqProgress?.totalMeasurement) *
                                    100
                                ) > 99
                                  ? '40px'
                                  : 0,
                            }}
                          >
                            {/* {moment().format("DD MMM, YYYY")} */}
                          </div>
                        </Popover>
                      )}
                    </ConfigProvider>
                  </>
                )}
              </div>
              <Grid
                container
                item
                xs={12}
                alignItems="center"
                justify="space-between"
                style={{
                  color: '#fff',
                  paddingTop: '5px',
                }}
              >
                <Grid item>
                  <small>
                    {boqProgress?.startDate
                      ? moment(boqProgress?.startDate).format('DD MMM, YYYY')
                      : '-'}
                  </small>
                </Grid>
                <Grid item>
                  <small>
                    {' '}
                    {boqProgress?.endDate
                      ? moment(boqProgress?.endDate).format('DD MMM, YYYY')
                      : '-'}
                  </small>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BOQProgressGreenContainer;
