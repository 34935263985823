import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Switch,
  TextareaAutosize,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  getProjectsClassificationList,
  projectReducer,
} from 'src/redux/projects';
import { useDispatch, useSelector } from 'react-redux';

import defaultProjectImage from '../../assests/Projects Icon.png';
import FilterProjectComponent from 'src/components/FilterProjectComponent';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Page from 'src/components/Page';
import PlayCircleFilledRoundedIcon from '@material-ui/icons/PlayCircleFilledRounded';
import AddBoxIcon from '@material-ui/icons/AddBox';
import general from '../../assests/boq/general.svg';
import DatePicker from 'react-datepicker';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import { v4 as uuidv4 } from 'uuid';
import CloseIcon from '@material-ui/icons/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import {
  addBOQ,
  addProgress,
  addUpdateBoq,
  getSOWListByBOQ,
  getBOQMetrics,
  getBOQProgress,
  getFinancialProgressData,
  getProjectBudget,
  getProjectProgressGraph,
  getScopeList,
  getUnitList,
  getListOfBOQ,
  productivityReducer,
  selectProductivityParentProject,
  uploadBulkProgress,
  uploadBulkBOQ,
  updateChangeRequestQuantity,
} from 'src/redux/productivity';
import toast, { Toaster } from 'react-hot-toast';
import FilterByLabourContractorComponent from 'src/components/FilterByLaourContractorComponent';
import { getContractors } from 'src/redux/users';
import CreateFieldNote from '../FieldNotes/createFieldNote';
import arrow from '../../assests/arrow.png';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryLegend,
  VictoryTheme,
  VictoryTooltip,
} from 'victory';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ProductivityListView from './listView';
import BOQProgressGreenContainer from 'src/components/productivity/BOQProgressGreenContainer';

import { useRef } from 'react';
import ViewBOQList from './viewBOQList';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import SearchInput from 'src/components/SearchInput';
import { NestedBOQ } from './NestedBOQ';
import workmanshipCost from '../../assests/poorWorkmanship.png';
import materialDefect from '../../assests/materialDefects.png';
import variationCost from '../../assests/variationCost.png';
import changeRequest from '../../assests/changeRequest.png';
import TempComponent from 'src/components/TempComponent';
import uncertifiedWork from '../../assests/uncertifiedWork.png';
import unbilledWorker from '../../assests/unbilledWorkers.png';
import useAccessPerView from 'src/common/Access';
import TielsComponenet from 'src/components/TielsComponent';
import { contractor } from 'src/routes';
import { useLocation } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  titleAccordion: { width: '100%', border: 'none', boxShadow: 'none' },
  titleAccordionSummary: { padding: 0, margin: 0 },
  titleAccordionDetails: {
    width: '100%',
    display: 'inline-block',
    padding: 0,
    margin: 0,
  },
  expanded: {
    '&$expanded': {
      margin: '0px',
    },
  },
  remainingWorkValue: {
    transition: 'color 0.3s ease',
  },
  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonGrey: {
    color: theme.palette.common.white,
    backgroundColor: '#ddd',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  input1: {
    display: 'none',
  },
  buttonPurpleOpacity: {
    color: '#986FEF',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    borderRadius: '10px',
    textTransform: 'capitalize',
    padding: '5px 15px',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  cardPaddingRight: {
    paddingRight: '2rem',
  },
  marginTop: {
    marginTop: '0px',
  },
  width: {
    width: '90%',
  },

  caroselHeight: {
    height: '38em',
  },
  table: {
    minWidth: 650,
  },
  DARAndDLRColums: {
    color: '#c2c1cdff',
    fontSize: '12px',
  },
  marginRight: {
    marginRight: '10px',
  },
  greenContainer: {
    backgroundColor: '#81B734',
    height: '250px',
  },
  containerPadding: {
    padding: '1em',
  },

  progressBar: {
    padding: '10px',
    borderRadius: '40px',
    height: '25px',
    '& .MuiLinearProgress-bar': {
      background: 'linear-gradient(to right, #4426a8ff, #c0429eff)',
    },
  },
  progressBarColorPrimary: {
    backgroundColor: 'white', // Set the background color of the bar
  },

  red: {
    color: 'red',
    backgroundColor: 'red',
  },

  customSelect: {
    border: 'none',
    borderBottom: 'none',
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },

  label: {
    color: '#8E99A8',
  },
  modal: {
    '& .MuiDialog-container .MuiDialog-paperWidthLg': {
      maxWidth: '1200px !important',
    },
  },
  ImagePlaceHolder: {
    cursor: 'pointer',
  },
  [theme.breakpoints.down('sm')]: {
    cardPaddingRight: {
      paddingRight: '0',
    },
    marginTop: {
      marginTop: '1.5rem',
    },
    width: {
      width: '100%',
    },
    caroselHeight: {
      height: '30em',
    },
  },
}));
const DescriptionRow = ({
  title,
  desc,
  descIndex,
  titleIndex,
  sowIndex,
  rowItem,
  addTitleDescription,
  deleteTitleDescription,
  unitList,
  updateTitleDescValue,
}) => {
  return (
    <Grid container alignItems="center">
      <Grid item style={{ margin: '0px 15px', width: '130px' }}>
        <Typography style={{ color: '#8E99A8' }}>Cost Code</Typography>
        <Typography>{`${rowItem.scopeOfWorkId}.${titleIndex + 1}.${
          descIndex + 2
        }`}</Typography>
      </Grid>
      <Grid item style={{ width: '140px' }}></Grid>
      <Grid item>
        <TextField
          fullWidth
          label="Description"
          size="small"
          variant="filled"
          multiline
          // rows={3}
          rowsMax={3}
          InputProps={{
            endAdornment: descIndex === title.descriptions.length - 1 && (
              <InputAdornment
                position="end"
                onClick={() => {
                  addTitleDescription(rowItem.rowId, title.id);
                }}
              >
                <IconButton aria-label="toggle password visibility" edge="end">
                  <ControlPointIcon style={{ fontSize: '18px' }} />
                </IconButton>
              </InputAdornment>
            ),
            disableUnderline: true,
            style: {
              borderRadius: '7px',
            },
          }}
          value={desc.description}
          InputLabelProps={{
            style: {
              color: '#8E99A8',
            },
          }}
          onChange={({ target }) => {
            let { value } = target;
            updateTitleDescValue(
              rowItem.rowId,
              title.id,
              desc.id,
              'description',
              value
            );
          }}
          style={{
            margin: '5px',
            width: '240px',
          }}
        />
      </Grid>
      <Grid item>
        <TextField
          select
          label="Unit"
          size="small"
          variant="filled"
          InputProps={{
            disableUnderline: true,
            style: {
              borderRadius: '7px',
            },
          }}
          // style={{padding: "5px"}}
          InputLabelProps={{
            // classes: {
            //   root: classes.label,
            // },
            style: {
              color: '#8E99A8',
            },
          }}
          value={desc.unitId}
          style={{ width: '90px', margin: '5px' }}
          required
          onChange={(event) => {
            let { value } = event.target;
            updateTitleDescValue(
              rowItem.rowId,
              title.id,
              desc.id,
              'unitId',
              value
            );
          }}
        >
          {unitList?.length ? (
            unitList?.map((item) => (
              <MenuItem
                key={item?.unitId}
                value={item?.unitId}
                style={{ fontFamily: 'Roboto' }}
              >
                {item?.code}
              </MenuItem>
            ))
          ) : (
            <MenuItem value=""></MenuItem>
          )}
        </TextField>
      </Grid>
      <Grid item>
        <TextField
          id="filled-basic"
          label="Quantity"
          size="small"
          variant="filled"
          InputProps={{
            disableUnderline: true,
            style: {
              borderRadius: '7px',
            },
          }}
          type="tel"
          value={desc.quantity}
          // style={{padding: "5px"}}
          InputLabelProps={{
            // classes: {
            //   root: classes.label,
            // },
            style: {
              color: '#8E99A8',
            },
          }}
          onChange={({ target }) => {
            let { value } = target;
            updateTitleDescValue(
              rowItem.rowId,
              title.id,
              desc.id,
              'quantity',
              value
            );
          }}
          style={{ width: '80px', margin: '5px' }}
          required
        />
      </Grid>
      <Grid item>
        <TextField
          id="filled-basic"
          label="Rate"
          size="small"
          variant="filled"
          InputProps={{
            disableUnderline: true,
            style: {
              borderRadius: '7px',
            },
          }}
          required
          type="tel"
          value={desc.rate}
          // style={{padding: "5px"}}
          InputLabelProps={{
            // classes: {
            //   root: classes.label,
            // },
            style: {
              color: '#8E99A8',
            },
          }}
          onChange={({ target }) => {
            let { value } = target;
            updateTitleDescValue(
              rowItem.rowId,
              title.id,
              desc.id,
              'rate',
              value
            );
          }}
          style={{ width: '100px', margin: '5px' }}
        />
      </Grid>
      <Grid item>
        <TextField
          id="filled-basic"
          label="Amount"
          size="small"
          variant="filled"
          InputProps={{
            disableUnderline: true,
            style: {
              borderRadius: '7px',
            },
          }}
          required
          type="tel"
          value={(desc.rate * desc.quantity).toString().toLocaleString()}
          // style={{padding: "5px"}}
          InputLabelProps={{
            // classes: {
            //   root: classes.label,
            // },
            style: {
              color: '#8E99A8',
            },
          }}
          style={{ width: '100px', margin: '5px' }}
        />
      </Grid>
      <Grid item style={{ margin: '0px 15px' }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <TextField
            id="date"
            type="date"
            label="Completion Date"
            InputLabelProps={{
              shrink: true,
            }}
            // value={desc.EndDate}
            onChange={(date) =>
              updateTitleDescValue(
                rowItem.rowId,
                title.id,
                desc.id,
                'EndDate',
                moment(date.target.value).format('YYYY-MM-DD')
              )
            }
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item title="Delete Description">
        <HighlightOffIcon
          style={{ color: 'red', cursor: 'pointer' }}
          onClick={() => {
            deleteTitleDescription(rowItem.rowId, title.id, desc.id);
          }}
        />
      </Grid>
    </Grid>
  );
};
const TitleRow = (props) => {
  const {
    unitList,
    rowItem,
    addTitle,
    scopeList,
    addTitleDescription,
    classes,
    index,
    title,
    deleteRow,
    rowIndex,
    deleteTitle,
    updateScopeWorkValue,
    updateTitleValue,
    sowIndex,
    titleIndex,
  } = props;
  return (
    <Grid container alignItems="center">
      <Grid item style={{ margin: '0px 15px', minWidth: '130px' }}>
        <Typography style={{ color: '#8E99A8' }}>Cost Code</Typography>
        <Typography>{`${rowItem.scopeOfWorkId}.${
          titleIndex + 1
        }.1`}</Typography>
      </Grid>
      <Grid item>
        <TextField
          label="Title"
          size="small"
          variant="filled"
          multiline
          InputProps={{
            endAdornment: rowItem.titles.length - 1 === index && (
              <InputAdornment
                position="end"
                onClick={() => {
                  addTitle(rowItem.rowId);
                }}
              >
                <IconButton aria-label="toggle password visibility" edge="end">
                  <ControlPointIcon style={{ fontSize: '18px' }} />
                </IconButton>
              </InputAdornment>
            ),
            disableUnderline: true,
            style: {
              borderRadius: '7px',
            },
          }}
          InputLabelProps={{
            style: {
              color: '#8E99A8',
            },
          }}
          value={title.title}
          style={{ width: '130px', margin: '5px' }}
          required
          onChange={(event) => {
            let { value } = event.target;
            updateTitleValue(rowItem.rowId, title.id, 'title', value);
          }}
        />
      </Grid>
      <Grid item>
        <TextField
          label="Description"
          size="small"
          variant="filled"
          multiline
          // rows={3}
          rowsMax={3}
          InputProps={{
            endAdornment: title.descriptions.length === 0 && (
              <InputAdornment
                position="end"
                onClick={() => {
                  addTitleDescription(rowItem.rowId, title.id);
                }}
              >
                <IconButton aria-label="toggle password visibility" edge="end">
                  <ControlPointIcon style={{ fontSize: '18px' }} />
                </IconButton>
              </InputAdornment>
            ),
            disableUnderline: true,
            style: {
              borderRadius: '7px',
            },
          }}
          value={title.description}
          InputLabelProps={{
            style: {
              color: '#8E99A8',
            },
          }}
          onChange={({ target }) => {
            let { value } = target;
            updateTitleValue(rowItem.rowId, title.id, 'description', value);
          }}
          style={{
            margin: '5px',
            width: '240px',
          }}
        />
      </Grid>
      <Grid item>
        <TextField
          select
          label="Unit"
          size="small"
          variant="filled"
          InputProps={{
            disableUnderline: true,
            style: {
              borderRadius: '7px',
            },
          }}
          // style={{padding: "5px"}}
          InputLabelProps={{
            // classes: {
            //   root: classes.label,
            // },
            style: {
              color: '#8E99A8',
            },
          }}
          value={title.unitId}
          style={{ width: '90px', margin: '5px' }}
          required
          onChange={(event) => {
            let { value } = event.target;

            updateTitleValue(rowItem.rowId, title.id, 'unitId', value);
          }}
        >
          {unitList?.length ? (
            unitList?.map((item) => (
              <MenuItem
                key={item?.unitId}
                value={item?.unitId}
                style={{ fontFamily: 'Roboto' }}
              >
                {item?.code}
              </MenuItem>
            ))
          ) : (
            <MenuItem value=""></MenuItem>
          )}
        </TextField>
      </Grid>
      <Grid item>
        <TextField
          id="filled-basic"
          label="Quantity"
          size="small"
          variant="filled"
          InputProps={{
            disableUnderline: true,
            style: {
              borderRadius: '7px',
            },
          }}
          type="tel"
          value={title.quantity}
          // style={{padding: "5px"}}
          InputLabelProps={{
            // classes: {
            //   root: classes.label,
            // },
            style: {
              color: '#8E99A8',
            },
          }}
          onChange={({ target }) => {
            let { value } = target;
            updateTitleValue(rowItem.rowId, title.id, 'quantity', value);
          }}
          style={{ width: '80px', margin: '5px' }}
          required
        />
      </Grid>
      <Grid item>
        <TextField
          id="filled-basic"
          label="Rate"
          size="small"
          variant="filled"
          InputProps={{
            disableUnderline: true,
            style: {
              borderRadius: '7px',
            },
          }}
          required
          type="tel"
          value={title.rate}
          // style={{padding: "5px"}}
          InputLabelProps={{
            // classes: {
            //   root: classes.label,
            // },
            style: {
              color: '#8E99A8',
            },
          }}
          onChange={({ target }) => {
            let { value } = target;
            updateTitleValue(rowItem.rowId, title.id, 'rate', value);
          }}
          style={{ width: '100px', margin: '5px' }}
        />
      </Grid>
      <Grid item>
        <TextField
          id="filled-basic"
          label="Amount"
          size="small"
          variant="filled"
          InputProps={{
            disableUnderline: true,
            style: {
              borderRadius: '7px',
            },
          }}
          required
          type="tel"
          value={(title.rate * title.quantity).toString().toLocaleString()}
          // style={{padding: "5px"}}
          InputLabelProps={{
            // classes: {
            //   root: classes.label,
            // },
            style: {
              color: '#8E99A8',
            },
          }}
          style={{ width: '100px', margin: '5px' }}
        />
      </Grid>
      <Grid item style={{ margin: '0px 15px' }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <TextField
            id="date"
            type="date"
            label="Completion Date"
            InputLabelProps={{
              shrink: true,
            }}
            value={rowItem.EndDate}
            onChange={(date) => {
              updateTitleValue(
                rowItem.rowId,
                title.id,
                'EndDate',
                moment(date.target.value).format('YYYY-MM-DD')
              );
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      {index !== 0 && (
        <Grid item title="Delete Title">
          <HighlightOffIcon
            style={{ color: 'red', cursor: 'pointer' }}
            onClick={() => {
              deleteTitle(rowItem.rowId, title.id);
            }}
          />
        </Grid>
      )}
      {index === 0 && rowIndex !== 0 && (
        <Grid item title="Delete BOQ">
          <HighlightOffIcon
            style={{ color: 'red', cursor: 'pointer' }}
            onClick={() => {
              deleteRow(rowItem.rowId);
            }}
          />
        </Grid>
      )}
      {title.descriptions.length > 0 &&
        title.descriptions.map((desc, i) => (
          <DescriptionRow
            key={desc.id}
            desc={desc}
            descIndex={i}
            titleIndex={titleIndex}
            sowIndex={sowIndex}
            {...props}
          />
        ))}
    </Grid>
  );
};

const Row = (props) => {
  let {
    classes,
    rowItem,
    rowIndex: sowIndex,
    updateScopeWorkValue,
    scopeList,
    rowList,
  } = props;

  const prevRows = rowList.map((row) => row.scopeOfWorkId);
  const total = rowItem?.titles?.reduce((acc, title) => {
    if (title.quantity && title.rate) {
      acc += Number(title.quantity) * Number(title.rate);
    }
    if (title?.descriptions?.length > 0) {
      acc += title.descriptions.reduce((descAcc, description) => {
        if (description.quantity && description.rate) {
          descAcc += Number(description.quantity) * Number(description.rate);
        }
        return descAcc;
      }, 0);
    }
    return acc;
  }, 0);

  const selectedScope = scopeList?.find((scope) => {
    if (scope) {
      return scope.scopeOfWorkId === rowItem?.scopeOfWorkId;
    } else {
      return false;
    }
  });

  const totalLabel = selectedScope ? (
    <Grid item container style={{ marginLeft: 40 }}>
      <Grid item>
        <img
          src={selectedScope.imageUrl}
          style={{ width: 25, marginRight: 5 }}
          alt={`${selectedScope.name}`}
        />
      </Grid>
      <Grid item>
        <Typography style={{ fontWeight: 'bold' }}>
          {`${selectedScope.name} Total`}
        </Typography>
      </Grid>
    </Grid>
  ) : (
    'Scope Total'
  );
  return (
    <Grid container alignItems="center">
      <Grid
        item
        xs={12}
        container
        style={{
          border: '1px solid rgba(2,1,1,0.3)',
          borderRadius: 5,
          marginBottom: 10,
        }}
      >
        <Grid
          container
          item
          style={{ display: 'flex', marginLeft: 20, marginTop: 5 }}
          alignItems="center"
        >
          {/* <img src={icon} alt="skill" style={{ width: "24px" }} /> */}
          <div
            style={{
              backgroundColor: 'rgba(1,1,1,0.4)',
              borderRadius: 20,
              width: 30,
              height: 30,
              color: 'white',
              marginRight: 5,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {sowIndex + 1}
          </div>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Scope Of Work</InputLabel>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              // value={rowItem.scopeOfWorkId}
              style={{
                minWidth: '195px',
                backgroundColor: 'none',
                // margin: "0 20px 0 10px",
                border: 'none',
                fontWeight: '500',
                '&:focus': {
                  backgroundColor: 'transparent',
                },
              }}
              classes={{
                select: classes.customSelect,
              }}
              inputProps={{
                style: {
                  borderBottom: 'none',
                },
              }}
              onChange={async (event) => {
                updateScopeWorkValue(rowItem.rowId, event.target.value);
              }}
              // label={"Hello theres"}
              value={rowItem.scopeOfWorkId}
              disableUnderline
              IconComponent={ExpandMoreIcon}
              defaultValue={scopeList[0]?.scopeOfWorkId || -1}
            >
              <MenuItem value={-1}>Scope of Work</MenuItem>
              {/* filters out the scope items that have already been added */}
              {scopeList?.length > 0 &&
                scopeList
                  .filter(
                    (list) =>
                      !prevRows.includes(list.scopeOfWorkId) ||
                      list.scopeOfWorkId === rowItem.scopeOfWorkId
                  )
                  .map((item, index) => (
                    <MenuItem
                      value={item.scopeOfWorkId}
                      key={index}
                      style={{ fontWeight: 500 }}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          style={{
            margin: '5px 10px',
            paddingTop: 10,
            borderTop: '1px solid rgba(1,1,1,0.3)',
            width: '100%',
          }}
        >
          {rowItem.titles?.length &&
            rowItem.titles.map((item, index) => (
              <TitleRow
                key={item.id}
                index={index}
                titleIndex={index}
                sowIndex={sowIndex}
                title={item}
                {...props}
              />
            ))}
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          justify="space-between"
          xs={12}
          style={{
            margin: '0px 10px',
            borderTop: '1px solid rgba(1,1,1,0.2)',
            padding: '10px 0px',
          }}
        >
          <Grid item>{totalLabel}</Grid>
          <Grid item>
            <TextField
              label="Total Amount"
              size="small"
              variant="filled"
              InputProps={{
                disableUnderline: true,
                style: {
                  borderRadius: '7px',
                },
              }}
              value={total.toLocaleString()}
              disabled
              type="tel"
              InputLabelProps={{
                style: {
                  color: '#8E99A8',
                },
              }}
              style={{ margin: '5px', width: 125 }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const ProgressRow = ({
  classes,
  unitList,
  scopeList,
  rowItem,
  setRowList,
  rowList,

  addProgressEntery,
  openFieldNote,
  handleRemarkChange,
  setListData,
  listData,
  handleImage,
}) => {
  const [progressValue, setProgressValue] = useState(0);

  console.log('progress row is now open');
  return (
    <Grid
      container
      alignItems="center"
      title={rowItem.scopeOfWorkName}
      style={{ width: '1450px' }}
    >
      <Grid item>
        <TextField
          disabled
          id="filled-basic"
          label="Scope Of Work"
          size="small"
          InputProps={{
            disableUnderline: true,
            style: {
              borderRadius: '7px',
            },
          }}
          value={rowItem.scopeOfWorkName}
          InputLabelProps={{
            style: {
              color: '#8E99A8',
            },
          }}
          style={{ width: '180px', margin: '5px' }}
        />
      </Grid>
      <Grid
        item
        title={rowItem.title}
        style={{
          width: '150px',
          wordWrap: 'break-word',
          maxHeight: '100px',
          overflowY: rowItem.showMoreTitle ? 'scroll' : 'hidden',
        }}
      >
        <div>
          {' '}
          <small style={{ color: 'rgb(142, 153, 168)', fontSize: '12px' }}>
            Title
          </small>
        </div>
        {!rowItem?.showMoreTitle && rowItem.title?.split(' ')?.length > 10 ? (
          <span
            style={{
              color: '#6d6d6d',
              fontSize: '13px',
            }}
          >
            {rowItem.title?.split(' ')?.slice(0, 5).join(' ')}
            <small
              style={{ color: 'blue', cursor: 'pointer' }}
              onClick={() =>
                setListData(
                  listData?.map((i) => {
                    if (i.boqId === rowItem.boqId) {
                      return {
                        ...rowItem,
                        showMoreTitle: true,
                      };
                    } else {
                      return i;
                    }
                  })
                )
              }
            >
              {' '}
              show more
            </small>
          </span>
        ) : (
          <span
            style={{
              color: '#6d6d6d',
              fontSize: '13px',
            }}
          >
            {rowItem.title}
            {rowItem.title?.split(' ')?.length > 15 && (
              <small
                style={{ color: 'blue', cursor: 'pointer' }}
                onClick={() =>
                  setListData(
                    listData?.map((i) => {
                      if (i.boqId === rowItem.boqId) {
                        return {
                          ...rowItem,
                          showMoreTitle: false,
                        };
                      } else {
                        return i;
                      }
                    })
                  )
                }
              >
                {' '}
                show less
              </small>
            )}
          </span>
        )}
      </Grid>

      <Grid
        item
        title={rowItem.description}
        style={{
          width: '150px',
          wordWrap: 'break-word',
          maxHeight: '100px',
          overflowY: rowItem.showMore ? 'scroll' : 'hidden',
        }}
      >
        <div>
          {' '}
          <small style={{ color: 'rgb(142, 153, 168)', fontSize: '12px' }}>
            Description
          </small>
        </div>
        {!rowItem?.showMore && rowItem.description?.split(' ')?.length > 10 ? (
          <span
            style={{
              color: '#6d6d6d',
              fontSize: '13px',
            }}
          >
            {rowItem.description?.split(' ')?.slice(0, 5).join(' ')}
            <small
              style={{ color: 'blue', cursor: 'pointer' }}
              onClick={() =>
                setListData(
                  listData?.map((i) => {
                    if (i.boqId === rowItem.boqId) {
                      return {
                        ...rowItem,
                        showMore: true,
                      };
                    } else {
                      return i;
                    }
                  })
                )
              }
            >
              {' '}
              show more
            </small>
          </span>
        ) : (
          <span
            style={{
              color: '#6d6d6d',
              fontSize: '13px',
            }}
          >
            {rowItem.description}
            {rowItem.description?.split(' ')?.length > 15 && (
              <small
                style={{ color: 'blue', cursor: 'pointer' }}
                onClick={() =>
                  setListData(
                    listData?.map((i) => {
                      if (i.boqId === rowItem.boqId) {
                        return {
                          ...rowItem,
                          showMore: false,
                        };
                      } else {
                        return i;
                      }
                    })
                  )
                }
              >
                {' '}
                show less
              </small>
            )}
          </span>
        )}
      </Grid>

      <Grid item>
        <TextField
          disabled
          id="filled-basic"
          label="Quantity"
          size="small"
          InputProps={{
            disableUnderline: true,
            style: {
              borderRadius: '7px',
            },
          }}
          type="number"
          value={rowItem.remainingQuantity ?? 'N/A'}
          InputLabelProps={{
            style: {
              color: '#8E99A8',
            },
          }}
          style={{ width: '100px', margin: '5px' }}
          required
        />
      </Grid>

      <Grid item>
        <TextField
          disabled
          label="Unit"
          size="small"
          InputProps={{
            disableUnderline: true,
            style: {
              borderRadius: '7px',
            },
          }}
          InputLabelProps={{
            style: {
              color: '#8E99A8',
            },
          }}
          value={rowItem.unitName ?? 'N/A'}
          style={{ width: '100px', margin: '5px' }}
        />
      </Grid>

      <Grid item>
        <TextField
          id="filled-basic"
          label="Rate"
          size="small"
          disabled
          InputProps={{
            disableUnderline: true,
            style: {
              borderRadius: '7px',
            },
          }}
          required
          type="number"
          value={rowItem.rate}
          InputLabelProps={{
            style: {
              color: '#8E99A8',
            },
          }}
          style={{ width: '80px', margin: '5px' }}
        />
      </Grid>

      <Grid item>
        <TextField
          id="filled-basic"
          label="Amount"
          size="small"
          disabled
          InputProps={{
            disableUnderline: true,
            style: {
              borderRadius: '7px',
            },
          }}
          required
          type="number"
          value={rowItem.amount ?? 'N/A'}
          InputLabelProps={{
            style: {
              color: '#8E99A8',
            },
          }}
          style={{ width: '100px', margin: '5px' }}
        />
      </Grid>

      <Grid item>
        <TextField
          id="filled-basic"
          label="Today's Progress"
          size="small"
          variant="filled"
          InputProps={{
            disableUnderline: true,
            style: {
              borderRadius: '7px',
            },
          }}
          type="number"
          value={progressValue}
          InputLabelProps={{
            style: {
              color: '#8E99A8',
            },
          }}
          onChange={({ target }) => {
            setProgressValue(target.value);
          }}
          style={{ width: '140px', marginLeft: '5px' }}
          required
        />
      </Grid>
      <Grid item>
        <TextareaAutosize
          aria-label="minimum height"
          minRows={3}
          placeholder="Add Remarks"
          style={{
            height: '40px',
            backgroundColor: '#F1F1F1',
            borderRadius: '7px',
            border: 'none',
            outline: 'none',
            padding: '5px 10px',
            margin: '0 5px',
          }}
          // disabled={rowItem.remarks}
          disabled={rowItem.remainingQuantity === 0}
          value={rowItem.remarks}
          onChange={(e) => handleRemarkChange(e.target.value, rowItem.boqId)}
        />
      </Grid>
      <Grid item>
        <input
          accept="image/*"
          className={classes.input1}
          id="icon-button-file1"
          type="file"
          onChange={(e) => handleImage(e, rowItem.boqId)}
        />

        <label htmlFor="icon-button-file1" className={classes.ImagePlaceHolder}>
          {rowItem.isImageVisible ? (
            <img
              src={`${URL?.createObjectURL(rowItem.image)}`}
              alt="project url"
              style={{
                width: '50px',
                height: '50px',
                borderRadius: '50%',
                // marginBottom: "10px",
              }}
            />
          ) : (
            <Grid
              container
              className={classes.imageCard}
              justify="center"
              alignItems="center"
            >
              <Grid
                item
                container
                justify="center"
                alignItems="center"
                direction="column"
                size="small"
              >
                <Button
                  onClick={() => {
                    document.getElementById('icon-button-file1').click();
                  }}
                  className={classes.buttonPurple}
                >
                  Upload
                </Button>
              </Grid>
            </Grid>
          )}
        </label>
      </Grid>

      <Grid item style={{ paddingLeft: '10px' }}>
        <Button
          className={
            rowItem.remainingQuantity === 0
              ? classes.buttonGrey
              : classes.buttonPurple
          }
          style={{ color: '#fff', marginRight: '10px' }}
          // size="small"
          // onClick={() => setOpenUpdateProgress(false)}
          size="small"
          onClick={() =>
            addProgressEntery(
              rowItem.boqId,
              progressValue,
              rowItem.remarks,
              rowItem.image
            )
          }
          disabled={rowItem.remainingQuantity === 0}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
};
const ProgressRowEditable = ({
  classes,
  unitList,
  scopeList,
  rowItem,
  setRowList,
  rowList,

  addProgressEntery,
  openFieldNote,
  handleRemarkChange,
  setListData,
  listData,
  handleImage,
  handleAdd,
  handleEdit,
  handleClose,
  inProgress,
}) => {
  const [quantity, setQuantity] = useState('');
  const [unit, setUnit] = useState('');
  const [description, setDescription] = useState('');
  const [rate, setRate] = useState('');
  const [amount, setAmount] = useState('');
  const [title, setTitle] = useState('');
  const [completionDate, setCompletionDate] = useState('');
  const [LabourContractor, setLabourContractor] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  let dispatch = useDispatch();

  useEffect(() => {
    if (rowItem) {
      setTitle(rowItem.title);
      setRate(rowItem.rate);
      setAmount(rowItem.amount);
      setCompletionDate(rowItem.endDate);
      setUnit(rowItem.unitCode);
      setQuantity(rowItem.quantity);
      setDescription(rowItem.description);
    }
  }, [rowItem]);

  const hasIncompleteFields = [
    quantity,
    unit,
    description,
    rate,
    amount,
    title,
    completionDate,
    LabourContractor,
  ].some((field) => !field);

  let submitDisabled;
  if (rowItem.editMode) {
    submitDisabled = isLoading || !quantity;
  } else {
    submitDisabled = isLoading || hasIncompleteFields;
  }

  const addChangeRequest = async () => {
    setIsLoading(true);
    const finalObject = {
      contractorId: LabourContractor.userId,
      endDate: completionDate,
      workOrderNumber: rowItem.workOrderNumber,
      isRowItem: true,
      boqId: rowItem.boqId,
      boqList: [
        {
          scopeOfWorkId: rowItem.scopeOfWorkId,
          titles: [
            {
              title,
              descriptions: [
                {
                  unitId: unitList.find((u) => u.code === unit)?.unitId,
                  description,
                  rate,
                  quantity,
                  endDate: completionDate,
                },
              ],
            },
          ],
        },
      ],
    };
    const success = await handleAdd(finalObject);
    setIsLoading(false);
    if (success) {
      handleClose();
      dispatch(getListOfBOQ(rowItem.curProject.projectId));
    }
  };

  const handleSubmit = async () => {
    if (rowItem.editMode) {
      const success = await handleEdit({
        quantity,
        contractorBOQId: rowItem.contractorBOQId,
      });
      if (success) {
        dispatch(getListOfBOQ(rowItem.curProject.projectId));
        handleClose();
      }
      return;
    }
    addChangeRequest();
  };

  const handleLabourContractor = (val) => {
    setLabourContractor(val);
  };

  return (
    <Grid style={{ padding: '0px 0px 10px 0px' }}>
      <Grid item xs={4} style={{ margin: '0px 0px 10px 10px' }}>
        {!rowItem.editMode && (
          <FilterByLabourContractorComponent
            value={LabourContractor}
            onChange={handleLabourContractor}
            setInitialUser={(user) => setLabourContractor(user)}
            currentProject={rowItem.curProject}
          />
        )}
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="space-evenly"
        style={{
          maxWidth: '1450px',
          width: 'auto',
          flexWrap: 'nowrap',
          overflow: 'auto',
        }}
      >
        <Grid item>
          <Typography style={{ fontSize: 11 }}>Scope Of Work</Typography>
          <Typography>{rowItem.scopeOfWork}</Typography>
        </Grid>

        <Grid item>
          <TextField
            // select
            label="Title"
            size="small"
            variant="standard"
            InputProps={{
              style: {
                borderRadius: '7px',
              },
            }}
            // style={{padding: "5px"}}
            InputLabelProps={{
              // classes: {
              //   root: classes.label,
              // },
              style: {
                color: '#8E99A8',
              },
            }}
            onChange={(e) => setTitle(e.target.value)}
            value={title}
            style={{
              width: '130px',
              margin: '5px',
              backgroundColor: 'transparent',
            }}
            disabled={rowItem.editMode}
            required={!rowItem.editMode}
          />
        </Grid>
        <Grid item>
          <TextField
            // select
            label="Description"
            size="small"
            variant="standard"
            InputProps={{
              style: {
                borderRadius: '7px',
              },
            }}
            // style={{padding: "5px"}}
            InputLabelProps={{
              // classes: {
              //   root: classes.label,
              // },
              style: {
                color: '#8E99A8',
              },
            }}
            onChange={(e) => setDescription(e.target.value)}
            multiline
            value={description}
            style={{
              width: '130px',
              margin: '5px',
              backgroundColor: 'transparent',
            }}
            disabled={rowItem.editMode}
            required={!rowItem.editMode}
          />
        </Grid>

        <Grid item>
          <TextField
            id="filled-basic"
            label="Quantity"
            size="small"
            variant="standard"
            InputProps={{
              style: {
                borderRadius: '7px',
              },
            }}
            onChange={(e) => setQuantity(e.target.value)}
            value={quantity}
            type="number"
            // style={{padding: "5px"}}
            required
          />
        </Grid>

        <Grid item>
          <TextField
            label="Unit"
            size="small"
            variant="standard"
            InputProps={{
              style: {
                borderRadius: '7px',
                width: 100,
              },
            }}
            value={unit || ''}
            select
            disabled={rowItem.editMode}
          >
            <MenuItem value="" disabled>
              Select Unit
            </MenuItem>
            {unitList.map((menuUnit) => (
              <MenuItem
                onClick={() => setUnit(menuUnit.code)}
                value={menuUnit.code}
              >
                {menuUnit.code}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item>
          <TextField
            id="filled-basic"
            label="Rate"
            size="small"
            variant="standard"
            InputProps={{
              style: {
                borderRadius: '7px',
              },
            }}
            disabled={rowItem.editMode}
            required={!rowItem.editMode}
            type="number"
            // style={{padding: "5px"}}

            onChange={(e) => setRate(e.target.value)}
            value={rate}
          />
        </Grid>

        <Grid item>
          <TextField
            id="filled-basic"
            label="Amount"
            size="small"
            variant="standard"
            InputProps={{
              style: {
                borderRadius: '7px',
              },
            }}
            disabled={rowItem.editMode}
            required={!rowItem.editMode}
            type="number"
            onChange={(e) => setAmount(e.target.value)}
            value={amount} // style={{padding: "5px"}}
          />
        </Grid>
        {!rowItem.editMode && (
          <Grid item>
            <TextField
              id="filled-basic"
              label="Completion Date"
              size="small"
              variant="standard"
              InputProps={{
                style: {
                  borderRadius: '7px',
                },
              }}
              disabled={rowItem.editMode}
              required={!rowItem.editMode}
              InputLabelProps={{
                shrink: true,
              }}
              type="date"
              onChange={(e) => setCompletionDate(e.target.value)}
              value={completionDate}
            />
          </Grid>
        )}

        <Grid item style={{ paddingLeft: '10px' }}>
          <Button
            className={
              submitDisabled ? classes.buttonGrey : classes.buttonPurple
            }
            style={{ color: '#fff', marginRight: '10px' }}
            // size="small"
            // onClick={() => setOpenUpdateProgress(false)}
            size="small"
            onClick={handleSubmit}
            disabled={submitDisabled}
          >
            {rowItem.editMode ? 'Save' : 'Submit'}
            {isLoading && (
              <CircularProgress
                style={{ width: 20, height: 20, marginLeft: 10 }}
              />
            )}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export let getNewProductivity = null;

const Productivity = () => {
  const { isEditMode } = useAccessPerView('PROJECT_INFORMATION');

  const [classifiedProject, setClassifiedProject] = useState('');
  const [currentProject, setCurrentProject] = useState('');
  const [currentProjectBOQ, setCurrentProjectBOQ] = useState('');
  const [currentProjectProgress, setCurrentProjectProgress] = useState('');
  const [currentProjectFinancial, setCurrentProjectFinancial] = useState('');

  const [position, setPosition] = useState(21);

  const [openUpdateProgress, setOpenUpdateProgress] = useState(false);

  const [LabourContractor, setLabourContractor] = useState(0);
  const [LabourContractorProgress, setLabourContractorProgress] = useState(0);
  const [listForProgressModal, setListForProgressModal] = useState(null);
  const [openFieldNote, setOpenFIeldNote] = useState(false);
  const [projectEndDate, setProjectEndDate] = useState(new Date());
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const fileInputRef = useRef(null);
  const [isViewBOQ, setIsViewBOQ] = useState(false);
  const [isOpenLastModal, setIsOpenLastModal] = useState(false);

  const [paginationData, setPaginationData] = useState({
    page: 0,
    rowsPerPage: 20,
    count: 100,
  });

  const [currentProjectProgressGraph, setCurrentProjectProgressGraph] =
    useState('');

  const [currentProjectBudget, setCurrentProjectBudget] = useState('');

  const [selectedRowItem, setSelectedRowItem] = useState(null);
  const [GCListListView, setGCListView] = useState(false);
  const [GCListListView1, setGCListView1] = useState(false);
  const [listData, setListData] = useState([]);
  const [workerOrderId, setWorkerOrderId] = useState('');
  const [isAddBOQSubmit, setIsAddBOQSubmit] = useState(false);
  const [productivityFilterForGCList, setProductivityFilterForGCList] =
    useState('');
  const [preview, setPreview] = useState(null);
  const [actionModal, setActionModal] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [reject, setReject] = useState(0);

  //! INSTANCES
  const classes = useStyles();
  let dispatch = useDispatch();

  const location = useLocation();
  let openCreateBoqModal = false;

  if (location?.state) {
    const { initialModals } = location?.state;
    openCreateBoqModal = initialModals.insertBOQ;
  }

  const [openInsertBOQ, setOpenInsertBOQ] = useState(openCreateBoqModal);

  //! SELECTORS
  const { projectClassificationList } = useSelector(projectReducer);
  const {
    unitList,
    scopeList,
    loading,
    boqList,
    boqProgress,
    boqProgressLoading,
    metrics,
    projectProgressData,
    projectProgressDataLoading,
    projectBudgetData,
    projectBudgetLoading,
    financialGraphData,
    financialGraphLoading,
    bulkUploadLoading,
    productivitySelectedProject,
  } = useSelector(productivityReducer);
  const [rowList, setRowList] = useState([
    {
      rowId: 1,
      boqId: 0,
      scopeOfWorkId: scopeList[0]?.scopeOfWorkId,

      titles: [
        {
          id: uuidv4(),
          scopeOfWorkDetailId: '',
          title: '',
          description: '',
          unitId: 0,
          rate: 0,
          quantity: 0,
          timeline: 0,
          scopeListDetail: [],
          descriptions: [],
          scopeOfWorkOrderId: 1,
          titleOrderId: 1,
          descriptionOrderId: 1,
          EndDate: moment().format('YYYY-MM-DD'),
        },
      ],
    },
  ]);

  const renderFilters = () => {
    return (
      <Grid
        item
        container
        justify="space-between"
        className="input-container-nav"
      >
        <Grid
          item
          style={{ maxWidth: '875px' }}
          container
          justify="space-between"
        >
          <Grid
            item
            xs={4}
            container
            justify="space-between"
            alignItems="center"
            style={{ flexWrap: 'nowrap' }}
          >
            <FilterProjectComponent
              projectClassificationList={projectClassificationList}
              src={
                !classifiedProject[0]?.url
                  ? defaultProjectImage
                  : `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${classifiedProject[0]?.url}`
              }
              value={currentProjectBudget}
              // onChange={classifyProjectBoqUpdates}
              onChange={classifyProject}
            />
          </Grid>
          <Grid
            item
            xs={4}
            container
            justify="space-between"
            alignItems="center"
            style={{ flexWrap: 'nowrap' }}
          >
            <FilterByLabourContractorComponent
              value={LabourContractor}
              onChange={classifyProjectBOQByContractor}
              setInitialUser={(user) => setLabourContractor(user)}
              currentProject={projectClassificationList[0]}
            />
          </Grid>
          <Grid
            item
            xs={4}
            container
            justify="space-between"
            alignItems="center"
            style={{ flexWrap: 'nowrap' }}
          >
            {/* <Grid item xs={2} style={{ textWrap: 'nowrap', margin: '0px 5px' }}>
              <small className="font11">Date Range:</small>
            </Grid>
            <Grid item xs={10}>
              <DatePicker
                dateFormat="MMMM d, yyyy"
                selectsRange={true}
                startDate={new Date()}
                isClearable={true}
                className="font12 date-picker "
              />
            </Grid> */}
          </Grid>
        </Grid>
        <Grid
          style={{ maxWidth: '230px' }}
          item
          container
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <small className="font11">Search:</small>
          </Grid>
          <Grid item style={{ position: 'relative' }}>
            <SearchInput search={''} handleSearch={() => {}} />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const handleImage = (e, id) => {
    // let selectedImage = e.target.files[0];

    if (e.target.files.length) {
      setPreview(URL?.createObjectURL(e.target.files[0]));
      setListData(
        listData?.map((item) =>
          item.boqId === id
            ? { ...item, isImageVisible: true, image: e.target.files[0] }
            : item
        )
      );
      // setRotation(0); // Reset rotation angle
    }
  };
  const aggregatedData = {};
  function getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  let data = {};

  // Ensure that financialGraphData is defined
  if (financialGraphData?.length > 0) {
    financialGraphData.forEach((item) => {
      const month = item.month;
      const costData = item.costbySOW;

      if (!aggregatedData[month]) {
        aggregatedData[month] = {};
      }

      if (Array.isArray(costData)) {
        costData.forEach((costItem) => {
          const label = costItem.label;
          const actualCost = costItem.actualCost;

          if (!aggregatedData[month][label]) {
            aggregatedData[month][label] = 0;
          }

          aggregatedData[month][label] += actualCost;
        });
      } else if (costData) {
        const label = costData.label;
        const actualCost = costData.actualCost;

        if (!aggregatedData[month][label]) {
          aggregatedData[month][label] = 0;
        }

        aggregatedData[month][label] += actualCost;
      }
    });

    const months = financialGraphData.map((item) => item.month); // Use the months from financialGraphData
    const labelSet = new Set();

    // Collect all unique labels across all months
    months.forEach((month) => {
      const labels = Object.keys(aggregatedData[month]);
      labels.forEach((label) => labelSet.add(label));
    });

    const datasets = Array.from(labelSet).map((label) => {
      return {
        label,
        data: months.map((month) => aggregatedData[month][label] || 0),
        backgroundColor: financialGraphData
          .filter(
            (item) =>
              Array.isArray(item.costbySOW) &&
              item.costbySOW.some((cost) => cost.label === label)
          )
          .map((item) => {
            const costItem = item.costbySOW.find(
              (cost) => cost.label === label
            );
            return costItem ? `#${costItem.colorCode}` : 'red'; // fallback to red if colorCode not found
          }),

        stack: '1',
      };
    });

    // Define your chart data and options
    data = {
      labels: months,
      datasets,
    };
  } else {
    // Handle the case where financialGraphData is empty
    // You might want to display an error message or take other appropriate actions.
  }

  const options = {
    plugins: {
      title: {
        display: true,
        text: 'Skilled by workers',
      },
    },
    legend: {
      display: false,
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        beginAtZero: true,
      },
      yAxes: [
        {
          ticks: {
            callback: (label, index, labels) => {
              return '₹ ' + label;
            },
          },
        },
      ],
    },
    indexAxis: 'y',
  };
  // let cardData = [
  //   {
  //     title: 'Uncertified Works',
  //     count: `₹ 0`,
  //     icon: <img src={uncertifiedWork} alt="issue" />,
  //     filter: 'UncertifiedWorkers',
  //   },
  //   {
  //     title: ' Unbilled Works',
  //     count: `₹ 0`,
  //     icon: <img src={unbilledWorker} alt="issue" />,
  //     filter: 'UnbilledWorkers',
  //   },
  //   {
  //     title: ' Poor Workmanship',
  //     count: `₹ ${metrics?.workmanshipCost?.toLocaleString()}`,
  //     icon: <img src={workmanshipCost} alt="issue" />,
  //     filter: 'WorkmanshipCost',
  //     isVisible: true,
  //   },
  //   {
  //     title: 'Material Defects',
  //     count: `₹ ${metrics?.qualityCost?.toLocaleString()}`,
  //     icon: <img src={materialDefect} alt="defect" />,
  //     filter: 'MaterialDefect',
  //     isVisible: true,
  //   },
  //   {
  //     title: 'Variations',
  //     count: `₹ ${metrics?.excessCost?.toLocaleString()}`,
  //     icon: <img src={variationCost} alt="defect" />,
  //     filter: 'Variations',
  //     isVisible: true,
  //   },
  //   {
  //     title: 'Change Requests',
  //     count: `₹ 0`,
  //     icon: <img src={changeRequest} alt="defect" />,
  //     filter: 'changeRequest',
  //     isVisible: true,
  //   },
  // ];

  //! LIFECYCLE
  function fetchInitialData() {
    if (projectClassificationList.length === 0)
      dispatch(getProjectsClassificationList());
    if (unitList.length === 0) dispatch(getUnitList());

    if (projectClassificationList.length > 0) {
      dispatch(selectProductivityParentProject(projectClassificationList[0]));

      setCurrentProjectProgress(
        productivitySelectedProject ?? projectClassificationList[0]
      );
      setCurrentProject(
        productivitySelectedProject ?? projectClassificationList[0]
      );

      setCurrentProjectBOQ(
        productivitySelectedProject ?? projectClassificationList[0]
      );
      setCurrentProjectProgressGraph(
        productivitySelectedProject ?? projectClassificationList[0]
      );
      dispatch(
        getBOQProgress(
          productivitySelectedProject?.hasOwnProperty('projectId')
            ? productivitySelectedProject?.projectId
            : projectClassificationList[0]?.projectId
        )
      );
      dispatch(
        getContractors(
          productivitySelectedProject?.hasOwnProperty('projectId')
            ? productivitySelectedProject?.projectId
            : projectClassificationList[0]?.projectId
        )
      );
      dispatch(
        getSOWListByBOQ(
          productivitySelectedProject?.hasOwnProperty('projectId')
            ? productivitySelectedProject?.projectId
            : projectClassificationList[0]?.projectId,
          LabourContractor?.userId,
          paginationData.page + 1,
          paginationData.rowsPerPage
        )
      );
      dispatch(
        getBOQMetrics(
          productivitySelectedProject?.hasOwnProperty('projectId')
            ? productivitySelectedProject?.projectId
            : projectClassificationList[0]?.projectId
        )
      );
      // setCurrentProjectProgressGraph(
      //   productivitySelectedProject?.projectId
      //     ? productivitySelectedProject?.projectId
      //     : projectClassificationList[0]?.projectId
      // );
      dispatch(
        getProjectProgressGraph(
          productivitySelectedProject?.hasOwnProperty('projectId')
            ? productivitySelectedProject?.projectId
            : projectClassificationList[0]?.projectId
        )
      );
      dispatch(
        getProjectBudget(
          productivitySelectedProject?.hasOwnProperty('projectId')
            ? productivitySelectedProject?.projectId
            : projectClassificationList[0]?.projectId
        )
      );
      setCurrentProjectBudget(
        productivitySelectedProject ?? projectClassificationList[0]
      );
      setCurrentProjectFinancial(
        productivitySelectedProject ?? projectClassificationList[0]
      );
      dispatch(
        getFinancialProgressData(
          productivitySelectedProject?.hasOwnProperty('projectId')
            ? productivitySelectedProject?.projectId
            : projectClassificationList[0]?.projectId
        )
      );
    }
  }

  const [activeBoq, setActiveBoq] = useState(null);

  useEffect(() => {
    getNewProductivity = () => fetchInitialData();
    fetchInitialData();
  }, [projectClassificationList?.length]);

  useEffect(() => {
    if (productivitySelectedProject?.projectId) {
      setCurrentProject(productivitySelectedProject);
    }
  }, [productivitySelectedProject?.projectId]);

  useEffect(() => {
    if (scopeList.length === 0) dispatch(getScopeList());
  }, []);
  const grossProgress = {};

  useEffect(() => {
    if (scopeList.length > 0) {
      setRowList([
        {
          rowId: 1,
          boqId: 0,
          scopeOfWorkId: scopeList[0]?.scopeOfWorkId,
          titles: [
            {
              id: uuidv4(),
              scopeOfWorkDetailId: '',
              title: '',
              description: '',
              unitId: 0,
              rate: 0,
              quantity: 0,
              timeline: 0,
              scopeListDetail: [],
              descriptions: [],
              scopeOfWorkOrderId: 1,
              titleOrderId: 1,
              descriptionOrderId: 1,
              EndDate: moment().format('YYYY-MM-DD'),
            },
          ],
        },
      ]);
    }
    if (boqList?.data) {
      setListData(
        boqList.data.map((item) => ({
          ...item,
          showMore: false,
          showMoreTitle: false,
          isImageVisible: false,
        }))
      );
    }
  }, [scopeList.length, boqList]);
  const classifyProject = (project) => {
    if (project) {
      dispatch(selectProductivityParentProject(project));
      setCurrentProject(project);
      dispatch(getBOQProgress(project?.projectId));
      dispatch(getBOQMetrics(project?.projectId));
      classifyProjectBOQ(project);
      classifyProjectProgress(project);
      classifyProjectProgressGraph(project);
      classifyProjectBudget(project);
      classifyProjectFinancial(project);
    } else {
      dispatch(getBOQProgress(projectClassificationList[0]?.projectId));
      setCurrentProject(projectClassificationList[0]);
      dispatch(getBOQMetrics(projectClassificationList[0]?.projectId));

      classifyProjectBOQ(projectClassificationList[0]);
      classifyProjectProgress(projectClassificationList[0]);
      classifyProjectProgressGraph(projectClassificationList[0]);
      classifyProjectBudget(projectClassificationList[0]);
      classifyProjectFinancial(projectClassificationList[0]);
      dispatch(selectProductivityParentProject(projectClassificationList[0]));
    }
  };

  const handleSubmitBOQ = async () => {
    let alertToast = toast(
      <Grid container>
        <Grid container xs={12}>
          {' '}
          <Typography>Are you sure ? You want to create boq's</Typography>
        </Grid>
        <Grid
          container
          justify="flex-end"
          alignItems="center"
          style={{ marginTop: '10px' }}
        >
          <Button
            size="small"
            style={{
              backgroundColor: 'tomato',
              textTransform: 'capitalize',
              color: '#fff',
              marginRight: '10px',
            }}
            onClick={() => toast.remove(alertToast)}
          >
            Cancel
          </Button>
          <Button
            size="small"
            style={{
              backgroundColor: '#81B734',
              textTransform: 'capitalize',
              color: '#fff',
            }}
            onClick={() => bubmitThBOQ()}
          >
            Confirm
          </Button>
        </Grid>
      </Grid>
    );

    async function bubmitThBOQ() {
      toast.remove(alertToast);

      let loadingToast = toast.loading('Submitting...');
      let changedRowList = [];

      rowList.forEach((item) => {
        // Process titles first
        item?.titles?.forEach((title) => {
          changedRowList.push({
            contractorBOQId: 0,
            scopeOfWorkId: item.scopeOfWorkId,
            unitId: title.unitId,
            description: title.description,
            title: title.title,
            rate: parseInt(title.rate),
            quantity: parseInt(title.quantity),
            scopeOfWorkOrderId: title.scopeOfWorkOrderId,
            titleOrderId: title.titleOrderId,
            descriptionOrderId: title.descriptionOrderId,
            EndDate: title.EndDate,
          });

          // Then process descriptions if they exist
          if (title.descriptions) {
            title.descriptions.forEach((desc) => {
              changedRowList.push({
                contractorBOQId: 0,
                scopeOfWorkId: item.scopeOfWorkId,
                unitId: desc.unitId,
                description: desc.description,
                title: title.title,
                rate: parseInt(desc.rate),
                quantity: parseInt(desc.quantity),
                scopeOfWorkOrderId: title.scopeOfWorkOrderId,
                titleOrderId: title.titleOrderId,
                descriptionOrderId: title.descriptionOrderId + 1,
                EndDate: desc.EndDate,
              });
            });
          }
        });
      });

      function transformData(inputArray) {
        // Create the transformed object structure
        let transformedArray = [];
        // Loop through the inputArray
        inputArray.forEach((item, index) => {
          const transformedItem = {
            contractorBOQId: item.boqId,
            scopeOfWorkId: item.scopeOfWorkId,
            titles: item.titles.map((title, index) => {
              let transformedDescriptions = [];
              if (
                !transformedDescriptions.some((item) => item.id === title.id)
              ) {
                transformedDescriptions.push({
                  unitId: title.unitId,
                  description: title.description,
                  rate: parseFloat(title.rate),
                  quantity: parseFloat(title.quantity),
                  EndDate: title.EndDate,
                });
              }
              if (title.descriptions && title.descriptions.length > 0) {
                let temp = title.descriptions.map((description) => ({
                  unitId: description.unitId,
                  description: description.description,
                  rate: parseFloat(description.rate),
                  quantity: parseFloat(description.quantity),
                  EndDate: description.EndDate,
                }));
                transformedDescriptions = [...transformedDescriptions, ...temp];
              }
              const transformedTitle = {
                title: title.title,
                descriptions: transformedDescriptions,
              };
              return transformedTitle;
            }),
            titleOrderId: index, // You might need to assign a specific value here
            descriptionOrderId: 0, // You might need to assign a specific value here
          };

          transformedArray.push(transformedItem);
        });

        return transformedArray;
      }

      const transformedData = transformData(rowList);

      // const restructuredArray = rowList.map((item) => ({
      //   contractorBOQId: item.boqId,
      //   scopeOfWorkId: item.scopeOfWorkId,
      //   titles: item.titles.map((title) => ({
      //     title: title.title,
      //     descriptions: title.descriptions.map((desc) => ({
      //       unitId: desc.unitId,
      //       description: desc.description,
      //       rate: desc.rate,
      //       quantity: desc.quantity,
      //     })),
      //   })),
      //   titleOrderId: item.titles[0]?.titleOrderId || 0,
      //   descriptionOrderId: item.titles[0]?.descriptionOrderId || 0,
      // }));

      // console.log("Restructured Array", restructuredArray);
      if (!LabourContractor?.userId) {
        return toast.error('Please Select Labour Contractor', {
          position: 'top-right',
        });
      }

      let noValidationError = false;
      transformedData.forEach((item, index) => {
        item.titles.forEach((titleObj) => {
          if (
            titleObj.title === '' ||
            titleObj.descriptions.some((desc) => desc.description === '')
          ) {
            noValidationError = true;
            return toast.error('Please enter title and description', {
              position: 'top-right',
            });
          }
        });
      });

      if (noValidationError) return;

      let schemaObject = {
        boqList: transformedData,
        projectId: currentProjectBOQ?.projectId,
        contractorId: LabourContractor?.userId,
        endDate: projectEndDate,
        workOrderNumber: workerOrderId,
      };

      let resp = await dispatch(addBOQ(schemaObject));
      if (resp?.status === 200) {
        setIsAddBOQSubmit(false);
        toast.remove(loadingToast);
        fetchInitialData();
        toast.success('BOQ created successfully!', {
          position: 'top-right',
        });
        setRowList([
          {
            rowId: 1,
            boqId: 0,
            scopeOfWorkId: scopeList[0]?.scopeOfWorkId,
            titles: [
              {
                id: uuidv4(),
                scopeOfWorkDetailId: '',
                title: '',
                description: '',
                unitId: 0,
                rate: 0,
                quantity: 0,
                timeline: 0,
                scopeListDetail: [],
                descriptions: [],
                EndDate: moment().format('YYYY-MM-DD'),
              },
            ],
          },
        ]);
        setOpenInsertBOQ(false);
      } else if (resp?.status === 404 || resp?.status === 400) {
        toast.error(resp?.data?.error, {
          position: 'top-right',
        });
      } else {
        toast.error("Some Went Wrong While Saving BOQ's", {
          position: 'top-right',
        });
      }
    }
  };
  const handleLabourContractor = (val) => {
    setLabourContractor(val);
  };

  const classifyProjectBOQ = (project) => {
    if (project) {
      dispatch(selectProductivityParentProject(project));
      setCurrentProjectBOQ(project);
      dispatch(getContractors(project?.projectId));
      setCurrentProject(project);
      dispatch(getBOQProgress(project?.projectId));
      dispatch(getBOQMetrics(project?.projectId));
      classifyProjectProgress(project);
      classifyProjectProgressGraph(project);
      classifyProjectBudget(project);
      classifyProjectFinancial(project);
    } else {
      dispatch(getBOQProgress(projectClassificationList[0]?.projectId));
      setCurrentProject(projectClassificationList[0]);
      dispatch(getBOQMetrics(projectClassificationList[0]?.projectId));

      classifyProjectProgress(projectClassificationList[0]);
      classifyProjectProgressGraph(projectClassificationList[0]);
      classifyProjectBudget(projectClassificationList[0]);
      classifyProjectFinancial(projectClassificationList[0]);
      dispatch(selectProductivityParentProject(projectClassificationList[0]));

      setCurrentProjectBOQ(projectClassificationList[0]);
      setLabourContractor(null);
      dispatch(selectProductivityParentProject(projectClassificationList[0]));
    }
  };
  const classifyProjectProgress = async (project) => {
    setLabourContractorProgress(0);
    setListForProgressModal(null);
    setPaginationData({
      page: 0,
      rowsPerPage: 20,
      count: 100,
    });
    if (project) {
      dispatch(getSOWListByBOQ(project?.projectId));
      setCurrentProjectProgress(project);
      dispatch(selectProductivityParentProject(project));
    } else {
      dispatch(getSOWListByBOQ(projectClassificationList[0]?.projectId));

      setCurrentProjectProgress(projectClassificationList[0]);
      dispatch(selectProductivityParentProject(projectClassificationList[0]));
    }
  };

  const addProgressEntery = async (
    boqId,
    value,
    remarks,
    image,
    sowId,
    errorFallback
  ) => {
    let formData = new FormData();
    formData.append('boqProgressId', 0);
    formData.append('quantity', parseInt(value));
    formData.append('boqId', boqId);
    formData.append('remarks', remarks);
    formData.append('image', image);

    let resp = await dispatch(addProgress(formData));
    if (resp?.status !== 200) {
      errorFallback();
    }
  };

  const renderSuccessPrompt = () => {
    toast.success('New item has been added!');
  };

  const renderFailurePrompt = () => {
    toast.error('A problem occured while adding the boq entry');
  };

  const handleAdd = async (request) => {
    request.projectId = currentProjectBOQ.projectId;
    try {
      let resp = await dispatch(addUpdateBoq(request));
      if (resp.status === 200) {
        renderSuccessPrompt();
        return true;
      } else {
        renderFailurePrompt();
        return false;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleEdit = async (request) => {
    try {
      const response = await dispatch(updateChangeRequestQuantity(request));
      if (!response.data.success) {
        toast.error(response.data.errors[0]);
        return false;
      } else {
        toast.success('Change request quantity updated');
        return true;
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const addBulkProgressEntries = async () => {
    const bulkUpdateList = Object.entries(grossProgress).map(
      ([boqId, { quantity, remarks, setErrorFallback, sowId }]) => ({
        boqProgressId: 0,
        quantity: parseInt(quantity),
        boqId: parseInt(boqId),
        remarks,
        setErrorFallback,
      })
    );

    const initiateEmptyProgressErrorFeedback = (erroneuosRows) => {
      erroneuosRows.forEach((titleOrDescriptionRow) => {
        titleOrDescriptionRow.setErrorFallback((prev) => ({
          ...prev,
          progress: true,
        }));
      });
      toast.error(
        'Incomplete Data Detected. Please ensure all entries contain progress data before proceeding.'
      );
    };

    const initiateGeneralErrorFeedback = (erroneuosRows) => {
      erroneuosRows.forEach((titleOrDescriptionRow) => {
        titleOrDescriptionRow.setErrorFallback({
          remarks: true,
          progress: true,
        });
      });
      toast.error('An error occured while saving the bulk progress entries.');
    };

    const emptyQuantities = bulkUpdateList.filter((boq) => !boq.quantity);

    if (emptyQuantities.length > 0) {
      initiateEmptyProgressErrorFeedback(emptyQuantities);
      return;
    }
    try {
      let resp = await dispatch(
        uploadBulkProgress(JSON.stringify({ bulkUpdateList }))
      );
      if (resp?.status === 200) {
        dispatch(
          getSOWListByBOQ(
            currentProjectBudget?.projectId,
            LabourContractorProgress.userId
          )
        );
      } else {
        initiateGeneralErrorFeedback(bulkUpdateList);
      }
    } catch (err) {
      toast.error('Something went wrong!');
    }
  };

  const onChangeDatePayments = (date) => {
    if (!date) {
      setProjectEndDate(null);
    }

    setProjectEndDate(date);
  };
  //! ADD BOQ FUNTIONS

  const pushNewRow = () => {
    setRowList((prevList) => {
      const prevScopes = prevList.map((list) => list.scopeOfWorkId);
      const [newScope] = scopeList.filter(
        (list) => !prevScopes.includes(list.scopeOfWorkId)
      );
      return [
        ...prevList,
        {
          rowId: rowList[rowList.length - 1].rowId + 1,
          boqId: 0,
          scopeOfWorkId: newScope?.scopeOfWorkId,
          titles: [
            {
              id: uuidv4(),
              scopeOfWorkDetailId: '',
              title: '',
              description: '',
              unitId: 0,
              rate: 0,
              quantity: 0,
              timeline: 0,
              scopeListDetail: [],
              descriptions: [],
              scopeOfWorkOrderId: rowList[rowList.length - 1].rowId + 1,
              titleOrderId: 1,
              descriptionOrderId: 1,
              EndDate: moment().format('YYYY-MM-DD'),
            },
          ],
        },
      ];
    });
  };

  const addTitle = (rowId) => {
    let updatedData = rowList.map((row) => {
      if (row.rowId === rowId) {
        return {
          ...row,
          titles: [
            ...row.titles,
            {
              id: uuidv4(),
              scopeOfWorkDetailId: '',
              title: '',
              description: '',
              unitId: 0,
              rate: 0,
              quantity: 0,
              timeline: 0,
              scopeListDetail: [],
              descriptions: [],
              scopeOfWorkOrderId: rowId,
              titleOrderId: row.titles.length + 1,
              descriptionOrderId: 1,
            },
          ],
        };
      }
      return row;
    });
    setRowList(updatedData);
  };

  const addTitleDescription = (rowId, titleId) => {
    let updatedList = rowList.map((item) => {
      if (item.rowId === rowId) {
        return {
          ...item,
          titles: item.titles.map((title) => {
            if (title.id === titleId) {
              return {
                ...title,
                descriptionOrderId: title.descriptions.length + 1,
                descriptions: [
                  ...title.descriptions,
                  {
                    id: uuidv4(),
                    boqId: 0,
                    scopeOfWorkId: 0,
                    scopeOfWorkDetailId: '',
                    description: '',
                    unitId: 0,
                    rate: 0,
                    quantity: 0,
                    timeline: 0,
                    scopeOfWorkOrderId: rowId,
                    titleOrderId: title.titleOrderId,
                    descriptionOrderId: title.descriptions.length + 1,
                    EndDate: moment().format('YYYY-MM-DD'),
                  },
                ],
              };
            }
            return title;
          }),
        };
      }
      return item;
    });
    setRowList(updatedList);
  };
  // console.log("Row list---->>>", rowList);

  const deleteRow = (rowId) => {
    let updatedData = rowList.filter((item) => item.rowId !== rowId);
    setRowList(updatedData);
  };

  const deleteTitle = (rowId, titleId) => {
    let updatedData = rowList.map((item) => {
      if (item.rowId === rowId) {
        return {
          ...item,
          titles: item.titles.filter((title) => title.id !== titleId),
        };
      }
      return item;
    });
    setRowList(updatedData);
  };
  const deleteTitleDescription = (rowId, titleId, descId) => {
    let updatedData = rowList.map((row) => {
      if (row.rowId === rowId) {
        return {
          ...row,
          titles: row.titles.map((title) => {
            if (title.id === titleId) {
              return {
                ...title,
                descriptions: title.descriptions.filter(
                  (desc) => desc.id !== descId
                ),
              };
            }
            return title;
          }),
        };
      }
      return row;
    });
    setRowList(updatedData);
  };

  const updateTitleValue = (rowId, titleId, valueName, value) => {
    let updatedData = rowList.map((row) => {
      if (row.rowId === rowId) {
        return {
          ...row,
          titles: row.titles.map((title) => {
            if (title.id === titleId) {
              return {
                ...title,
                [valueName]: value,
              };
            }
            return title;
          }),
        };
      }
      return row;
    });

    setRowList(updatedData);
  };

  const updateTitleDescValue = (rowId, titleId, descId, valueName, value) => {
    let updatedData = rowList.map((row) => {
      if (row.rowId === rowId) {
        return {
          ...row,
          titles: row.titles.map((title) => {
            if (title.id === titleId) {
              return {
                ...title,
                descriptions: title.descriptions.map((description) => {
                  if (description.id === descId) {
                    return {
                      ...description,
                      [valueName]: value,
                    };
                  }
                  return description;
                }),
              };
            }
            return title;
          }),
        };
      }
      return row;
    });

    setRowList(updatedData);
  };
  const updateScopeWorkValue = (rowId, value) => {
    let updatedArray = rowList.map((item) => {
      if (item.rowId === rowId) {
        return {
          ...item,
          scopeOfWorkId: value,
        };
      }
      return item;
    });
    setRowList(updatedArray);
  };
  //! END
  const handleDateRange = (update) => {
    let [start, end] = update;
    setDateRange(update);
  };
  function truncateString(str, maxLength) {
    if (str?.length > maxLength) {
      return str.slice(0, maxLength) + '...';
    } else {
      return str;
    }
  }

  const getBarData = () => {
    return projectProgressData?.graphData?.map((item) => ({
      x: truncateString(item.label, 6),
      skill: truncateString(item.label, 6),
      percentage: item.overallProgress ?? 0,
      color: item.colorCode,
    }));
  };

  // Find the maximum value

  const maxBudgetedCost =
    projectBudgetData?.graphData.length &&
    Math.max(...projectBudgetData.graphData.map((item) => item.budgetedCost));
  const maxActualCost =
    projectBudgetData?.graphData.length &&
    Math.max(...projectBudgetData.graphData.map((item) => item.actualCost));
  const maxCommittedCost =
    projectBudgetData?.graphData.length &&
    Math.max(...projectBudgetData.graphData.map((item) => item.committedCost));
  const maxValue = Math.max(maxBudgetedCost, maxActualCost, maxCommittedCost);

  const getBarDataBudget = () => {
    return projectBudgetData?.graphData?.map((item) => {
      let percent =
        item.budgetedCost <= 0 ? 0 : (item.budgetedCost / maxValue) * 100;
      return {
        x: truncateString(item.label, 6),
        skill: truncateString(item.label, 6),
        actualValue: item.budgetedCost <= 0 ? 0 : item.budgetedCost,
        percentage: percent === 0 ? 0 : percent <= 1 ? 1.3 : percent,
      };
    });
  };

  const getBarDataActualCost = () => {
    return projectBudgetData?.graphData?.map((item) => {
      let percent =
        item.actualCost <= 0 ? 0 : (item.actualCost / maxValue) * 100;
      return {
        x: truncateString(item.label, 6),
        skill: truncateString(item.label, 6),
        actualValue: item.actualCost <= 0 ? 0 : item.actualCost,
        percentage: percent === 0 ? 0 : percent <= 1 ? 1.3 : percent,
      };
    });
  };
  const getBarDataCommittedCost = () => {
    return projectBudgetData?.graphData?.map((item) => {
      let percent =
        item.committedCost <= 0 ? 0 : (item.committedCost / maxValue) * 100;
      return {
        x: truncateString(item.label, 6),
        skill: truncateString(item.label, 6),
        actualValue: item.committedCost <= 0 ? 0 : item.committedCost,
        percentage: percent === 0 ? 0 : percent <= 1 ? 1.3 : percent,
      };
    });
  };

  const classifyProjectProgressGraph = (project) => {
    if (project) {
      setCurrentProjectProgressGraph(project);
      dispatch(getProjectProgressGraph(project?.projectId));
    } else {
      setCurrentProjectProgressGraph(projectClassificationList[0]);
      dispatch(
        getProjectProgressGraph(projectClassificationList[0]?.projectId)
      );
    }
  };
  const classifyProjectBudget = (project) => {
    if (project) {
      setCurrentProjectBudget(project);
      dispatch(getProjectBudget(project?.projectId));
    } else {
      setCurrentProjectBudget(projectClassificationList[0]);
      dispatch(getProjectBudget(projectClassificationList[0]?.projectId));
    }
  };
  const classifyProjectBoqUpdates = (project) => {
    if (project) {
      classifyProject(project);
      dispatch(
        getSOWListByBOQ(
          project?.hasOwnProperty('projectId')
            ? project?.projectId
            : projectClassificationList[0]?.projectId,
          LabourContractor?.userId,
          paginationData.page + 1,
          paginationData.rowsPerPage
        )
      );
      setCurrentProjectBudget(project);
    } else {
      classifyProject(null);
      setCurrentProjectBudget(projectClassificationList[0]);
    }
  };
  const classifyProjectBOQByContractor = (contractor) => {
    if (contractor) {
      handleLabourContractor(contractor);
      dispatch(
        getSOWListByBOQ(currentProjectBOQ.projectId, contractor?.userId)
      );
    }
  };
  const classifyProjectFinancial = (project) => {
    if (project) {
      setCurrentProjectFinancial(project);
      dispatch(getFinancialProgressData(project?.projectId));
    } else {
      setCurrentProjectFinancial(projectClassificationList[0]);
      dispatch(
        getFinancialProgressData(projectClassificationList[0]?.projectId)
      );
    }
  };
  const handleFileUpload = (event) => {
    if (LabourContractor === 0) {
      return toast.error('Labour Contractor is required');
    }
    if (workerOrderId === '') {
      return toast.error('Worker Order is required');
    }
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();

    formData.append('File', file);
    formData.append('ProjectId', currentProjectBOQ?.projectId);
    formData.append('ContractorId', LabourContractor?.userId ?? 0);
    formData.append('EndDate', moment(projectEndDate).format('DD MMM, YYYY'));
    formData.append('WorkOrderNumber', workerOrderId);

    let resp = await dispatch(uploadBulkBOQ(formData));
    if (resp.status === 200 && resp.data.isSuccess) {
      toast.success("boq's uploaded successfully");
      classifyProject(currentProjectBOQ);
      setOpenInsertBOQ(false);
    } else {
      toast.error(
        'Something went wrong, Please upload file again with proper format.'
      );
    }
    // console.log("resp", resp);
  };

  const handleRemarkChange = (value, id) => {
    setListData(
      listData?.map((item) =>
        item.boqId === id ? { ...item, remarks: value } : item
      )
    );
  };

  const handleChangePage = (event, newPage) => {
    setPaginationData({
      ...paginationData,
      page: event.target.value ?? newPage,
    });

    dispatch(
      getSOWListByBOQ(
        currentProject.projectId,
        LabourContractor?.userId,
        event.target.value + 1 ?? newPage + 1,
        paginationData.rowsPerPage
      )
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setPaginationData({
      ...paginationData,
      rowsPerPage: Number(event.target.value),
    });
    dispatch(
      getSOWListByBOQ(
        currentProject.projectId,
        LabourContractor?.userId,
        paginationData.page + 1,
        Number(event.target.value)
      )
    );
  };

  const openGCListModal = (filter) => {
    setGCListView1(true);
    setProductivityFilterForGCList(filter);
  };

  const flattenBOQObject = (boq) => {
    if (!boq) return [];
    const flattened = [];
    boq.descriptions.forEach((desc) => {
      flattened.push({
        boqId: boq.boqId,
        scopeOfWorkName: boq.scopeOfWork,
        title: boq.title,
        description: desc.description.trim(),
        contractorBOQId: desc.contractorBOQId,
        remainingQuantity: desc.quantity,
        rate: desc.rate,
        unitCode: desc.unitCode,
        unitId: desc.unitId,
        unitName: desc.unitCode,
        boqCode: desc.boqCode,
        boqNumber: desc.boqNumber,
        workOrderNumber: desc.workOrderNumber,
        actualQuantity: desc.actualQuantity,
        actualAmount: desc.actualAmount,
        amount: desc.amount,
        endDate: desc.endDate,
        percentage: desc.percentage,
      });
    });
    return flattened;
  };

  const renderEditBoqView = (boq) => {
    let boqList = [];
    boqList = flattenBOQObject(boq);

    console.log('boq', boq);

    return (
      <Dialog
        open={openUpdateProgress}
        onClose={() => setOpenUpdateProgress(false)}
        keepMounted
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle
          style={{
            padding: '15px',
          }}
          id="customized-dialog-title"
          onClose={() => setOpenUpdateProgress(false)}
        >
          <Grid container alignItems="center">
            <Grid container alignItems="center" xs={6}>
              <Grid item>Change Request</Grid>
            </Grid>
            <Grid item xs={6} container justify="flex-end" alignItems="center">
              <CloseIcon
                onClick={() => setOpenUpdateProgress(false)}
                style={{ cursor: 'pointer' }}
              />
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent style={{ padding: '8px 10px', minHeight: 100 }}>
          {loading ? (
            <Typography variant="subtitle1" align="center">
              Loading...
            </Typography>
          ) : !boqList || boqList?.length === 0 ? (
            <Typography variant="subtitle1" align="center">
              No BOQ Found!
            </Typography>
          ) : (
            <ProgressRowEditable
              rowItem={boq}
              unitList={unitList}
              classes={classes}
              handleAdd={handleAdd}
              handleEdit={handleEdit}
              handleClose={() => setOpenUpdateProgress(false)}
            />
          )}
        </DialogContent>
      </Dialog>
    );
  };

  const renderInsertBoqView = () => {
    return (
      <>
        <DialogTitle
          style={{
            padding: '10px 15px',
          }}
          id="customized-dialog-title"
          onClose={() => setOpenInsertBOQ(false)}
        >
          <Grid container style={{ marginBottom: '10px' }} alignItems="center">
            <Typography
              variant="subtitle1"
              style={{ marginRight: '10px', fontSize: 'larger' }}
            >
              Insert BoQ's
            </Typography>
          </Grid>

          <Grid container alignItems="center" xs={12} justify="space-between">
            <Grid item style={{ paddingRight: '.5rem' }}>
              <FilterProjectComponent
                projectClassificationList={projectClassificationList}
                src={
                  !classifiedProject[0]?.url
                    ? defaultProjectImage
                    : `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${classifiedProject[0]?.url}`
                }
                value={currentProjectBOQ}
                // onChange={classifyProjectBOQ}
                onChange={classifyProject}
              />
            </Grid>

            <Grid item md={2}>
              <FilterByLabourContractorComponent
                value={LabourContractor}
                onChange={handleLabourContractor}
                // setInitialUser={(user) => setLabourContractor(user)}
                currentProject={projectClassificationList[0]}
                label="Select Contractor"
              />
            </Grid>
            <Grid item>
              <TextField
                style={{ width: '120px' }}
                fullWidth
                value={workerOrderId}
                // style={{ width: "120px" }}
                onChange={(e) => setWorkerOrderId(e.target.value)}
                label="Work Order ID"
              />
            </Grid>
            <Grid item xs={1}>
              <FormControl>
                <FormGroup style={{ width: '120px', fontFamily: 'Roboto' }}>
                  <FormControlLabel
                    style={{ width: '120px', fontFamily: 'Roboto' }}
                    control={
                      <Switch

                      // checked={state.gilad}
                      // onChange={handleChange}
                      // name="gilad"
                      />
                    }
                    label={
                      <small
                        style={{
                          fontWeight: 400,
                          fontSize: '10px',
                          width: '120px',
                          fontFamily: 'Roboto',
                        }}
                      >
                        With Material
                      </small>
                    }
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid
              item
              md={2}
              xs={12}
              // className={classes.marginTop}
              style={{ paddingLeft: '1rem' }}
              container
              alignItems="center"
            >
              {' '}
              <Grid item xs={5}>
                <small style={{ fontSize: '10px' }}>Completion Date:</small>
              </Grid>
              <Grid item md={7} xs={12} style={{ paddingLeft: '.1rem' }}>
                <DatePicker
                  selected={projectEndDate}
                  onChange={onChangeDatePayments}
                  isClearable={true}
                  className="border-datepicker"
                />
              </Grid>
            </Grid>
            <Grid item>
              <Input
                type="file"
                inputRef={fileInputRef}
                accept=".xlsx, application/vnd.ms-excel"
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
              <Button
                onClick={handleFileUpload}
                startIcon={
                  bulkUploadLoading ? (
                    <CircularProgress size="20px" style={{ color: '#ddd' }} />
                  ) : (
                    <CloudUploadIcon />
                  )
                }
                className={classes.buttonGreen}
                // size="small"
                // style={{ padding: "5px" }}
              >
                Upload Bulk Boqs
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent
          style={{
            padding: '15px',
            minHeight: '200px',
            width: '1350px',
          }}
        >
          {loading ? (
            <>Loading...</>
          ) : (
            rowList?.map((item, index) => (
              <Row
                key={item.rowId}
                rowIndex={index}
                rowItem={item}
                classes={classes}
                icon={general}
                updateScopeWorkValue={updateScopeWorkValue}
                //! DATA
                unitList={unitList}
                scopeList={scopeList}
                rowList={rowList}
                setRowList={setRowList}
                //! METHODS
                addTitle={addTitle}
                addTitleDescription={addTitleDescription}
                deleteRow={deleteRow}
                deleteTitle={deleteTitle}
                deleteTitleDescription={deleteTitleDescription}
                updateTitleDescValue={updateTitleDescValue}
                updateTitleValue={updateTitleValue}
              />
            ))
          )}
        </DialogContent>
        <DialogActions>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            style={{
              marginRight: '.2rem',
            }}
          >
            <Grid item>
              <Grid
                item
                container
                alignItems="center"
                justify="center"
                style={{
                  // width: "197px",
                  height: '45px',
                  backgroundColor: 'rgb(247,247,248)',
                  margin: '5px',
                  borderRadius: '7px',
                  border: '1px dashed rgba(172, 187, 195, 1)',
                  // position: "relative",
                  padding: '0 5px',
                  cursor: 'pointer',
                }}
                onClick={pushNewRow}
              >
                <AddBoxIcon
                  style={{
                    color: '#1069E3',
                    fontSize: '18px',
                  }}
                  // size="14px"
                />
                <Typography
                  style={{
                    fontWeight: 500,
                    marginLeft: '10px',
                    fontSize: '14px',
                  }}
                >
                  Add Scope
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <Button
                fullWidth
                className={classes.buttonPurple}
                style={{
                  color: '#fff',
                  padding: '10px 0',
                  borderRadius: '7px',
                }}
                disabled={loading}
                size="small"
                endIcon={
                  loading ? (
                    <CircularProgress style={{ color: '#fff' }} size={15} />
                  ) : (
                    <PlayCircleFilledRoundedIcon
                      style={{ fontSize: '15px' }}
                      // size="10"
                    />
                  )
                }
                onClick={handleSubmitBOQ}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </>
    );
  };

  const memoizedList = listData?.map((data, index) => (
    <NestedBOQ
      index={index}
      boq={data}
      classes={classes}
      scopeList={scopeList}
      onSave={addProgressEntery}
      grossProgress={grossProgress}
    />
  ));

  const renderUpdateBOQView = () => {
    return (
      <>
        <DialogTitle
          style={{
            padding: '15px',
          }}
          id="customized-dialog-title"
          onClose={() => setOpenUpdateProgress(false)}
        >
          <Grid container justify="space-between" alignItems="center">
            <Grid item>Update Progress</Grid>
            <Grid item alignItems="center">
              <CloseIcon
                onClick={() => setOpenUpdateProgress(false)}
                style={{ cursor: 'pointer' }}
              />
            </Grid>
          </Grid>
          {renderFilters()}
        </DialogTitle>

        <DialogContent style={{ padding: '8px 10px' }}>
          {loading ? (
            <Typography variant="subtitle1" align="center">
              Loading...
            </Typography>
          ) : !boqList || boqList?.data?.length === 0 ? (
            <Typography variant="subtitle1" align="center">
              No BOQ Found!
            </Typography>
          ) : (
            // listData?.map((item) => (
            //   <ProgressRow
            //     key={item}
            //     rowItem={item}
            //     classes={classes}
            //     icon={general}
            //     unitList={unitList}
            //     scopeList={scopeList}
            //     setRowList={setRowList}
            //     setListData={setListData}
            //     listData={listData}
            //     rowList={rowList}
            //     addProgressEntery={addProgressEntery}
            //     handleRemarkChange={handleRemarkChange}
            //     handleImage={handleImage}
            //     openFieldNote={(rowItem) => {
            //       setSelectedRowItem(rowItem);
            //       setOpenFIeldNote(true);
            //     }}
            //   />
            // ))
            memoizedList
          )}
        </DialogContent>
        <Grid
          container
          item
          justify="flex-end"
          style={{ padding: '15px 10px' }}
          alignItems="center"
        >
          <Button
            className={classes.buttonPurple}
            style={{
              color: '#fff',
              padding: '10px 0',
              borderRadius: '7px',
              width: 180,
            }}
            size="small"
            endIcon={
              <img src={arrow} alt="save all" style={{ fontSize: '15px' }} />
            }
            onClick={addBulkProgressEntries}
          >
            Save All
          </Button>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Dialog
        open={openFieldNote}
        onClose={() => {
          // if (!applyMethod) {
          //   setOpenFIeldNote(false);
          // }
        }}
        keepMounted
        maxWidth="xl"
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          style={{
            padding: '15px',
          }}
          id="customized-dialog-title"
          onClose={() => {
            // if (!applyMethod) {
            //   setOpenFIeldNote(false);
            // }
          }}
        ></DialogTitle>
        <DialogContent
          style={{
            padding: '15px',
          }}
        >
          <CreateFieldNote
            currentProjectProgress={currentProjectProgress}
            fromModal={true}
            selectedRow={selectedRowItem}
            openPreviousModal={true}
            closeModal={() => {
              if (currentTab === 1) {
                setActionModal(false);
                setCurrentTab(0);
                setOpenFIeldNote(false);
                return setIsOpenLastModal(false);
              }
              setSelectedRowItem(null);
              setIsOpenLastModal(true);
              setActionModal(true);
              setCurrentTab(1);
            }}
            reject={reject}
          />
        </DialogContent>
      </Dialog>

      {isViewBOQ ? (
        <>
          <ViewBOQList
            setIsViewBOQ={setIsViewBOQ}
            handleEdit={(boq, item) => {
              setOpenUpdateProgress(true);
              setActiveBoq({ ...boq, ...item });
            }}
            scopeList={scopeList}
            fetchParentPageData={classifyProject}
          />
          {openUpdateProgress && renderEditBoqView(activeBoq)}
        </>
      ) : GCListListView ? (
        <ProductivityListView
          isOpenLastModal={isOpenLastModal}
          setIsOpenLastModal={setIsOpenLastModal}
          setGCListView={setGCListView}
          actionModal={actionModal}
          setActionModal={setActionModal}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          openTheFieldNote={(row) => {
            setOpenFIeldNote(true);
            setSelectedRowItem(row);
            setIsOpenLastModal(false);
          }}
          fetchParentPageData={classifyProject}
          setRejectStuff={(func) => setReject(func)}
        />
      ) : GCListListView1 ? (
        <ProductivityListView
          isOpenLastModal={isOpenLastModal}
          setGCListView={setGCListView1}
          setIsOpenLastModal={setIsOpenLastModal}
          hideActions={true}
          actionModal={actionModal}
          setActionModal={setActionModal}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          fetchParentPageData={classifyProject}
          openTheFieldNote={(row) => {
            setOpenFIeldNote(true);
            setSelectedRowItem(row);
            setIsOpenLastModal(false);
          }}
          filter={productivityFilterForGCList}
          setRejectStuff={(func) => setReject(func)}
        />
      ) : (
        <Page className={classes.root} title="Productivity">
          <Container maxWidth={false}>
            <Dialog
              open={openInsertBOQ}
              onClose={() => setOpenInsertBOQ(false)}
              keepMounted
              PaperProps={{ style: { maxWidth: 1350 } }}
            >
              {renderInsertBoqView()}
            </Dialog>
            {openUpdateProgress && (
              <Dialog
                open={openUpdateProgress}
                onClose={() => setOpenUpdateProgress(false)}
                keepMounted
                maxWidth="xl"
                fullWidth={true}
              >
                {renderUpdateBOQView()}
              </Dialog>
            )}
            <Grid
              container
              maxWidth={false}
              style={{
                background: 'white',
                borderRadius: '10px',
              }}
            >
              <BOQProgressGreenContainer
                projectClassificationList={projectClassificationList}
                currentProject={currentProject ?? productivitySelectedProject}
                classifiedProject={classifiedProject}
                setOpenInsertBOQ={setOpenInsertBOQ}
                classifyProject={classifyProject}
                setGCListView={setGCListView}
                setOpenUpdateProgress={setOpenUpdateProgress}
                setIsViewBOQ={setIsViewBOQ}
              />
            </Grid>
            <TempComponent projectId={productivitySelectedProject?.projectId} />
            <TielsComponenet currentProject={classifyProject} />
            {/* <Grid item container>
              {cardData.map((val, index) => {
                return (
                  <Grid
                    item
                    container
                    md={2}
                    xs={12}
                    style={{
                      padding: '1em 0',
                      paddingRight: index === 0 ? '20px' : '0',
                      paddingLeft:
                        index === 2 || index === 3 || index === 4 || index === 5
                          ? '20px'
                          : '0',
                    }}
                    key={index + 1}
                    alignItems="center"
                    className={`${
                      index === 0 ? classes.padRight : classes.padLeft
                    }`}
                    onClick={() => openGCListModal(val.filter)}
                  >
                    <Card
                      elevation={6}
                      style={{
                        borderRadius: 10,
                        width: '100%',
                        cursor: 'pointer',
                      }}
                    >
                      <CardContent>
                        <Grid
                          container
                          justify="space-between"
                          alignItems="center"
                          item
                          xs={12}
                        >
                          <Grid item xs={8}>
                            <Grid item xs>
                              <Typography
                                style={{
                                  fontFamily: 'Roboto',
                                  fontSize: 11,
                                  color: '#B2BEC7',
                                  fontWeight: '400',
                                }}
                              >
                                {val.title}
                              </Typography>
                            </Grid>
                            <Grid
                              container
                              alignItems="center"
                              direction="row"
                              justify="space-between"
                              item
                              xs={12}
                            >
                              <Grid item xs={12}>
                                <Typography
                                  style={{
                                    fontFamily: 'Roboto',
                                    fontSize: 15,
                                    fontWeight: '400',
                                  }}
                                >
                                  {boqProgressLoading ? (
                                    <CircularProgress
                                      style={{
                                        color: '#81B734',
                                        width: '25px',
                                        height: '25px',
                                      }}
                                    />
                                  ) : (
                                    val.count ?? 0
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Grid
                              container
                              justify="center"
                              alignItems="center"
                              style={{
                                fontFamily: 'Roboto',
                                height: 60,
                                width: 60,
                                fontWeight: '400',
                              }}
                            >
                              {val.icon}
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
            </Grid> */}
            <Grid
              container
              xs={12}
              style={{ marginTop: '1rem' }}
              justify="center"
            >
              <Grid item xs={12}>
                <Card
                  elevation={6}
                  style={{
                    width: '100%',
                    borderRadius: 10,
                    // height: "500px",
                  }}
                  className={classes.cardHeight}
                >
                  <CardHeader
                    title={
                      <Grid
                        container
                        alignItems="center"
                        justify="space-between"
                      >
                        <Grid item>
                          <Typography
                            style={{
                              fontSize: 20,
                              fontWeight: '500',
                              fontFamily: 'Roboto',
                              // color: "#D9D9D9",
                            }}
                          >
                            Project Progress
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={12}
                          style={{ marginTop: '.6rem' }}
                          justify="space-between"
                          alignItems="center"
                        >
                          <Grid item md={8} xs={12}>
                            <FilterProjectComponent
                              projectClassificationList={
                                projectClassificationList
                              }
                              src={
                                !classifiedProject[0]?.url
                                  ? defaultProjectImage
                                  : `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${classifiedProject[0]?.url}`
                              }
                              value={currentProjectProgressGraph}
                              onChange={classifyProject}

                              // onChange={classifyProjectProgressGraph}
                            />
                          </Grid>
                          {/* <Grid
                          item
                          md={4}
                          xs={12}
                          className={classes.marginTop}
                          container
                          alignItems="center"
                          justify="flex-end"
                        >
                          <Grid item style={{ paddingRight: "10px" }}>
                            <Typography
                              variant="subtitle1"
                              style={{ fontSize: "12px", fontWeight: 400 }}
                            >
                              Search Filters:
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            style={{
                              width: "210px",
                              position: "relative",
                            }}
                          >
                            <DatePicker
                              dateFormat="d MMM, yyyy"
                              selectsRange={true}
                              startDate={startDate}
                              endDate={endDate}
                              onChange={(update) => {
                                handleDateRange(update);
                              }}
                              isClearable={true}
                              className="border-datepicker"
                              placeholderText={
                                moment().format("DD MMM, YYYY") +
                                " - " +
                                moment().add(1, "days").format("DD MMM, YYYY")
                              }
                            />
                            {!startDate && (
                              <EventNoteIcon
                                style={{
                                  position: "absolute",
                                  right: "5px",
                                  top: "7px",
                                  color: "#ADBAC3",
                                }}
                              />
                            )}
                          </Grid>
                        </Grid> */}
                        </Grid>
                      </Grid>
                    }
                  />
                  <CardContent>
                    {projectProgressDataLoading ? (
                      <Grid style={{ height: '400px', textAlign: 'center' }}>
                        {' '}
                        Loading
                      </Grid>
                    ) : (
                      <Grid container item xs={12}>
                        <VictoryChart
                          animate={{
                            duration: 1000,
                            onLoad: { duration: 1000 },
                          }}
                          domainPadding={{ x: 50, y: 10 }}
                          domain={{ y: [1, projectProgressData?.maximum] }}
                          width={1000}
                          height={350}
                        >
                          <VictoryGroup
                            colorScale={getBarData()?.map((item) =>
                              item.color === '' || !item.color
                                ? '#9872EF'
                                : '#' + item.color
                            )}
                            // offset={40} // Adjust the offset to separate bars
                          >
                            {getBarData()?.map((item, index) => (
                              <VictoryBar
                                key={index}
                                style={{ labels: { fill: 'white' } }}
                                barWidth={40}
                                data={[item]}
                                x="x"
                                labels={({ datum }) => datum.percentage + '%'}
                                labelComponent={
                                  <VictoryLabel
                                    dy={20}
                                    textAnchor="middle"
                                    style={{ fill: '#fff' }}
                                  />
                                }
                                y="percentage"
                                cornerRadius={{ top: 5, bottom: 0 }}
                              />
                            ))}
                          </VictoryGroup>
                          <VictoryAxis
                            dependentAxis
                            tickFormat={(tick) => `${tick}%`}
                          />
                          <VictoryAxis tickFormat={(tick) => tick} />
                        </VictoryChart>
                      </Grid>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              style={{ marginTop: '4rem', padding: '0 1rem' }}
              justify="center"
            >
              <Grid item xs={12}>
                <Card
                  elevation={6}
                  style={{
                    width: '100%',
                    borderRadius: 10,
                  }}
                  className={classes.cardHeight}
                >
                  <CardHeader
                    title={
                      <Grid
                        container
                        alignItems="center"
                        justify="space-between"
                      >
                        <Grid item>
                          <Typography
                            style={{
                              fontSize: 20,
                              fontWeight: '500',
                              fontFamily: 'Roboto',
                              // color: "#D9D9D9",
                            }}
                          >
                            Financial Progress
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={12}
                          style={{ marginTop: '.6rem' }}
                          justify="space-between"
                          alignItems="center"
                        >
                          <Grid item md={8} xs={12}>
                            <FilterProjectComponent
                              projectClassificationList={
                                projectClassificationList
                              }
                              src={
                                !classifiedProject[0]?.url
                                  ? defaultProjectImage
                                  : `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${classifiedProject[0]?.url}`
                              }
                              value={currentProjectFinancial}
                              onChange={classifyProject}

                              // onChange={classifyProjectFinancial}
                            />
                          </Grid>
                          {/* <Grid
                          item
                          md={4}
                          xs={12}
                          className={classes.marginTop}
                          container
                          alignItems="center"
                          justify="flex-end"
                        >
                          <Grid item style={{ paddingRight: "10px" }}>
                            <Typography
                              variant="subtitle1"
                              style={{ fontSize: "12px", fontWeight: 400 }}
                            >
                              Search Filters:
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            style={{
                              width: "210px",
                              position: "relative",
                            }}
                          >
                            <DatePicker
                              dateFormat="d MMM, yyyy"
                              selectsRange={true}
                              startDate={startDate}
                              endDate={endDate}
                              onChange={(update) => {
                                handleDateRange(update);
                              }}
                              isClearable={true}
                              className="border-datepicker"
                              placeholderText={
                                moment().format("DD MMM, YYYY") +
                                " - " +
                                moment().add(1, "days").format("DD MMM, YYYY")
                              }
                            />
                            {!startDate && (
                              <EventNoteIcon
                                style={{
                                  position: "absolute",
                                  right: "5px",
                                  top: "7px",
                                  color: "#ADBAC3",
                                }}
                              />
                            )}
                          </Grid>
                        </Grid> */}
                        </Grid>
                      </Grid>
                    }
                  />
                  <CardContent>
                    <Grid container item xs={12}>
                      <Bar options={options} data={data} />
                    </Grid>

                    <Grid container>
                      {data?.datasets?.map((item) => (
                        <Grid
                          style={{
                            marginRight: '10px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {item.label}
                          <div
                            style={{
                              marginLeft: '5px',
                              width: '10px',
                              height: '10px',
                              backgroundColor: item.backgroundColor[0],
                            }}
                          ></div>
                        </Grid>
                      ))}{' '}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              style={{ marginTop: '4rem', padding: '0 1rem' }}
              justify="center"
            >
              <Grid item xs={12}>
                <Card
                  elevation={6}
                  style={{
                    width: '100%',
                    borderRadius: 10,
                  }}
                  className={classes.cardHeight}
                >
                  <CardHeader
                    title={
                      <Grid
                        container
                        alignItems="center"
                        justify="space-between"
                      >
                        <Grid
                          item
                          container
                          justify="space-between"
                          alignItems="center"
                        >
                          <Grid item>
                            <Typography
                              style={{
                                fontSize: 19,
                                fontWeight: '500',
                                fontFamily: 'Roboto',
                                // color: "#D9D9D9",
                              }}
                            >
                              Budgeted Vs Committed Vs Actual Cost
                            </Typography>
                          </Grid>

                          <Grid xs={8} container item>
                            <Grid container item md={4} xs={12}>
                              <Grid
                                item
                                container
                                alignItems="center"
                                justify="space-around"
                                style={{
                                  background: '#F9F9F9',
                                  borderRadius: 10,
                                  marginRight: '10px',
                                  height: '60px',
                                }}
                              >
                                <Grid>
                                  <Typography
                                    style={{
                                      fontFamily: 'Roboto',
                                      fontSize: 12,
                                    }}
                                  >
                                    Budget <br /> Cost
                                  </Typography>
                                </Grid>
                                <Grid>
                                  <Typography
                                    style={{
                                      fontFamily: 'Roboto',
                                      fontSize: 25,
                                      color: '#21D940',
                                    }}
                                  >
                                    {projectBudgetLoading ? (
                                      <CircularProgress
                                        style={{
                                          color: '#21D940',

                                          width: '25px',
                                          height: '25px',
                                        }}
                                      />
                                    ) : (
                                      '₹' +
                                      projectBudgetData?.budgetedCost?.toLocaleString()
                                    )}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              alignItems="center"
                              item
                              md={4}
                              xs={12}
                              className={classes.marginTop}
                              justify="space-around"
                              style={{
                                background: '#F9F9F9',
                                borderRadius: 10,
                                width: '100%',
                              }}
                            >
                              <Grid>
                                <Typography
                                  style={{ fontFamily: 'Roboto', fontSize: 12 }}
                                >
                                  Committed
                                  <br />
                                  Cost
                                </Typography>
                              </Grid>
                              <Grid>
                                <Typography
                                  style={{
                                    fontFamily: 'Roboto',
                                    fontSize: 25,
                                    color: '#7E7E7E',
                                  }}
                                >
                                  {projectBudgetLoading ? (
                                    <CircularProgress
                                      style={{
                                        color: '#7E7E7E',

                                        width: '25px',
                                        height: '25px',
                                      }}
                                    />
                                  ) : (
                                    '₹' +
                                    projectBudgetData?.committedCost?.toLocaleString()
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              alignItems="center"
                              item
                              md={4}
                              xs={12}
                              className={classes.marginTop}
                            >
                              <Grid
                                item
                                container
                                xs={12}
                                justify="space-around"
                                alignItems="center"
                                style={{
                                  background: '#F9F9F9',
                                  borderRadius: 10,
                                  width: '100%',
                                  marginLeft: '10px',

                                  height: '60px',
                                }}
                              >
                                <Grid>
                                  <Typography
                                    style={{
                                      fontFamily: 'Roboto',
                                      fontSize: 12,
                                    }}
                                  >
                                    Actual
                                    <br />
                                    Cost
                                  </Typography>
                                </Grid>
                                <Grid>
                                  <Typography
                                    style={{
                                      fontFamily: 'Roboto',
                                      fontSize: 25,
                                      color: '#AF9CF6',
                                    }}
                                  >
                                    {projectBudgetLoading ? (
                                      <CircularProgress
                                        style={{
                                          color: '#AF9CF6',

                                          width: '25px',
                                          height: '25px',
                                        }}
                                      />
                                    ) : (
                                      '₹' +
                                      projectBudgetData?.actualCost?.toLocaleString()
                                    )}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={12}
                          style={{ marginTop: '.6rem' }}
                          justify="space-between"
                          alignItems="center"
                        >
                          <Grid item md={8} xs={12}>
                            <FilterProjectComponent
                              projectClassificationList={
                                projectClassificationList
                              }
                              src={
                                !classifiedProject[0]?.url
                                  ? defaultProjectImage
                                  : `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${classifiedProject[0]?.url}`
                              }
                              value={currentProjectBudget}
                              onChange={classifyProject}

                              // onChange={classifyProjectBudget}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    }
                  />

                  <CardContent>
                    <Grid container item xs={12}>
                      {projectBudgetLoading ? (
                        <div style={{ height: '340px', textAlign: 'center' }}>
                          Loading...
                        </div>
                      ) : (
                        <VictoryChart
                          animate={{
                            duration: 1500,
                            onLoad: { duration: 1000 },
                          }}
                          theme={VictoryTheme.grayscale}
                          width={1200}
                          height={450}
                          padding={{
                            top: 50,
                            bottom: 50,
                            left: 90,
                            right: 10,
                          }}
                        >
                          <VictoryLegend
                            x={0}
                            y={0}
                            orientation="horizontal"
                            gutter={20}
                            colorScale={[
                              'RGB(133, 186, 89)',
                              '#7E7E7E',
                              '#9872EF',
                            ]}
                            data={[
                              { name: 'Budget Cost' },
                              { name: 'Commited Cost' },
                              { name: 'Actual Cost' },
                            ]}
                          />
                          <VictoryGroup
                            offset={25}
                            colorScale={[
                              'RGB(133, 186, 89)',
                              '#7E7E7E',
                              '#9872EF',
                            ]}
                          >
                            <VictoryBar
                              barWidth={25}
                              data={getBarDataBudget()}
                              // data={normalizedBudgetData}
                              // data={dummyData}
                              x="x"
                              // labels={({ datum }) =>
                              //   '₹' + datum.percentage?.toLocaleString()
                              // }
                              labels={({ datum }) =>
                                `₹${datum.actualValue?.toLocaleString()}`
                              }
                              // labelComponent={<VictoryLabel dy={50} />}
                              y="percentage"
                              cornerRadius={{ top: 5, bottom: 0 }}
                              style={{ labels: { fill: 'RGB(133, 186, 89)' } }}
                              alignment="start"
                              barRatio={0.8}
                              labelComponent={
                                <VictoryTooltip
                                  label={({ datum }) => {
                                    return `${datum.x}: ${datum.percentage}`;
                                  }}
                                  style={{
                                    fill: 'white', // Set the background color to white
                                  }}
                                  flyoutStyle={{
                                    padding: 10, // Adjust the padding as needed
                                    fill: 'RGB(133, 186, 89)', // Background color of the tooltip box
                                    stroke: 'RGB(133, 186, 89)', // Border color of the tooltip box
                                    strokeWidth: 2, // Border width of the tooltip box
                                  }}
                                  textStyle={{
                                    padding: 8, // Add padding to the text content
                                    // You can also customize other text styles like font size, font family, etc. here
                                  }}
                                />
                              }
                            />
                            <VictoryBar
                              barWidth={25}
                              data={getBarDataCommittedCost()}
                              // data={normalizedCommittedData}
                              // data={dummyData}
                              x="x"
                              cornerRadius={{ top: 5, bottom: 0 }}
                              // labels={({ datum }) =>
                              //   '₹' + datum.percentage?.toLocaleString()
                              // }
                              labels={({ datum }) =>
                                `₹${datum.actualValue?.toLocaleString()}`
                              }
                              // labelComponent={<VictoryLabel dy={50} />}
                              y="percentage"
                              style={{ labels: { fill: '#7E7E7E' } }}
                              alignment="start"
                              barRatio={0.8}
                              labelComponent={
                                <VictoryTooltip
                                  label={({ datum }) => {
                                    return `${datum.x}: ${datum.percentage}`;
                                  }}
                                  style={{
                                    fill: 'white', // Set the background color to white
                                  }}
                                  flyoutStyle={{
                                    padding: '10px', // Adjust the padding as needed
                                    fill: '#7E7E7E', // Background color of the tooltip box
                                    stroke: '#7E7E7E', // Border color of the tooltip box
                                    strokeWidth: 2, // Border width of the tooltip box
                                    // You can further customize other flyout styles as needed
                                  }}
                                  textStyle={{
                                    padding: 8, // Add padding to the text content
                                    // You can also customize other text styles like font size, font family, etc. here
                                  }}
                                />
                              }
                            />

                            <VictoryBar
                              barWidth={25}
                              // data={normalizedActualData}
                              data={getBarDataActualCost()}
                              // data={dummyData}
                              x="x"
                              cornerRadius={{ top: 5, bottom: 0 }}
                              // labels={({ datum }) =>
                              //   '₹' + datum.percentage?.toLocaleString()
                              // }
                              labels={({ datum }) =>
                                `₹${datum.actualValue?.toLocaleString()}`
                              }
                              // labelComponent={<VictoryLabel dy={50} />}
                              y="percentage"
                              style={{ labels: { fill: '#9872EF' } }}
                              alignment="start"
                              barRatio={0.8}
                              labelComponent={
                                <VictoryTooltip
                                  label={({ datum }) => {
                                    return `${datum.x}: ${datum.percentage}`;
                                  }}
                                  style={{
                                    fill: 'white', // Set the background color to white
                                  }}
                                  flyoutStyle={{
                                    padding: '10px', // Adjust the padding as needed
                                    fill: '#9872EF', // Background color of the tooltip box
                                    stroke: '#9872EF', // Border color of the tooltip box
                                    strokeWidth: 2, // Border width of the tooltip box
                                    // You can further customize other flyout styles as needed
                                  }}
                                  textStyle={{
                                    padding: 8, // Add padding to the text content
                                    // You can also customize other text styles like font size, font family, etc. here
                                  }}
                                />
                              }
                            />
                          </VictoryGroup>
                          <VictoryAxis
                            dependentAxis
                            domain={[0, 100]}
                            tickFormat={(tick) =>
                              `${tick.toLocaleString()}0000`
                            }
                          />
                          <VictoryAxis tickFormat={(tick) => tick} />
                        </VictoryChart>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Toaster />
          </Container>
        </Page>
      )}
    </>
  );
};

export default Productivity;
