import React from 'react';
import EventNoteIcon from '@material-ui/icons/EventNote';

import { Grid } from '@material-ui/core';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';

registerLocale('es', es);

const DateRangePicker = ({ startDate, endDate, handleDateRange }) => {
  return (
    <Grid
      container
      item
      style={{
        position: 'relative',
      }}
    >
      <DatePicker
        dateFormat="MMMM d, yyyy"
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => {
          handleDateRange(update);
        }}
        isClearable={true}
        className="font12 date-picker "
      />

      {!startDate && (
        <EventNoteIcon
          style={{
            position: 'absolute',
            right: '5px',
            top: '8px',
            color: '#ADBAC3',
          }}
        />
      )}
    </Grid>
  );
};

export default DateRangePicker;
