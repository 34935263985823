import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  Paper,
  Popper,
  TextField,
  Typography,
} from '@material-ui/core';
import { AddBox, Close as CloseIcon } from '@material-ui/icons';
import arrow from '../../assests/arrow.png';
import FilterProjectComponent from 'src/components/FilterProjectComponent';
import { useDispatch, useSelector } from 'react-redux';
import {
  getProjectsClassificationList,
  projectReducer,
} from 'src/redux/projects';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'relative',
    padding: '5px 20px',
    '&::before': {
      content: '""',
      position: 'absolute',
      width: 0,
      height: 0,
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      borderBottom: `10px solid ${theme.palette.background.paper}`,
      top: -10,
      left: 'calc(50% - 10px)',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
}));

const CustomFieldsModal = (props) => {
  const { anchorEl, handleSubmit, handleClose, cleanup } = props;
  const classes = useStyles();

  const [name, setName] = useState('');
  const [details, setDetails] = useState('');
  const [zIndex, setZIndex] = useState(1300);

  const openPopper = Boolean(anchorEl);

  useEffect(() => {
    if (cleanup) {
      setZIndex(-1);
    } else {
      setZIndex(1300);
    }
  }, [cleanup]);

  const handleCreate = () => {
    if (!name || !details) {
      return;
    }
    handleClear();
    handleSubmit({ name, details });
  };

  const handleClear = () => {
    setName('');
    setDetails('');
  };

  return (
    <Popper
      placement="bottom"
      anchorEl={anchorEl}
      open={openPopper}
      onClose={() => {
        handleClear();
        handleClose();
      }}
      style={{ zIndex, width: 280 }}
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper className={classes.paper} tabIndex={-1} elevation={5}>
            <Grid container>
              <Grid
                item
                xs={12}
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography>Create Custom Field</Typography>
                <IconButton
                  onClick={() => {
                    handleClear();
                    handleClose();
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid container>
                <InputLabel htmlFor="name-field" style={{ fontSize: 12 }}>
                  FIELD NAME (LABEL)
                </InputLabel>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  id="name-field"
                  placeholder="Enter Name"
                  style={{ marginBottom: '10px', marginTop: 3 }}
                  value={name}
                  autoFocus
                  onChange={(e) => setName(e.target.value)}
                />
                <InputLabel htmlFor="type-field" style={{ fontSize: 12 }}>
                  TYPE
                </InputLabel>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  id="type-field"
                  placeholder="Insert Details"
                  style={{ marginBottom: '10px', marginTop: 3 }}
                  value={details}
                  onChange={(e) => setDetails(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      handleCreate();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  style={{
                    margin: '10px 0px',
                    float: 'right',
                    textTransform: 'inherit',
                  }}
                  className={classes.buttonPurple}
                  onClick={handleCreate}
                >
                  Create
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};
const InsertLocationModal = (props) => {
  const { classes, handleClose, open } = props;

  const [currentProject, setCurrentProject] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [customFields, setCustomFields] = useState([
    { label: 'Custom Field', value: 'ABC' },
  ]);
  const [cleanup, setCleanup] = useState(false);

  useEffect(() => {
    if (open) {
      setCleanup(false);
    }
  }, [open]);

  let dispatch = useDispatch();

  //! SELECTORS
  const { projectClassificationList } = useSelector(projectReducer);

  //! LIFECYCLE
  useEffect(() => {
    if (projectClassificationList.length === 0) {
      dispatch(getProjectsClassificationList());
    }
  }, [projectClassificationList]); // eslint-disable-line

  const handleProjectChange = (project) => {
    setCurrentProject(project);
  };

  const toggleActionModal = (event) => {
    setCustomFields((prev) => [
      ...prev,
      { label: 'Custom Field', value: 'ABC' },
    ]);
    setAnchorEl(event.currentTarget);
  };

  const handleNewField = (field) => {
    setAnchorEl(null);

    setCustomFields((prevFields) => {
      const updatedFields = [...prevFields];
      const lastIndex = updatedFields.length - 1;
      updatedFields[lastIndex] = {
        ...updatedFields[lastIndex],
        label: field.name,
        value: field.details,
      };
      return updatedFields;
    });
  };

  const handleClear = () => {
    setCleanup(true);
    setAnchorEl(null);
    setCustomFields([{ label: 'Custom Field', value: 'ABC' }]);
    handleClose();
  };

  const handleRefChange = useCallback((e) => {
    if (e) setAnchorEl(e);
  }, []);

  // Removes the last field (incomplete) from the fields list
  const handleCloseCustomModal = () => {
    if (customFields.length === 1) {
      return;
    }
    setCustomFields((prev) => prev.slice(0, -1));
    setAnchorEl(null);
  };

  const renderTitle = () => {
    return (
      <DialogTitle>
        <Grid
          container
          style={{ alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Typography style={{ fontSize: 24 }}>Insert Location</Typography>
          <IconButton style={{ padding: '0px' }}>
            <CloseIcon
              onClick={handleClear}
              style={{ cursor: 'pointer', width: 30, height: 30 }}
            />
          </IconButton>
        </Grid>
      </DialogTitle>
    );
  };

  const renderFooter = () => {
    return (
      <DialogActions className={classes.footerShadow}>
        <Grid
          container
          justify="flex-end"
          alignItems="center"
          style={{ margin: '15px 20px' }}
        >
          <Button
            className={classes.buttonPurple}
            style={{
              fontSize: 12,
              padding: '15px 45px',
              borderRadius: 8,
              textTransform: 'inherit',
            }}
            endIcon={<img src={arrow} alt="submit" />}
            onClick={handleClear}
          >
            Submit
          </Button>
        </Grid>
      </DialogActions>
    );
  };

  const renderProjectFilter = () => {
    return (
      <Grid container xs={12} style={{ paddingBottom: 10 }}>
        <FilterProjectComponent
          projectClassificationList={projectClassificationList}
          value={currentProject}
          onChange={handleProjectChange}
        />
      </Grid>
    );
  };

  const renderAddIcon = () => {
    return (
      <AddBox
        onClick={toggleActionModal}
        ref={handleRefChange}
        style={{
          cursor: 'pointer',
          marginTop: 4,
          height: 50,
          width: 50,
          fill: '#81B734',
        }}
      />
    );
  };

  const renderCustomFields = () => {
    return (
      <Grid
        container
        xs={12}
        style={{
          border: '1px solid rgba(1,1,1,0.1',
          padding: 10,
          minHeight: 200,
          borderRadius: 10,
        }}
      >
        <div style={{ display: 'flex', minWidth: '100%', flexWrap: 'wrap' }}>
          {customFields.map((field) => {
            return (
              <div style={{ padding: '5px 5px', width: 180 }}>
                <TextField
                  id="filled-basic"
                  label={field.label}
                  size="small"
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      borderRadius: '7px',
                    },
                  }}
                  value={field.value}
                  InputLabelProps={{
                    style: {
                      color: '#8E99A8',
                    },
                  }}
                  required
                />
              </div>
            );
          })}
          {renderAddIcon()}
          <CustomFieldsModal
            classes={classes}
            anchorEl={anchorEl}
            handleSubmit={handleNewField}
            handleClose={handleCloseCustomModal}
            cleanup={cleanup}
          />
        </div>
      </Grid>
    );
  };

  const renderBody = () => {
    return (
      <DialogContent container>
        {renderProjectFilter()}
        {renderCustomFields()}
      </DialogContent>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      disableEnforceFocus
      PaperProps={{ style: { height: 500 } }}
    >
      {renderTitle()}
      {renderBody()}
      {renderFooter()}
    </Dialog>
  );
};
export default InsertLocationModal;
